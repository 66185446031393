import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ReportCustomPageBreakService } from 'src/app/service/filter/report-custom-page-break.service';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-soa',
  templateUrl: './soa.component.html',
  styleUrls: ['./soa.component.scss']
})
export class SoaComponent implements OnInit {

  @ViewChild('pdfData') htmlData;
  dataPrintSoa: any;

  // DEBUGGING PURPOSE
  debugging = false;

  constructor(private reportCustomPageBreakService: ReportCustomPageBreakService) {}

  ngAfterViewInit(): void {
    this.downloadPDF();
  }

  ngOnInit(): void {
    this.dataPrintSoa = JSON.parse(sessionStorage.getItem('soa_temp'));

    // DEBUGGING PURPOSE
    if (this.debugging) {
      // format SOA's custom page break
      // 1 line can have 50 characters
      // put the important col (eg. description to be used) in font of the rest parameter
      this.dataPrintSoa = this.reportCustomPageBreakService.formatData4Pages(this.dataPrintSoa,
        50, 60, 40 - 2, 46 - 2, 50, 'soa',   // OPT: 56, 64, 42, 48, 50
        'description', 'date', 'ref', 'dr', 'cr', 'balance'
      );
    }
    console.log('selectbedData: ', this.dataPrintSoa);
  }

  downloadPDF(): void {
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('p', 'px', 'a4');

    doc.html(DATA, {
      margin: 10,
      // tslint:disable-next-line:no-shadowed-variable
      callback: (doc) => {
        doc.save('official-soa (' + this.dataPrintSoa.debtor + ').pdf');
        Swal.fire('Print Successfully', 'SOA Printed', 'success').then(() => {
          window.close();
        });
      }
    });
  }
}
