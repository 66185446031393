import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { NavigationItem } from '../navigation';
import { NextConfig } from '../../../../../app-config';
import Swal from 'sweetalert2';
// service
import { AuthService } from 'src/app/shared/auth.service';
import { LocalService } from 'src/app/service/local/local.service';
import { UserDetailsService } from 'src/app/service/api/UserDetails/user-details.service';
import { UsersRecordService } from 'src/app/service/api/UsersRecord/users-record.service';

@Component({
    selector: 'app-nav-content',
    templateUrl: './nav-content.component.html',
    styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, AfterViewInit {
    public nextConfig: any;
    public navigation: any;
    public prevDisabled: string;
    public nextDisabled: string;
    public contentWidth: number;
    public wrapperWidth: any;
    public scrollWidth: any;
    public windowWidth: number;
    public isNavProfile = false; // nav under logo (View Profile, Settings, Logout)
    public showSettingNav = false;

    public dataUserDetails: any = {}; // logged user w/ access level

    // tslint:disable-next-line:no-output-on-prefix
    @Output() onNavMobCollapse = new EventEmitter();

    @ViewChild('navbarContent', { static: false }) navbarContent: ElementRef;
    @ViewChild('navbarWrapper', { static: false }) navbarWrapper: ElementRef;

    constructor(
        public nav: NavigationItem, private location: Location,
        private authService: AuthService, private localService: LocalService,
        private userDetailsService: UserDetailsService, private usersRecordService: UsersRecordService
    ) {
        this.nextConfig = NextConfig.config;
        this.windowWidth = window.innerWidth;
        this.prevDisabled = 'disabled';
        this.nextDisabled = '';
        this.scrollWidth = 0;
        this.contentWidth = 0;
    }

    ngOnInit(): void {
        this.navigation = this.nav.get(); // get nav list
        this.httpCallData(this.localService.getData('email'));

        if (this.windowWidth < 992) {
            this.nextConfig.layout = 'vertical';
            setTimeout(() => {
                document.querySelector('.pcoded-navbar').classList.add('menupos-static');
                (document.querySelector('#nav-ps-next') as HTMLElement).style.maxHeight = '100%';
            }, 500);
        }
    }

    ngAfterViewInit(): void {
        if (this.nextConfig.layout === 'horizontal') {
            this.contentWidth = this.navbarContent.nativeElement.clientWidth;
            this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
        }
    }

    httpCallData(email): void {
        forkJoin(
            {
                userDetail: this.userDetailsService.getUserDetailsByEmail(email), // get single user details by email
            }
        ).subscribe({
            next: rs => {
                if (rs.userDetail){
                    /* get logged user data */
                    this.dataUserDetails = rs.userDetail;
                    // prevent null
                    if (!this.dataUserDetails.institutionViewList) {
                        this.dataUserDetails.institutionViewList = [];
                    }
                    // temp filter for old data
                    if (this.dataUserDetails.institutionViewList.length === 0) {
                        this.dataUserDetails.institutionViewList.push(this.dataUserDetails.institutionId);
                    }
                    // save dataUserDetails into localStorage
                    this.localService.saveData('userDetails', JSON.stringify(this.dataUserDetails));

                    const dataUserDetails = this.localService.parseData('userDetails');
                    // console.log('sidenav-loggedUser', dataUserDetails); // for debugging purpose

                    // filter sidenav
                    this.filterSideNavItem(dataUserDetails);

                    // update user status to online
                    if (dataUserDetails.status && dataUserDetails.status === 1){ // if user status is offline
                        this.usersRecordService.toggleUserStatusByStatus(dataUserDetails.id, 2).subscribe({ // status 2: online
                            next: httpData => {
                                console.log('online');
                                // console.log(httpData);
                            },
                            error: err => {
                                console.log('Failed to set user status to "online"', err);
                                Swal.fire('Update Data Failed', 'Data: user online status', 'error');
                            }
                        });
                    }
                }
            }, error: err => {
                console.log(err);
                if (err.status === 0){ // if connect backend failed
                    Swal.fire({
                        icon: 'error',
                        title: 'Server Error',
                        html: 'The server cannot be reached.<br>Please contact system administrator'
                    });
                } else { // normal error
                    Swal.fire({
                        icon: 'error',
                        title: 'Get Data Failed',
                        html: 'Data: Logged-In User<br>Error ' + err.status
                    });
                }
            }
        });
    }

    unhideSideNavItem(title: string, layer: number = 1, type: string = 'item', collapseTitle: string = 'Management'): void {
        if (layer === 1){
            this.navigation[0].children
                .filter(item => item.title === title && item.type === type)
                .forEach(ele => ele.hidden = false);
        } else if (layer === 2){
            this.navigation[0].children
                .filter(item => item.title === collapseTitle && item.type === 'collapse')
                .forEach(nav1 => {
                    nav1.hidden = false; // Unhide parent nav
                    nav1.children
                        .filter(nav2 => nav2.title === title && nav2.type === type)
                        .forEach(ele => ele.hidden = false);
                });
        }
    }

    // Show sidenav based on logged user's Access Level
    filterSideNavItem(dataUserDetails): void {
        // Setting
        Object.keys(dataUserDetails).forEach(key => {
            if (key.includes('setting')){
                if (dataUserDetails[key] === true){
                    this.showSettingNav = true;
                }
            }
        });

        // Appointment
        if (
            dataUserDetails.appointmentView
            // || dataUserDetails.appointmentCreate
            // || dataUserDetails.appointmentEdit
            // || dataUserDetails.appointmentAssign
        ){
            this.unhideSideNavItem('Appointment');
        }
        // Sample Registration
        if (
            dataUserDetails.sampleRegisterView
            // || dataUserDetails.sampleRegisterCreate
            // || dataUserDetails.sampleRegisterEdit
            // || dataUserDetails.sampleVoid
        ){
            this.unhideSideNavItem('Sample Registration');
        }
        // Sample Transfer
        if (
            dataUserDetails.sampleTransferView
            // || dataUserDetails.sampleTransferEdit
        ){
            this.unhideSideNavItem('Sample Transfer');
        }
        // Sample Receive
        if (dataUserDetails.sampleReceiveView){
            this.unhideSideNavItem('Sample Receive');
        }
        // Worksheet
        if (dataUserDetails.sampleWorksheetView){
            this.unhideSideNavItem('Worksheet');
        }
        // Rejected Sample
        if (dataUserDetails.sampleRejectView){
            this.unhideSideNavItem('Rejected Sample');
        }
        // Test Order
        if (
            (dataUserDetails.testOrderView && dataUserDetails.testOrderView.indexOf(true) !== -1) // view
            // || (dataUserDetails.testOrderEdit && dataUserDetails.testOrderEdit.indexOf(true) !== -1) // edit
            // || (dataUserDetails.departmentValidate && dataUserDetails.departmentValidate.indexOf(true) !== -1) // validate
            // || (dataUserDetails.departmentVerify && dataUserDetails.departmentVerify.indexOf(true) !== -1) // verify
        ){
            this.unhideSideNavItem('Test Order');
        }
        // Archive
        if (dataUserDetails.archiveView){
            this.unhideSideNavItem('Archive');
        }

        // Inventory
        if (
            dataUserDetails.inventoryView
            // || dataUserDetails.inventoryCreate
            // || dataUserDetails.inventoryEdit
        ){
            this.unhideSideNavItem('Inventory', 2);
        }
        // Customer
        if (
            dataUserDetails.customerView
            // || dataUserDetails.customerCreate
            // || dataUserDetails.customerEdit
        ){
            this.unhideSideNavItem('Customer', 2);
        }
        // User
        if (
            dataUserDetails.userView
            // || dataUserDetails.userCreate
            // || dataUserDetails.userEdit
        ){
            this.unhideSideNavItem('User', 2);
        }
        // Billing Account
        if (
            dataUserDetails.billingAccountView
            // || dataUserDetails.billingAccountCreate
            // || dataUserDetails.billingAccountEdit
        ){
            this.unhideSideNavItem('Billing and Account', 2);
        }
        // Department
        if (
            dataUserDetails.departmentCreateNew // create
            || (dataUserDetails.departmentView && dataUserDetails.departmentView.indexOf(true) !== -1) // view
            // || (dataUserDetails.departmentEdit && dataUserDetails.departmentEdit.indexOf(true) !== -1) // edit
        ){
            this.unhideSideNavItem('Department', 2);
        }
        // Test
        if (
            dataUserDetails.testView
            // || dataUserDetails.testCreate
            // || dataUserDetails.testEdit
        ){
            this.unhideSideNavItem('Test', 2);
        }
    }

    scrollPlus(): void {
        this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
        if (this.scrollWidth > this.contentWidth - this.wrapperWidth) {
            this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
            this.nextDisabled = 'disabled';
        }
        this.prevDisabled = '';
        if (this.nextConfig.rtlLayout) {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
        } else {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
        }
    }

    scrollMinus(): void {
        this.scrollWidth = this.scrollWidth - this.wrapperWidth;
        if (this.scrollWidth < 0) {
            this.scrollWidth = 0;
            this.prevDisabled = 'disabled';
        }
        this.nextDisabled = '';
        if (this.nextConfig.rtlLayout) {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
        } else {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
        }
    }

    fireLeave(): void {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
            sections[i].classList.remove('active');
            sections[i].classList.remove('pcoded-trigger');
        }

        // tslint:disable-next-line:variable-name
        let current_url = this.location.path();
        // tslint:disable-next-line:no-string-literal
        if (this.location['_baseHref']) {
            // tslint:disable-next-line:no-string-literal
            current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = 'a.nav-link[ href=\'' + current_url + '\' ]';
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
            const parent = ele.parentElement;
            // tslint:disable-next-line:variable-name
            const up_parent = parent.parentElement.parentElement;
            // tslint:disable-next-line:variable-name
            const last_parent = up_parent.parentElement;
            if (parent.classList.contains('pcoded-hasmenu')) {
                parent.classList.add('active');
            } else if (up_parent.classList.contains('pcoded-hasmenu')) {
                up_parent.classList.add('active');
            } else if (last_parent.classList.contains('pcoded-hasmenu')) {
                last_parent.classList.add('active');
            }
        }
    }

    navMob(): void {
        if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
            this.onNavMobCollapse.emit();
        }
    }

    fireOutClick(): void {
        // tslint:disable-next-line:variable-name
        let current_url = this.location.path();
        // tslint:disable-next-line:no-string-literal
        if (this.location['_baseHref']) {
            // tslint:disable-next-line:no-string-literal
            current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = 'a.nav-link[ href=\'' + current_url + '\' ]';
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
            const parent = ele.parentElement;
            // tslint:disable-next-line:variable-name
            const up_parent = parent.parentElement.parentElement;
            // tslint:disable-next-line:variable-name
            const last_parent = up_parent.parentElement;
            if (parent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig.layout === 'vertical') {
                    parent.classList.add('pcoded-trigger');
                }
                parent.classList.add('active');
            } else if (up_parent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig.layout === 'vertical') {
                    up_parent.classList.add('pcoded-trigger');
                }
                up_parent.classList.add('active');
            } else if (last_parent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig.layout === 'vertical') {
                    last_parent.classList.add('pcoded-trigger');
                }
                last_parent.classList.add('active');
            }
        }
    }

    logout(): void {
        Swal.fire({
            icon: 'warning',
            title: 'Log Out?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed){
                this.authService.logout().then(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    console.log('logout');
                    window.location.href = '/login';
                });
            }
        });
    }
}
