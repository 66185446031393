import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = environment.apiURL + 'user';

  constructor(private http: HttpClient) { }

  getAllUser(): Observable<User[]>{
    return this.http.get<User[]>(this.url);
  }

  getUser(id: number): Observable<User>{
    return this.http.get<User>(this.url + '/' + id);
  }

  createUser(user: User): Observable<object>{
    return this.http.post(this.url + '/create', user);
  }

  updateUser(user: User): Observable<any>{
    return this.http.patch(this.url + '/update', user);
  }

  deleteUser(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }

  // Additional API
  getExistActiveUser(email: string): Observable<any> {
    return this.http.get(this.url + '/userExistsAndActive/' + email);
  }

  resetPassword(user: User): Observable<any>{
    return this.http.patch(this.url + '/resetPassword', user);
  }
}
