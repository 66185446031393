import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-satransfer',
  templateUrl: './satransfer.component.html',
  styleUrls: ['./satransfer.component.scss']
})
export class SatransferComponent implements AfterViewInit, OnInit {

  @ViewChild('pdfData') htmlData;
  readonly maxItemPerPage: number = 8;
  pageTotal: number;
  pageArray: any = [];

  constructor() { }
  data: any;

  // tube total text use
  tubeTotal: any = new Array(6).fill(0);
  totalItemNoUseTube = 0; // total of item which no use tube
  tubeTotalText = '';

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('temp'));
    this.generateTubeTotal();
    this.generatePageData();
    // console.log('data:', this.data);
  }

  ngAfterViewInit(): void {
    this.downloadPDF();
  }

  generateTubeTotal(): void {
    // update tubeTotal
    this.data.forEach(ele => {
      let totalTubeUsed = 0;
      for (let t = 0; t < 6; t++) {
        if (ele.tube[t] > 0) {
          totalTubeUsed++;
          this.tubeTotal[t] += ele.tube[t];
        }
      }
      if (totalTubeUsed === 0) {
        this.totalItemNoUseTube++;
      }
    });

    // static data
    const dataTube = [
      { id: 1, name: 'Plain Tube', abbr: 'P' },
      { id: 2, name: 'Urine Container', abbr: 'U' },
      { id: 3, name: 'Edta Tube', abbr: 'E' },
      { id: 4, name: 'Viral Transport Media', abbr: 'VTM' },
      { id: 5, name: 'Fluoride Tube', abbr: 'F' },
      { id: 6, name: 'Histopathology Examination', abbr: 'HPE' }
    ];

    // update tubeTotalText
    for (let t = 0; t < 6; t++) {
      if (this.tubeTotal[t] >= 1){
        if (this.tubeTotalText !== '') {
          this.tubeTotalText += ', ';
        }
        this.tubeTotalText += this.tubeTotal[t] + dataTube.find(item => item.id === t + 1).abbr;
      }
    }

    // update tubeTotalText, for test item no use tube
    if (this.totalItemNoUseTube > 0) {
      if (this.tubeTotalText !== '') {
        this.tubeTotalText += ', ';
      }
      this.tubeTotalText += this.totalItemNoUseTube.toString() + ' no tube';
    }
  }

  generatePageData(): void {
    this.pageTotal = Math.ceil(this.data.length / this.maxItemPerPage);
    for (let i = 0; i < this.pageTotal; i++) {
      this.pageArray.push(i + 1);
    }

    // set page num for each data row
    for (let index = 0, page = 0; index < this.data.length; index++) {
      if (index % this.maxItemPerPage === 0){
        page++;
      }
      this.data[index].page = page;
    }
  }

  downloadPDF(): void {
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('l', 'px', 'a4');

    doc.html(DATA, {
      margin: 10,
      // tslint:disable-next-line:no-shadowed-variable
      callback: (doc) => {
        doc.save('sa-transfer.pdf');
        Swal.fire('Print Successfully', 'Sample Transfer Form Printed', 'success').then(() => {
          window.close();
        });
      }
    });
  }
}
