import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customer } from './customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private url = environment.apiURL + 'customer';

  constructor(private http: HttpClient) { }

  getAllCustomer(): Observable<Customer[]>{
    return this.http.get<Customer[]>(this.url);
  }

  getCustomerById(id: number): Observable<Customer>{
    return this.http.get<Customer>(this.url + '/' + id);
  }

  createCustomer(customer: Customer): Observable<object>{
    return this.http.post(this.url + '/create', customer);
  }

  updateCustomer(customer: Customer): Observable<any>{
    return this.http.patch(this.url + '/update', customer);
  }

  deleteCustomer(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }

  // Additional API
  updateCustomerStatus(id: number, status: number, updatedBy: number): Observable<any>{
    return this.http.patch(this.url + '/' + id + '/updateStatus?status=' + status + '&updatedBy=' + updatedBy, status);
  }

  upload(file: File): Observable<any>{
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(this.url + '/upload', formData, {responseType: 'text'});
  }
}
