import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './shared/auth.service';
import IdleTimer from 'src/app/service/idleTimer.js';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    timerStatus: any = 'Active'; // for DEBUG purpose
    timer: any;

    constructor(private router: Router, private authService: AuthService) {}

    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        // auto logout if idle 10 min
        const pathName = window.location.pathname;
        if (pathName !== '/login' && pathName !== '/reset-password' && !pathName?.includes('test-report')) {
            this.timer = new IdleTimer({
                timeout: 30 * 60, // expired after XXX secs (10 mins in this case)
                onTimeout: () => {
                    this.timerStatus = 'Timeout'; // for DEBUG purpose
                    // logout
                    this.authService.logout().then(() => {
                        localStorage.clear();
                        console.log('logout');
                        localStorage.setItem('logout_reason', 'idle');
                        window.location.href = '/login';
                    });
                }
            });
        }
    }

    ngDestroy(): void {
        this.timer.clear();
    }
}
