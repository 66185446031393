<div class="mt-5">   <!--  d-none -->
	<div #pdfData class="a4 mx-auto">
		<div *ngFor="let i of dataPrintInvoice.qty, index as idx" style="height: 609px" #pdfData class="a4 mx-auto bg-white">
			<div class="header">
				<div class="row" *ngIf="idx === 0">
					<div class="col-12 text-center">
						<div class="title" style="margin-bottom: 3px; letter-spacing: 0.2px;">
							<h2 style="display: inline-block; margin-right: 5px">{{ dataPrintInvoice.institutionName }} - {{ dataPrintInvoice.institutionBranch }}</h2><span>(202101002796 (1403094-A))</span>
							<div>
								<p [innerHTML]="dataPrintInvoice.institutionAddress"></p>
								<p>Tel: {{ dataPrintInvoice.institutionPhone }}</p>
							</div>
							<p>{{ dataPrintInvoice.institutionEmail }}</p>
						</div>
					</div>
					<hr>
				</div>
				<div class="row">
					<div class="col-11 mx-auto">
						<div class="header">
							<div class="row m-0">
								<div class="col-7 px-0 text-center">
									<h2 style="margin-left: 159px; margin-bottom: 5px">INVOICE</h2>
								</div>
								<div class="col-4 ms-auto">
									<div class="row m-0 mt-1">
									<div class="col-6 px-0">
										<strong><p style="float: right; margin-right: 7px">No.</p></strong>
									</div>
									<div class="col-1 px-0">
										<strong>:</strong>
									</div>
									<div class="col-5 px-0">
										<strong><p>{{ dataPrintInvoice.no }}</p></strong>
									</div>
								</div>
							</div>
							</div>
							<div class="row m-0">
								<div class="col-7 pl-1">
									<div class="address" style="position: relative;">
										<div class="topleft"></div>
										<div class="topright" ></div>
										<div class="bottomright" ></div>
										<div class="bottomleft"></div>
										<div class="bottomright" ></div>
										<div>
											<p style="margin-bottom: 2px">{{ dataPrintInvoice?.name }}</p>
											<!-- <p style="height: 42px;" [innerHTML]="NO 11, JALAN MOLEK 1/30, <br>TAMAN MOLEK, <br>JOHOR BAHRU, <br>JOHOR"></p> -->
											<!-- <div [innerHTML]="'NO 11, JALAN MOLEK 1/30, <br>TAMAN MOLEK, <br>JOHOR BAHRU, <br>JOHOR'"></div> -->
											<div class="mt-1" [innerHTML]="dataPrintInvoice.address ? dataPrintInvoice.address : 'N/A'"></div>
										</div>
										<div>
											<div class="row mt-2">
												<div class="col-6 text-right">
													<p style="float: left;">TEL&nbsp;: {{ dataPrintInvoice.contact ? dataPrintInvoice.contact : 'N/A' }}</p>
												</div>
												<div class="col-6">
													<p>FAX&nbsp;: {{ dataPrintInvoice.fax ? dataPrintInvoice.fax : 'N/A' }}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-4 ms-auto">
									<div class="row m-0 mt-2">
										<div class="col-6 px-0">
											<p>Your Ref</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintInvoice.ref ? dataPrintInvoice.ref : '-' }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-6 px-0">
											<p>Your D/O No.</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintInvoice.doNo ? dataPrintInvoice.doNo : '-' }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-6 px-0">
											<p>Terms</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintInvoice.terms ? dataPrintInvoice.terms : '-' }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-6 px-0">
											<p>Date</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintInvoice.date }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-6 px-0">
											<p>Page</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{idx+1}} of {{dataPrintInvoice.qty.length}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="body">
				<div class="row">
					<div class="col-11 mx-auto">
						<table class="pdf-table">
							<tr *ngIf="idx === 0">
								<th>Item</th>
								<th width="40%">Description</th>
								<th>Qty</th>
								<th>UOM</th>
								<th class="text-right">U/Price <br>RM</th>
								<th>Disc. <br>RM</th>
								<th class="text-right">Total <br>RM</th>
							</tr>
							<tr *ngFor="let description of dataPrintInvoice.description[idx], index as j">
								<td class="text-center">
									<ng-container *ngIf="dataPrintInvoice.qty[idx][j]">
										{{ getNo(dataPrintInvoice.description, idx, j) + "." }}
									</ng-container>
								</td>
								<!-- <td width="40%" class="text-left">{{ description }}</td> -->
								<td width="40%" style="letter-spacing: 0.50px;" class="text-left">{{ dataPrintInvoice.description[idx][j] }}</td>   <!-- ditto -->
								<td class="text-center">{{ dataPrintInvoice.qty[idx][j] }}</td>
								<!-- LATER: resolve hardcode -->
								<!-- METHOD 1: -->
								<!-- <td class="text-center" *ngIf="dataPrintInvoice.uom[idx][j] === 1">BOX</td>
									<td class="text-center" *ngIf="dataPrintInvoice.uom[idx][j] === 2">LOT</td> -->
								<!-- METHOD 2: -->
								<!-- <td class="text-center">
									<ng-container *ngIf="dataPrintInvoice.uom[idx][j] === 1">
										BOX
									</ng-container>
									<ng-container *ngIf="dataPrintInvoice.uom[idx][j] === 2">
										LOT
									</ng-container>
								</td> -->
								<!-- METHOD 3: -->
								<td class="text-center">{{ dataPrintInvoice.uom[idx][j] }}</td>
								<td class="text-right">{{ dataPrintInvoice.price[idx][j]?.toFixed(2) }}</td>
								<td class="text-center">
									<ng-container *ngIf="dataPrintInvoice.qty[idx][j]">
										{{ dataPrintInvoice.singleMergedDiscInRM[idx][j] ? dataPrintInvoice.singleMergedDiscInRM[idx][j].toFixed(2) : "-" }}
									</ng-container>
								</td>
								<td class="text-right">{{ dataPrintInvoice.subtotalPrice[idx][j]?.toFixed(2) }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<div class="footer" *ngIf="idx === dataPrintInvoice.qty.length-1">
				<div class="row">
					<div class="col-11 mx-auto">
						<div class="total-amount">
							<div class="row">
								<div class="col-8">
									<p style="text-transform: uppercase; letter-spacing: 0.1px">{{ dataPrintInvoice.totalPriceWording }}</p>
								</div>
								<div class="col-4 pr-0">
									<div style="display: inline-block; margin-right: 5px;">
										Total: 
									</div>
									<div style="display: inline-block; width: calc(100% - 36px); border: 1px solid #000; padding-right: 5px; text-align: right;">{{ dataPrintInvoice.totalPrice?.toFixed(2) }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-11 mx-auto">
						<div class="row">
							<div class="col-8 ">
								<div class="notes">
									<p>Notes:</p>
									<ol>
										<li>All cheques should be crossed and made payable to MEDILABZ SDN. BHD.</li>
										<li>Bank details as follows:<br>
											UNITED OVERSEAS BANK (M) BERHAD<br>
											Account Number: 230-304-568-8<br>
											64E-H, Lebuh Bishop, 10200, Penang.<br>
											Swift Code: UOVBMYKL
										</li>
										<li>Goods sold are neither returnable nor refundable.</li>
										<li>Claim policy: Any disagreement regarding the product quantity must be reported to us within seven (7) days after receiving consignment. Claims regarding quality must be reported within fourteen (14) days after receiving consignment.</li>
									</ol>
								</div>
							</div>
							<div class="col-4">
								<div class="signature">ADMIN</div>
								<div class="text-center"><b>Authorised Signature</b></div>
								<p class="text-center" style="font-size: 3.34px;">THIS IS COMPUTER GENERATED DOCUMENT. NO SIGNATURE REQUIRED.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>