import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountType } from './account-type';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeService {

  private url = environment.apiURL + 'account-type';

  constructor(private http: HttpClient) { }

  getAllAccountType(): Observable<AccountType[]>{
    return this.http.get<AccountType[]>(this.url);
  }

  getAccountType(id: number): Observable<AccountType>{
    return this.http.get<AccountType>(this.url + '/' + id);
  }

  createAccountType(accountType: AccountType): Observable<object>{
    return this.http.post(this.url + '/create', accountType);
  }

  updateAccountType(accountType: AccountType): Observable<any>{
    return this.http.patch(this.url + '/update', accountType);
  }

  deleteAccountType(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }
}
