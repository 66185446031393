import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UnitMeasurement } from './unit-measurement';

@Injectable({
  providedIn: 'root'
})
export class UnitMeasurementService {

  private url = environment.apiURL + 'unit-measurement';

  constructor(private http: HttpClient) { }

  getAllUnitMeasurement(): Observable<UnitMeasurement[]>{
    return this.http.get<UnitMeasurement[]>(this.url);
  }

  getUnitMeasurement(id: number): Observable<UnitMeasurement>{
    return this.http.get<UnitMeasurement>(this.url + '/' + id);
  }

  createUnitMeasurement(unitMeasurement: UnitMeasurement): Observable<object>{
    return this.http.post(this.url + '/create', unitMeasurement);
  }

  updateUnitMeasurement(unitMeasurement: UnitMeasurement): Observable<any>{
    return this.http.patch(this.url + '/update', unitMeasurement);
  }

  deleteUnitMeasurement(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }
}
