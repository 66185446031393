import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { AccountTypeService } from 'src/app/service/api/AccountType/account-type.service';
import { CustomerService } from 'src/app/service/api/Customer/customer.service';
import { CustomerTypeService } from 'src/app/service/api/CustomerType/customer-type.service';
import { InstitutionService } from 'src/app/service/api/Institution/institution.service';
import { LabService } from 'src/app/service/api/Lab/lab.service';
import { NationalityService } from 'src/app/service/api/Nationality/nationality.service';
import { SampleService } from 'src/app/service/api/sample/sample.service';
import { TestService } from 'src/app/service/api/Test/test.service';
import { TestPackageService } from 'src/app/service/api/TestPackage/test-package.service';
import { TestProfileService } from 'src/app/service/api/TestProfile/test-profile.service';
import { UnitMeasurementService } from 'src/app/service/api/UnitMeasurement/unit-measurement.service';
import { UserService } from 'src/app/service/api/User/user.service';
import { ReportExtraInfoService } from 'src/app/service/filter/report-extra-info.service';
import { ReportService } from 'src/app/service/filter/report.service';
import { FontService } from 'src/app/service/shared/font.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements AfterViewInit, OnInit {

  isShowPreview: boolean;
  @ViewChild('pdfData') htmlData;
  pathLogo = 'assets/images/medilabz-logo.png';
  pathQR = 'assets/images/qrcode-eg.png';
  pageName: string; // 'report'=>form archive-view, 'test-report'=>from url
  reportPassword: string;
  sampleId = 0;

  dataAccountType: any = [];
  dataLab: any = [];
  dataUser: any = [];
  dataCustomerType: any = [];
  dataCustomer: any = [];
  dataNationality: any = [];
  dataTest: any = [];
  dataTestPackage: any = [];
  dataTestProfile: any = [];
  dataUnit: any = [];
  dataInstitution: any = [];

  constructor(
    private reportService: ReportService, private reportExtraInfoService: ReportExtraInfoService, private fontService: FontService,
    private sampleService: SampleService,
    private customerService: CustomerService, private customerTypeService: CustomerTypeService,
    private testPackageService: TestPackageService, private testProfileService: TestProfileService, private testService: TestService,
    private labService: LabService, private institutionService: InstitutionService,
    private nationalityService: NationalityService, private unitMeasureService: UnitMeasurementService,
    private userService: UserService, private accountTypeService: AccountTypeService
  ) { }

  dataReg: any = {};
  dataReport: any = {};
  pageArr: any = [];
  pageTotal = 0;

  // used for checking dataReport is ready
  private listSub = new BehaviorSubject({});
  listSubObservab = this.listSub.asObservable();

  ngOnInit(): void{
    document.body.classList.add('bg-white'); // set webpage background to white
    this.isShowPreview = false;

    const urlArr = window.location.pathname.split('/');
    this.pageName = urlArr[1];
    if (urlArr.length === 2 && this.pageName === 'report'){ // redirect from archive-view
      this.dataReg = JSON.parse(sessionStorage.getItem('report_temp'));
      this.generateReportData(this.dataReg);
    } else if (urlArr.length === 3 && this.pageName === 'test-report'){ // redirect from qr-code url
      if (isNaN(Number(urlArr[2]))){
        this.invalid();
      } else {
        this.sampleId = Number(urlArr[2]); // get Sample.id from URL
        forkJoin(
          {
            sample: this.sampleService.getSample(this.sampleId),
            customerType: this.customerTypeService.getAllCustomerType(),
            testPackage: this.testPackageService.getAllTestPackage(),
            testProfile: this.testProfileService.getAllTestProfile(),
            test: this.testService.getAllTest(),
            nationality: this.nationalityService.getAllNationality(),
            customer: this.customerService.getAllCustomer(),
            lab: this.labService.getAllLab(),
            institution: this.institutionService.getAllInstitution(),
            unitMeasure: this.unitMeasureService.getAllUnitMeasurement(),
            user: this.userService.getAllUser(),
            accountType: this.accountTypeService.getAllAccountType()
          }
        ).subscribe({
          next: rs => {
            if (rs.customerType) {
              this.dataCustomerType = rs.customerType;
            }
            if (rs.testPackage) {
              this.dataTestPackage = rs.testPackage;
            }
            if (rs.testProfile) {
              this.dataTestProfile = rs.testProfile;
            }
            if (rs.test) {
              this.dataTest = rs.test;
            }
            if (rs.nationality) {
              this.dataNationality = rs.nationality;
            }
            if (rs.customer) {
              this.dataCustomer = rs.customer;
            }
            if (rs.lab) {
              this.dataLab = rs.lab;
            }
            if (rs.institution) {
              this.dataInstitution = rs.institution;
            }
            if (rs.unitMeasure) {
              this.dataUnit = rs.unitMeasure;
            }
            if (rs.user) {
              this.dataUser = rs.user;
            }
            if (rs.accountType) {
              this.dataAccountType = rs.accountType;
            }
            if (rs.sample){
              this.dataReg = rs.sample;
              this.dataReg = this.reportService.getReportData(
                this.dataReg,
                this.dataUser, this.dataCustomer, this.dataCustomerType,
                this.dataTestPackage, this.dataTestProfile, this.dataTest,
                this.dataNationality, this.dataLab, this.dataUnit, this.dataAccountType, this.dataInstitution
              );
              this.generateReportData(this.dataReg);
            }
          }, error: err => {
            Swal.fire('Get Data Failed', 'Error ' + err.status, 'error');
          }
        });
      }
    } else {
      this.invalid();
    }
  }

  ngAfterViewInit(): void {
    if (this.pageName === 'report'){
      this.showPreview();
      this.downloadPDF();
    } else if (this.pageName === 'test-report'){
      const inputPwd = prompt('Please enter report password\n(Password is under report QR code)'); // get password input from user
      this.sampleService.getSample(this.sampleId).subscribe({
        next: httpData => {
          // console.log(inputPwd == this.reportPassword); //#
          if (httpData != null){
            if (this.isPwdValid(inputPwd) !== true && inputPwd != this.reportPassword){
              this.invalid('', 'Invalid Password', 1);
            } else {
              this.listSubObservab.subscribe(list => { // observer here, only triggered when dataReport notified
                if (Object.keys(this.dataReport).length !== 0) {
                  console.log('list', list); // # debug use
                  this.showPreview();
                  this.downloadPDF();
                }
              });
            }
          } else {
            this.invalid('Generate Data Failed', 'Data Not Exist');
          }
        },
        error: err => {
          this.invalid('Get Data Failed!', 'Data Not Exist', 2);
        }
      });
    } else {
      this.invalid('Page Not Exist');
    }
  }

  showPreview(): void {
    this.isShowPreview = true;
  }

  isPwdValid(pwd: string): boolean {
    try {
      if (
        pwd.length !== 8
        ||
        !pwd.match('[0-9a-zA-z]+')
      ) {
        return false;
      }
      return true;
    } catch (error) {
      window.location.reload();
    }
  }

  invalid(msg: string = '', title: string = 'Invalid URL', pageAction: number = 0): void {
    Swal.fire(title, msg, 'error').then(() => {
      if (pageAction === 1){
        window.location.reload();
      }
    });
    if (pageAction === 0){
      throw (msg === '') ? title : title + ' - ' + msg;
    }
  }

  setUrlQR(): void {
    const hostUrl = window.location.href.replace(window.location.pathname, ''); // get host url
    const url = hostUrl + '/test-report/' + this.sampleId; // complete url
    /* // for debug purpose only
    const url = 'https://lims.ergrouptech.com/test-report/15873'; // testing url // OPT: 'https://github.com/Cordobo/angularx-qrcode' */
    // console.log('QR url', url); // #
    //this.pathQR = 'https://chart.googleapis.com/chart?cht=qr&chs=50x50&chld=H|0&chl=' + url;
    this.pathQR = url;
    /* // for debug purpose only
    this.pathQR = this.pathQR.replace('http://localhost:4200/', 'https://lims.ergrouptech.com/'); */
    console.log('this.pathQR = ', this.pathQR);
  }

  setLogo(): void {
    // show customer logo if "Lab Logo" option in cust-mgmt page is "Yes", if "No" then show MediLabz logo as default
    if (this.dataReport.defaultLogo === false){
      this.pathLogo = this.dataReport.logoUrl;
    }
  }

  generateReportData(reg): void {
    this.sampleId = this.dataReg.id; // get Sample.id
    this.reportPassword = reg.reportPassword; // get Sample.reportPassword
    const data = this.reportExtraInfoService.updatePageHeigthData(this.dataReg);
    this.pageArr = data.pageArr;
    this.pageTotal = data.pageTotal;
    this.dataReport = data.dataReport;
    this.setUrlQR();
    this.setLogo();
    // console.log('testReport', this.dataReport); // # debug use

    this.listSub.next(this.dataReport); // notify that list is ready
  }

  downloadPDF(btnClicked: boolean = false): void {
    const filename: string = 'TEST REPORT - ' + this.dataReport.name + ' (' + this.dataReport.reportVersion + ').pdf';
    const DATA = this.htmlData.nativeElement;
    // console.log('DATA', DATA); // # debug use

    const doc = new jsPDF('p', 'px', 'a4');
    doc.setProperties(
      {
        title: filename
      }
    );

    // add font to support ≥ and ≤ symbol
    doc.addFileToVFS('cour-normal.ttf', this.fontService.getSymbolFont());
    doc.addFont('cour-normal.ttf', 'cour', 'normal');

    // add font to support chinese character
    doc.addFileToVFS('sourcehansanssc-normal.ttf', this.fontService.getChineseFont());
    doc.addFont('sourcehansanssc-normal.ttf', 'sourcehansanssc', 'normal');

    // open pdf
    doc.html(DATA, {
      margin: 10,
      // tslint:disable-next-line:no-shadowed-variable
      callback: (doc) => {
        if (btnClicked){ // download button clicked
          doc.save(filename);
        } else {
          window.open(doc.output('bloburl'), '_blank'); // open in new tab
        }
      }
    });
  }
}
