import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportCustomPageBreakService } from 'src/app/service/filter/report-custom-page-break.service';
import jsPDF from 'jspdf';
// import { Router } from '@angular/router';
import Swal from 'sweetalert2';
// import { DatePipe } from '@angular/common';
// import * as converter from 'number-to-words';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @ViewChild('pdfData') htmlData;
  dataPrintInvoice: any;
  /* totalPrice = 0;
  totalPriceWording = ""; */

  // DEBUGGING PURPOSE
  debugging = false;   // OPT: true

  constructor(private reportCustomPageBreakService: ReportCustomPageBreakService) {}   // private router: Router, private datePipe:DatePipe

  ngAfterViewInit(): void {
    this.downloadPDF();
  }

  ngOnInit(): void {
    this.dataPrintInvoice = JSON.parse(sessionStorage.getItem('inv_temp'));
    /* this.totalPrice = 0;
    this.dataPrintInvoice.price.forEach(request => this.totalPrice += Number(request));
    this.totalPriceWording = converter.toWords(this.totalPrice); */

    // DEBUGGING PURPOSE
    if (this.debugging) {
      // format invoice's custom page break
      // 1 line can have 50 characters
      // put the important col (eg. description to be used) in font of the rest parameter
      this.dataPrintInvoice = this.reportCustomPageBreakService.formatData4Pages(this.dataPrintInvoice,
        40, 50 - 2, 23 - 4, 35 - 2, 40, 'inv',   // OPT: 42, 51-2, 26-2, 32-2, 60, null; "inv"; 42, 50, 26, 35-2, 50
        'description', 'qty', 'uom', 'price', 'singleMergedDiscInRM', 'subtotalPrice'
      );
    }
    console.log('selectedData: ', this.dataPrintInvoice);
  }

  downloadPDF(): void {
    // let printNum = sessionStorage.getItem('print_num');
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('p', 'px', 'a4');

    doc.html(DATA, {
      margin: 10,
      // tslint:disable-next-line:no-shadowed-variable
      callback: (doc) => {
        /* for (let i=0; i<Number(printNum); i++)
          doc.save('official-invoice.pdf'); */
        doc.save('official-invoice (' + this.dataPrintInvoice.name + ').pdf');
        Swal.fire('Print Successfully', 'Invoice Printed', 'success').then(() => {
          window.close();
        });
      }
    });
  }

  // get no for invoice table
  getNo(arrayCol, currIdx, currNo): any {
    let prevAccumulatedNo = 0;

    // if currId is more than 1 (next page & so on), then we need to count the prev arr (page) item as well
    for (let i = 0; i < currIdx; i++) {
      prevAccumulatedNo += arrayCol[i].length;
    }

    return prevAccumulatedNo + currNo + 1;   // start from 1 instead of 0
  }
}
