import { Injectable } from '@angular/core';
import { PipeService } from '../pipe/pipe.service';
import { FilterService } from './filter.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  dataReport: any = {}; // filtered data, can used by archive & report
  ageSample: any = 0;

  dataReg: any = {}; // sample data
  dataAccountType: any = [];
  dataLab: any = [];
  dataUser: any = [];
  dataCustomerType: any = [];
  dataCustomer: any = [];
  dataNationality: any = [];
  dataTest: any = [];
  dataTestPackage: any = [];
  dataTestProfile: any = [];
  dataUnit: any = [];
  dataInstitution: any = [];

  operator = ['(None)', '-', '>', '<', '≥', '≤'];

  constructor(private pipeService: PipeService, private filterService: FilterService){ }

  /* Main Function START */
  getReportData(
    dataReg,
    user, customer, customerType,
    testPackage, testProfile, test,
    nationality, lab, unit, accountType, institution
  ): any {
    this.dataReg = dataReg;
    this.dataUser = user;
    this.dataCustomer = customer;
    this.dataCustomerType = customerType;
    this.dataTestPackage = testPackage;
    this.dataTestProfile = testProfile;
    this.dataTest = test;
    this.dataNationality = nationality;
    this.dataLab = lab;
    this.dataUnit = unit;
    this.dataAccountType = accountType;
    this.dataInstitution = institution;
    this.ageSample = this.filterService.getSampleAge(this.dataReg.birth, this.dataReg.sampleCollectDateTime);
    this.dataReport = this.generateReportData();
    return this.dataReport;
  }
  /* Main Function END */

  generateReportData(): any {
    const reg = this.dataReg;
    let dataReport: any = {};
    dataReport.id = reg.id;
    dataReport.reportPassword = reg.reportPassword;
    dataReport.logoUrl = 'assets/images/medilabz-logo.png'; // default set to MediLabz Logo, local file in server

    /* display setting START */
    dataReport.unitDisplay = this.getReportUnit(reg.customerId);
    dataReport.showChinese = this.getReportLanguage(reg.customerId);
    // default
    dataReport.defaultLogo = true; // if true, show MediLabz logo, false show clinic logo
    dataReport.showLogo = false;
    /* display setting END */

    // check whether customer logo needed to put on report page1 header
    if (reg.customerId && !isNaN(reg.customerId) && reg.customerId > 0){
      const customer = this.dataCustomer.find(item => item.id === reg.customerId);
      if (customer){
        if (customer.reportLogo === true){ // value is set by "Lab Logo" radio box in cust-mgmt page
          dataReport.showLogo = true;
          if (customer.logoPath && customer.logoPath.toString().trim() !== '') {
            dataReport.logoUrl = environment.apiURL + 'storage/download/' + customer.logoPath;
            dataReport.defaultLogo = false;
          }
        }
      } else {
        console.log('Failed to get customer data, id: ' + reg.customerId);
      }
    }


    /* header data START */
    // header left data
    dataReport.name = reg.name;
    dataReport.icPassport = this.filterService.getIcPassportDisplay(reg.ic, reg.passport);
    dataReport.contact = reg.contact;
    dataReport.email = (reg.email) ? reg.email : '-';
    const lab = this.dataLab.find(item => item.id === reg.labId);
    dataReport.lab = (lab === undefined || lab === '') ? '-' : lab?.name;
    const cust = this.dataCustomer.find(item => item.id === reg.customerId);
    dataReport.custName = (reg.customerId === 0 || reg.customerId === undefined) ? '-' : cust?.name;
    dataReport.custPic = (reg.customerId === 0 || reg.customerId === undefined) ? '-' : cust?.pic;
    dataReport.specimenType = this.getSpecimenTypeList();
    dataReport.fasting = (reg.fasting === true);
    dataReport.urgent = reg.urgent;
    dataReport.emailReport = reg.emailReport;
    dataReport.whatsappReport = reg.whatsappReport;

    // header right data
    dataReport.ageGender = this.ageSample + '/' + (reg.gender === 1 ? 'Male' : 'Female');
    dataReport.birth = reg.birth;
    dataReport.sampleCollectDateTime = reg.sampleCollectDateTime;
    dataReport.sampleReceiveDateTime = this.getLatestDateTime(reg.sampleReceiveDateTime);
    dataReport.reportDateTime = this.getLatestDateTime(reg.sampleVerifyDateTime);
    dataReport.reportVersion = 'Rev. No. ' + this.getReportVersion(reg);
    /* header data END */


    /* result data START */
    dataReport.resultData = [];

    const rejectArray = [];
    for (let iTestItem = 0; iTestItem < reg.testItemTempId.length; iTestItem++) {
      const testItemId = reg.testItemId[iTestItem];
      const testItemType = reg.testItemType[iTestItem];

      // TestItem Header
      dataReport.resultData.push({
        testItemName: this.getTestItemName(testItemId, testItemType),
        testItemNameChinese: this.getTestItemName(testItemId, testItemType, true),
        testItemType,
        testItemTypeName: this.filterService.getTestItemTypeName(testItemType),
        resultType: 2, // previously is 0, now set to 2 so test testItem will has header
        inner: [], // for package testItem use
        result: [], // for non-package testItem use
        description: (testItemType === 1) ?
          this.getDescription(testItemId, 1) :
          (testItemType === 2 ? this.getDescription(testItemId, 2) : ''
        ),
        reportNewPage: this.getReportPageSetting(testItemId, testItemType, 1),
        reportSelfPage: this.getReportPageSetting(testItemId, testItemType, 2),
        show: true,
        showItem: false, // default value
      });

      const dataReportChoosen = dataReport.resultData[iTestItem];

      for (let iSample = 0; iSample < reg.sampleTestId.length; iSample++) {
        if (reg.sampleTempId[iSample] === reg.testItemTempId[iTestItem]){
          const sampProId = reg.sampleProfileId[iSample]; // profileId
          const testId = reg.sampleTestId[iSample]; // testId
          if (testItemType === 1){// Package TestItem
            if (
              reg.sampleTempId[iSample] !== reg.sampleTempId[iSample - 1] ||
              sampProId !== reg.sampleProfileId[iSample - 1] ||
              sampProId === 0
            ){
              // Sub-Header (profile/test)
              dataReportChoosen.inner.push({
                testItemName: this.getTestItemName(((sampProId === 0) ? testId : sampProId), ((sampProId === 0) ? 3 : 2)),
                testItemNameChinese: this.getTestItemName(((sampProId === 0) ? testId : sampProId), ((sampProId === 0) ? 3 : 2), true),
                testItemId: (sampProId === 0) ? testId : sampProId,
                testItemType: (sampProId === 0) ? 3 : 2,
                testItemTypeName: this.filterService.getTestItemTypeName((sampProId === 0) ? 3 : 2),
                resultType: 2, // temp set to 2, will update later by code
                result: [],
                description: (sampProId === 0) ? '' : this.getDescription(sampProId, 2),
                reportNewPage: this.getReportPageSetting(
                  testItemId, 1, 1, false, (sampProId === 0 ? testId : sampProId), (sampProId === 0 ? 3 : 2)
                ),
                reportSelfPage: this.getReportPageSetting(
                  testItemId, 1, 2, false, (sampProId === 0 ? testId : sampProId), (sampProId === 0 ? 3 : 2)
                ),
                show: true,
                showItem: false, // default value
              });
            }

            // Test Result for Profile/Test in Package
            const resultValue = this.getCurrentResult(iSample);
            dataReportChoosen.inner[dataReportChoosen.inner.length - 1].result.push({
              resultType: ((this.isResultMeasureQty(testId) === false) ? 2 : 1),
              testId,
              testName: this.getTestItemName(testId, 3),
              testNameChinese: this.getTestItemName(testId, 3, true),
              isEmptyResult: resultValue == null,
              showItem: reg.sampleStatus[iSample] >= 34 ? (resultValue == null ? false : true) : false,
              resultRemark: this.getCurrentResultRemark(iSample),
              resultCU: this.checkCriticalRange(testId, resultValue, 1),
              unitCU: this.getTestUnit(testId, 1),
              rangeCU: this.getTestRange(testId, false, 1),
              resultSI: this.checkCriticalRange(testId, resultValue, 2),
              unitSI: this.getTestUnit(testId, 2),
              rangeSI: this.getTestRange(testId, false, 2),
              description: this.getDescription(testId, 3),
              remarkRangeCU: this.getTestRangeRemark(
                testId, this.checkCriticalRange(testId, resultValue, 1), this.getTestRange(testId, false, 1)
              ),
              remarkRangeSI: this.getTestRangeRemark(
                testId, this.checkCriticalRange(testId, resultValue, 2), this.getTestRange(testId, false, 2)
              ),
              rangeRemarkCU: this.checkCriticalRange(testId, resultValue, 1, true),
              rangeRemarkSI: this.checkCriticalRange(testId, resultValue, 2, true),
              reportNewPage: false,
              reportSelfPage: false,
              showCU: true,
              showSI: true
            });

            // update resultType
            for (let iInner = 0; iInner < dataReportChoosen.inner.length; iInner++) {
              const tempInner = dataReportChoosen.inner[iInner];
              for (let iResult = 0; iResult < tempInner.result.length; iResult++) {
                if (tempInner.result[iResult].resultType === 1){
                  tempInner.resultType = 1;
                  break;
                }
                // else no quantitaive result measure
                if (iResult === tempInner.result[iResult].length - 1) {
                  tempInner.resultType = 2;
                }
              }
            }
          } else {// Non-Package(Profile/Test) TestItem
            // Test Result in Profile/Test
            const resultValue = this.getCurrentResult(iSample);
            dataReportChoosen.result.push({
              resultType: ((this.isResultMeasureQty(testId) === false) ? 2 : 1),
              testId,
              testName: this.getTestItemName(testId, 3),
              testNameChinese: this.getTestItemName(testId, 3, true),
              isEmptyResult: resultValue == null,
              showItem: reg.sampleStatus[iSample] >= 34 ? (resultValue == null ? false : true) : false,
              resultRemark: this.getCurrentResultRemark(iSample),
              resultCU: this.checkCriticalRange(testId, resultValue, 1),
              unitCU: this.getTestUnit(testId, 1),
              rangeCU: this.getTestRange(testId, false, 1),
              resultSI: this.checkCriticalRange(testId, resultValue, 2),
              unitSI: this.getTestUnit(testId, 2),
              rangeSI: this.getTestRange(testId, false, 2),
              description: this.getDescription(testId, 3),
              remarkRangeCU: this.getTestRangeRemark(
                testId, this.checkCriticalRange(testId, resultValue, 1), this.getTestRange(testId, false, 1)
              ),
              remarkRangeSI: this.getTestRangeRemark(
                testId, this.checkCriticalRange(testId, resultValue, 2), this.getTestRange(testId, false, 2)
              ),
              rangeRemarkCU: this.checkCriticalRange(testId, resultValue, 1, true),
              rangeRemarkSI: this.checkCriticalRange(testId, resultValue, 2, true),
              reportNewPage: this.getReportPageSetting(testItemId, testItemType, 1, false, testId, 3),
              reportSelfPage: this.getReportPageSetting(testItemId, testItemType, 2, false, testId, 3),
              showCU: true,
              showSI: true
            });

            // update resultType
            for (let iResult = 0; iResult < dataReportChoosen.result.length; iResult++) {
              if (dataReportChoosen.result[iResult].resultType === 1){
                dataReportChoosen.resultType = 1;
              }
              // else no quantitaive result measure
              if (iResult === dataReportChoosen.result[iResult].length - 1) {
                dataReportChoosen.resultType = 2;
              }
            }
          }
          // Reject
          const rejectReason = reg.sampleRejectReason[iSample];
          if (rejectReason && rejectArray.indexOf(rejectReason) === -1) {
            rejectArray.push(rejectReason);
            if (!dataReportChoosen.rejectReason) { // prevent undefined
              dataReportChoosen.rejectReason = '';
            }
            dataReportChoosen.rejectReason += ' ' + rejectReason;
          }

          // Validate & Verify
          dataReportChoosen.validateBy = (reg.sampleValidateBy[iSample] === 0) ?
            null : this.filterService.getUserNameAndAccountType(reg.sampleValidateBy[iSample], this.dataUser, this.dataAccountType);
          dataReportChoosen.validateDateTime = reg.sampleValidateDateTime[iSample];
          dataReportChoosen.verifyBy = (reg.sampleVerifyBy[iSample] === 0) ?
            null : this.filterService.getUserNameAndAccountType(reg.sampleVerifyBy[iSample], this.dataUser, this.dataAccountType);
          dataReportChoosen.verifyDateTime = reg.sampleVerifyDateTime[iSample];
        }
      }
    }
    /* result data END */

    /* footer data START */
    const usedLab = this.dataLab.find(item => (item.id === this.dataReg.labId));
    const usedInstitution = this.dataInstitution.find(item => (item.id === usedLab.institutionId));
    dataReport.institutionBranch = usedInstitution.branch; // eg: Johor Bahru
    dataReport.institutionAddress = usedInstitution.address;
    dataReport.institutionPhone = usedInstitution.phone;
    dataReport.institutionEmail = usedInstitution.email;
    /* footer data END */

    /* generate abnormal range data show condition START */
    dataReport.abnormalShowCondition = [];
    for (let iTestItem = 0; iTestItem < this.dataReg.testItemId.length; iTestItem++) {
      dataReport = this.prepareAbnormalData(dataReport, this.dataReg.testItemId[iTestItem], this.dataReg.testItemType[iTestItem]);
    }
    dataReport = this.setAbnormalDisplayCondition(dataReport);

    // set test testItemType show condition to false, if both showCU&showSI is false
    dataReport.resultData.forEach(testItem => {
      if (testItem.testItemType === 1){
        testItem.inner.forEach(inner => {
          if (inner.testItemType === 3 && inner.result[0].showCU === false && inner.result[0].showSI === false) {
            inner.show = false;
          }
        });
      }
    });
    /* generate abnormal range data show condition END */

    return dataReport;
  }


  /* Abnormal Range Function START */
  setAbnormalDisplayCondition(dataReport: any): any {
    dataReport.resultData.forEach(rsData => {
      if (rsData.testItemType === 1){ // package
        rsData.inner.forEach(inner => {
          if (inner.testItemType === 2){// subItem is profile
            inner = this.subFunctionForFilterAbnormalShowCondition(
              inner, dataReport.abnormalShowCondition.filter(item => item.testItemType === 2), 'profile'
            );
          } else if (inner.testItemType === 3){// subItem is test
            rsData = this.subFunctionForFilterAbnormalShowCondition(
              rsData, dataReport.abnormalShowCondition.filter(item => item.testItemType === 1), 'test'
            );
          }
        });
      } else if (rsData.testItemType === 2){ // profile
        rsData = this.subFunctionForFilterAbnormalShowCondition(
          rsData, dataReport.abnormalShowCondition.filter(item => item.testItemType === 2), 'profile'
        );
      }
    });

    return dataReport;
  }

  subFunctionForFilterAbnormalShowCondition(data: any, abnormalList: any, type: string): any {
    if (abnormalList.length > 0){ // got data
      if (type === 'profile'){
        data.result.forEach(rs => {
          abnormalList.forEach(abnormal => {
            if (rs.testId === abnormal.abnormalTestId){// abnormal test.id found
              data.result.forEach(rs2 => {
                if (rs2.testId === abnormal.testId){// update show to false if test.id matched
                  // check result CU, if is not abnormal range
                  if (
                    rs.resultCU.toString().includes('ttext-primary') || rs.resultCU.toString().includes('ttext-danger') ||
                    rs.resultCU.toString().includes('text-primary') || rs.resultCU.toString().includes('text-danger')
                  ) {
                    rs2.showCU = false;
                  }

                  // check result SI, if is not abnormal range
                  if (
                    rs.resultSI.toString().includes('ttext-primary') || rs.resultSI.toString().includes('ttext-danger') ||
                    rs.resultSI.toString().includes('text-primary') || rs.resultSI.toString().includes('text-danger')
                  ){
                    rs2.showSI = false;
                  }
                }
              });
            }
          });
        });
      } else if (type === 'test'){
        data.inner.forEach(item => {
          if (item.testItemType === 3){
            abnormalList.forEach(abnormal => {
              if (item.testItemId === abnormal.abnormalTestId){// abnormal test.id found
                data.inner.forEach(item2 => {
                  if (item2.testItemId === abnormal.testId){// update show to false if test.id matched
                    item2.result.forEach(rs2 => {
                      // check result CU, if is not abnormal range
                      if (
                        item.result[0].resultCU.toString().includes('ttext-primary') || item.result[0].resultCU.toString().includes('ttext-danger') ||
                        item.result[0].resultCU.toString().includes('text-primary') || item.result[0].resultCU.toString().includes('text-danger')
                      ){
                        rs2.showCU = false;
                      }

                      // check result SI, if is not abnormal range
                      if (
                        item.result[0].resultSI.toString().includes('ttext-primary') || item.result[0].resultSI.toString().includes('ttext-danger') ||
                        item.result[0].resultSI.toString().includes('text-primary') || item.result[0].resultSI.toString().includes('text-danger')
                      ){
                        rs2.showSI = false;
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    }

    return data;
  }

  prepareAbnormalData(dataReport: any, testItemId: number, testItemType: number): any {
    if (testItemType === 1){ // package
      const aPackage = this.dataTestPackage.find(item => item.id === testItemId);
      if (aPackage){
        if (aPackage.itemShowIfAbnormalTestId && Array.isArray(aPackage.itemShowIfAbnormalTestId)){
          for (let i = 0; i < aPackage.itemId.length; i++) {
            if (aPackage.itemIsProfile[i] === false){ // is individual test
              const abnormalTestId = aPackage.itemShowIfAbnormalTestId[i];
              if (abnormalTestId && abnormalTestId > 0){
                dataReport.abnormalShowCondition.push(
                  {
                    testItemId,
                    testItemType: 1,
                    testId: aPackage.itemId[i],
                    abnormalTestId
                  }
                );
              }
            } else if (aPackage.itemIsProfile[i] === true){ // is profile
              dataReport = this.prepareAbnormalData(dataReport, aPackage.itemId[i], 2);
            }
          }
        }
      }
    } else if (testItemType === 2){ // profile
      const aProfile = this.dataTestProfile.find(item => item.id === testItemId);
      if (aProfile){
        if (aProfile.testShowIfAbnormalTestId && Array.isArray(aProfile.testShowIfAbnormalTestId)){
          for (let i = 0; i < aProfile.test.length; i++) {
            const abnormalTestId = aProfile.testShowIfAbnormalTestId[i];
            if (abnormalTestId && abnormalTestId > 0){
              dataReport.abnormalShowCondition.push(
                {
                  testItemId,
                  testItemType: 2,
                  testId: aProfile.test[i],
                  abnormalTestId
                }
              );
            }
          }
        }
      }
    }

    return dataReport;
  }
  /* Abnormal Range Function END */


  // check a test include "quantitative" result measurement?
  isResultMeasureQty(testId: number): boolean {
    const test = this.dataTest.find(item => item.id === testId);
    if (test !== undefined){
      for (let i = 0; i < test.resultMeasurement.length; i++) {
        if (test.resultMeasurement[i] === 1) {
          return true;
        }
      }
      return false;
    }
    // console.log('rs measure default false');
    return false;
  }

  // also use for get test.remark too, if returnRangeRemark === true
  checkCriticalRange(testId: number, resultValue: any, unitType: number = 0, returnRangeRemark: boolean = false): any {
    let oriResultValue: any = resultValue;

    // filter symbol
    let resultExtraOperation = 0; // add 1 if symbol is >; minus 1 if symbol is <
    if (resultValue){
      const decimalPlace: number = (resultValue.toString().split('.')[1] || '').length;
      const valueToCal: number = Math.pow(0.1, decimalPlace + 1);

      if (resultValue.includes('>')) {
        resultExtraOperation = valueToCal;
      }
      if (resultValue.includes('<')) {
        resultExtraOperation = valueToCal * -1;
      }
      resultValue = resultValue.replace(/≥/g, ''); // ≥
      resultValue = resultValue.replace(/>/g, ''); // >
      resultValue = resultValue.replace(/≤/g, ''); // ≤
      resultValue = resultValue.replace(/</g, ''); // <
    }
    // console.log(oriResultValue +"|"+ resultValue);

    // return "-", if result is null/undefined
    if (resultValue === null || resultValue === undefined){
      if (returnRangeRemark === true) {
        return null;
      }
      return '-';
    }

    const test = this.dataTest.find(item => item.id === testId);
    let rsMeasure = 0;
    if (test.resultMeasurement) {
      // Qualitative
      if (test.resultMeasurement.indexOf(2) !== -1) {
        rsMeasure = 2;
      }
      // Quantitative
      if (test.resultMeasurement.indexOf(1) !== -1) {
        rsMeasure = 1;
      }
    }

    // result is not number
    if (isNaN(Number(resultValue)) && rsMeasure !== 2) {
      if (returnRangeRemark === true) {
        return null;
      }
      return oriResultValue;
    } else {
      if (rsMeasure === 1) {
        resultValue = Number(resultValue) + Number(resultExtraOperation);
      }
    }

    // return resultValue, if no unit && range
    if (
      (unitType === 1 || unitType === 2)
      && (this.getTestUnit(testId, unitType) === '-' || this.getTestUnit(testId, unitType) === undefined)
      && (this.getTestRange(testId, false, unitType) === '-' || this.getTestRange(testId, false, unitType) === undefined)
    ){
      if (returnRangeRemark === true) {
        return null;
      }
      return oriResultValue;
    }

    const isFasting = (this.dataReg.fasting === true);
    const agePatient = this.ageSample;
    if (test && !test.testRangeFasting) {
      test.testRangeFasting = new Array(test.testRangeType).fill(false); // update for old data
    }
    // console.log(test.name); //#

    let range: any = [];
    try {
      range = this.getTestRange(test.id, true);
    } catch (error) {
      if (returnRangeRemark === true) {
        return null;
      }
      return oriResultValue;
    }

    if (range.length === 0){
      if (returnRangeRemark === true) {
        return null;
      }
      return oriResultValue;
    }
    // console.log(range); //#range before filter

    // convert result if has conversion factor
    let gotConversionFactor = false;
    if (unitType === 1 && test.conversionFactor && test.conversionFactor !== 0){
      resultValue /= test.conversionFactor;
      gotConversionFactor = true;
    }

    // filter decimal
    if (!isNaN(Number(test.decimal))){
      if (test.decimal){// if got decimal limit
        resultValue = Number(Number(resultValue).toFixed(test.decimal));
      } else {// no decimal limit
        const decimalPart = resultValue.toString().split('.')[1];
        if (decimalPart && decimalPart.length > 6){
          resultValue = Number(Number(resultValue).toFixed(6)); // limit to 6dp if exceed
        }
      }
    }

    // set unit to SI, if is not CU/SI
    if (unitType === 0) {
      unitType = 2;
    }

    // make result follow decimal setting
    if (!isNaN(oriResultValue)) {
      if (unitType === 1 && test.decimalCU !== null) { // CU
        oriResultValue = Number(oriResultValue).toFixed(test.decimalCU);
      } else if (unitType === 2 && test.decimalSI !== null) { // SI
        oriResultValue = Number(oriResultValue).toFixed(test.decimalSI);
      }
    }

    // get only the matched unit & gender & age range (if any)
    if (!range || range.length === 0){
      if (returnRangeRemark === true) {
        return null;
      }
      return (gotConversionFactor === true) ? resultValue : oriResultValue;
    }

    let rangeFasting: any = [];
    if (isFasting === true){
      rangeFasting = range.filter(item =>
        item.type === unitType
        && (item.gender === 3 || item.gender === this.dataReg.gender)
        && (
          item.ageOperator === 0
          || (item.ageOperator === 1 && agePatient >= item.age1 && agePatient <= item.age2)
          || (item.ageOperator === 2 && agePatient > item.age1)
          || (item.ageOperator === 3 && agePatient < item.age1)
          || (item.ageOperator === 4 && agePatient >= item.age1)
          || (item.ageOperator === 5 && agePatient <= item.age1)
        )
        && (item.fasting === true)
      );

      if (rangeFasting.length === 0) {
        // console.log('no fasting test range');
      } else {
        range = rangeFasting;
      }
    }

    if (isFasting === false || (isFasting === true && rangeFasting.length === 0)){
      range = range.filter(item =>
        item.type === unitType
        && (item.gender === 3 || item.gender === this.dataReg.gender)
        && (
          item.ageOperator === 0
          || (item.ageOperator === 1 && agePatient >= item.age1 && agePatient <= item.age2)
          || (item.ageOperator === 2 && agePatient > item.age1)
          || (item.ageOperator === 3 && agePatient < item.age1)
          || (item.ageOperator === 4 && agePatient >= item.age1)
          || (item.ageOperator === 5 && agePatient <= item.age1)
        )
        && (!item.fasting)
      );
    }
    // console.log(range); //#range after filter

    // colour 1=black(normal), 2=blue(criLow), 3=red(criHigh)
    range = range.sort((a, b) => a.colour - b.colour).reverse(); // order to 3,2,1

    for (let i = 0; i < range.length; i++) {
      const rangeColor: number = Number(range[i].colour) ?? 1; // 1=black(normal), 2=blue(criLow), 3=red(criHigh)
      const rangeValue1: number = Number(range[i].value1);
      const rangeValue2: number = Number(range[i].value2);
      const rangeValueOperator: number = Number(range[i].valueOperator);
      const rangeRemark: string = range[i].remark;

      // check critical
      if (rangeColor === 2 || rangeColor === 3){
        if (rsMeasure === 2) { // Qualitative
          const valueText = range[i].valueText;
          if (
            valueText &&
            resultValue &&
            valueText.toString().toLowerCase().trim() === resultValue.toString().toLowerCase().trim()
          ) {
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(rangeColor, 2) + ' font-weight-bold">' + oriResultValue + '**</span>';
          }
        } else if (rsMeasure === 1) { // Quantitative
          // < || ≤
          if (
            (rangeValueOperator === 3 && resultValue < rangeValue1) ||
            (rangeValueOperator === 5 && resultValue <= rangeValue1)
          ){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(rangeColor, 2) + ' font-weight-bold">' + oriResultValue + '**</span>';
          }

          // > || ≥
          if (
            (rangeValueOperator === 2 && resultValue > rangeValue1) ||
            (rangeValueOperator === 4 && resultValue >= rangeValue1)
          ){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(rangeColor, 1) + ' font-weight-bold">' + oriResultValue + '**</span>';
          }

          // -
          if (
            (rangeValueOperator === 1 && resultValue >= rangeValue1) &&
            resultValue <= rangeValue2
          ){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(rangeColor, 0) + ' font-weight-bold">' + oriResultValue + '**</span>';
          }
        }
      }

      // check normal range[i]
      else if (rangeColor === 1){
        if (rangeValueOperator === 1){// -
          if (resultValue >= rangeValue1 && resultValue <= rangeValue2){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span>' + oriResultValue + '</span>'; // normal
          } else if (resultValue < rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(2, 2) + ' font-weight-bold">' + oriResultValue + '*</span>'; // low
          } else if (resultValue > rangeValue2){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(3, 1) + ' font-weight-bold">' + oriResultValue + '*</span>'; // high
          }
        } else if (range[i].valueOperator === 2){// >
          if (resultValue > rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span>' + oriResultValue + '</span>'; // normal
          } else if (resultValue <= rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(2, 2) + ' font-weight-bold">' + oriResultValue + '*</span>'; // low
          }
        } else if (rangeValueOperator === 3){// <
          if (resultValue < rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span>' + oriResultValue + '</span>'; // normal
          } else if (resultValue >= rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(3, 1) + ' font-weight-bold">' + oriResultValue + '*</span>'; // high
          }
        } else if (rangeValueOperator === 4){// ≥
          if (resultValue >= rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span>' + oriResultValue + '</span>'; // normal
          } else if (resultValue < rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(2, 2) + ' font-weight-bold">' + oriResultValue + '*</span>'; // low
          }
        } else if (rangeValueOperator === 5){// ≤
          if (resultValue <= rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span>' + oriResultValue + '</span>'; // normal
          } else if (resultValue > rangeValue1){
            if (returnRangeRemark === true) {
              return rangeRemark;
            }
            return '<span class="' + this.getTextColourCSS(3, 1) + ' font-weight-bold">' + oriResultValue + '*</span>'; // high
          }
        }
      }
    }

    if (returnRangeRemark === true) {
      return null;
    }
    return oriResultValue;
  }

  /**
   * get text colour CSS
   * @param colour 1=default(black), 2=criticalLow(blue), 3=criticalHigh(red)
   * @param remarkType 0=-, 1=>(high), 2=<(low)
   * @returns CSS string class name
   */
  getTextColourCSS(colour: number, remarkType: number): string {
    const remarkCSS: string = (remarkType === 1) ? 'rightArrow' : (remarkType === 2) ? 'leftArrow' : '';
    if (colour === 2) {
      return 'ttext-primary ' + remarkCSS;
    } else if (colour === 3) {
      return 'ttext-danger ' + remarkCSS;
    } else {
      return '';
    }
  }

  // @params type 1=package, 2=profile, 3=test
  getTestItemName(id: number, type: number, chineseName: boolean = false): string {
    let data: any;
    if (type === 1) {
      data = this.dataTestPackage.find(item => item.id === id);
    } else if (type === 2) {
      data = this.dataTestProfile.find(item => item.id === id);
    } else if (type === 3) {
      data = this.dataTest.find(item => item.id === id);
    } else {
      throw new Error('Failed to get Test Item Name, id: ' + id + ', type: ' + type);
    }

    if (data){
      // filter chinese name
      if (data.nameChinese && data.nameChinese.trim() === '') {
        data.nameChinese = null;
      }
      return (chineseName === true) ? (data.nameChinese) : data.name;
    }
  }

  getLatestDateTime(array): any {
    if (array === undefined) {
      return '-';
    }
    array = array.filter(item => item !== null); // filter out null value
    array.sort((a, b) => (
      (new Date(this.pipeService.transformSystemDateTimeFormat(b)).getTime())
      - (new Date(this.pipeService.transformSystemDateTimeFormat(a)).getTime())
    ));
    return array.length === 0 ? '-' : array[0];
  }

  getSpecimenTypeList(): string {
    const array = [];
    if (this.dataReg.sampleTestId === undefined) {
      return '-';
    }
    this.dataReg.sampleTestId.forEach(aSampleTestId => {
      if (aSampleTestId !== 0 && aSampleTestId !== null && aSampleTestId !== undefined){
        this.dataTest.forEach(aTest => {
          if (
            aTest.id === aSampleTestId &&
            aTest.sampleType !== null &&
            aTest.sampleType.trim() !== '' &&
            array.filter(item => item.toString().toLowerCase() === aTest.sampleType.toString().toLowerCase()).length === 0
          ){
            array.push(aTest.sampleType);
          }
        });
      }
    });

    let displayText = '';
    for (let i = 0; i < array.length; i++) {
      displayText += (i === array.length - 1) ? array[i] : array[i] + ', ';
    }

    return displayText;
  }

  getDescription(itemId: number, itemType: number): string {
    let data: any;
    if (itemType === 1) {
      data = this.dataTestPackage.find(item => item.id === itemId);
    } else if (itemType === 2) {
      data = this.dataTestProfile.find(item => item.id === itemId);
    } else if (itemType === 3) {
      data = this.dataTest.find(item => item.id === itemId);
    }
    return (data && data.description) ? data.description : '';
  }

  getTestRangeRemark(testId: number, resultText, range): string {
    let index = -1;

    // filter by range
    range = range.toString(); // toString() to prevent error
    const rangeSplit = range.split('(')[1];
    if (rangeSplit?.includes('-')) {
      index = 0;
    } else if (rangeSplit?.includes('>') || rangeSplit?.includes('≥')) {
      index = 1;
    } else if (rangeSplit?.includes('<') || rangeSplit?.includes('≤')) {
      index = 2;
    }

    // filter by resultText
    resultText = resultText.toString(); // toString() to prevent error
    if (resultText === '-') { // no result is inputed
      index = -1;
    } else if (resultText?.includes('leftArrow')) { // <
      index = 2;
    } else if (resultText?.includes('rightArrow')) { // >
      index = 1;
    }

    const test = this.dataTest.find(item => item.id === testId);
    return (!test || index === -1) ? '' : (test.remarkRange[index].trim() === '' ? '' : test.remarkRange[index]);
  }

  // @param unitType 1=CU, 2=SI
  getTestUnit(testId: number, unitType: number = 0): string {
    const test = this.dataTest.find(item => item.id === testId);
    if (test !== undefined && test.unitIdCU !== undefined){
      if (unitType === 0){
        if (test.unitIdCU === 0){
          if (test.unitIdSI === 0) {
            return '-'; // return empty
          } else {
            return this.dataUnit.find(item => item.id === test.unitIdSI)?.name;
          }
        } else {
          return this.dataUnit.find(item => item.id === test.unitIdCU)?.name;
        }
      } else if (unitType === 1){// CU
        if (test.unitIdCU === 0) {
          return '-'; // return empty
        } else {
          return this.dataUnit.find(item => item.id === test.unitIdCU)?.name;
        }
      } else if (unitType === 2){// SI
        if (test.unitIdSI === 0) {
          return '-'; // return empty
        } else {
          return this.dataUnit.find(item => item.id === test.unitIdSI)?.name;
        }
      }
    }
  }

  // @param unitType 1=CU, 2=SI
  getTestRange(testId: number, returnJSON: boolean = false, unitType: number = 0): any {
    const range: any = [];
    let displayList = '';
    const operator = this.operator;
    const agePatient = this.ageSample;
    const genderPatient = this.dataReg.gender;
    const fastingPatient = this.dataReg.fasting;
    const test = this.dataTest.find(item => item.id === testId);

    // check result measurement
    let rsMeasure = 0;
    if (test.resultMeasurement) {
      // Qualitative
      if (test.resultMeasurement.indexOf(2) !== -1) {
        rsMeasure = 2;
      }
      // Quantitative
      if (test.resultMeasurement.indexOf(1) !== -1) {
        rsMeasure = 1;
      }
    }

    if (
      test === undefined
      ||
      (rsMeasure === 1 && (!test.testRangeValue1 || test.testRangeValue1.length === 0)) // quantitative
      ||
      (rsMeasure === 2 && (!test.testRangeValueText || test.testRangeValueText.length === 0)) // qualitative
    ) {
      return (returnJSON === true) ? [] : '<span class="text-center">-</span>'; // return empty
    }

    // set unit to SI, if is not CU/SI
    if (unitType === 0) {
      unitType = 2;
    }

    for (let i = 0; i < test.testRangeValue1.length; i++) {
      const valueText = test.testRangeValueText ? test.testRangeValueText[i] : null;
      // make range follow decimal setting
      let value1 = test.testRangeValue1[i];
      let value2 = test.testRangeValue2[i];
      if (unitType === 1 && test.decimalCU !== null) { // CU
        if (!isNaN(value1)) {
          value1 = Number(value1).toFixed(test.decimalCU);
        }
        if (!isNaN(value2)) {
          value2 = Number(value2).toFixed(test.decimalCU);
        }
      } else if (unitType === 2 && test.decimalSI !== null) { // SI
        if (!isNaN(value1)) {
          value1 = Number(value1).toFixed(test.decimalSI);
        }
        if (!isNaN(value2)) {
          value2 = Number(value2).toFixed(test.decimalSI);
        }
      }

      range.push(
        {
          type: test.testRangeType[i],
          colour: test.testRangeColour[i],
          valueText,
          value1,
          value2,
          valueOperator: test.testRangeValueOperator[i],
          age1: test.testRangeAge1[i],
          age2: test.testRangeAge2[i],
          ageOperator: test.testRangeAgeOperator[i],
          gender: test.testRangeGender[i],
          remark: (!test.testRangeRemark) ? null : test.testRangeRemark[i],
          fasting: (!test.testRangeFasting) ? false : test.testRangeFasting[i]
        }
      );
    }

    if (returnJSON === true) {
      return range;
    }

    let genderText = '';
    let ageText = '';
    let rangeText = '';

    // check whether got fasting range exist
    let gotFastingRange = false;
    if (fastingPatient === true && range.filter(item => item.fasting === true).length > 0){
      gotFastingRange = true;
    }

    for (let i = 0; i < range.length; i++) {
      const valueOpeVal = range[i].valueOperator;
      const valueText = range[i].valueText;
      const value1 = range[i].value1;
      const value2 = range[i].value2;
      const colour = range[i].colour;
      const ageOpeVal = range[i].ageOperator;
      const age1 = range[i].age1;
      const age2 = range[i].age2;
      const gender = range[i].gender;
      const fasting = (!range[i].fasting) ? false : range[i].fasting;
      if (
        (fastingPatient === false && fasting === false) // if not fasting
        || (fastingPatient === true && fasting === true && gotFastingRange === true) // if fasting, got fasting range
        || (fastingPatient === true && fasting === false && gotFastingRange === false) // if fasting, but no fasting range
      ){
        if (unitType === range[i].type){// 1=cu, 2=si
          if (genderPatient === gender || gender === 3){// 1=Male, 2=Female, 3=Both
            if (colour === 1){// colour 1=default(black), 2=criticalLow(blue), 3=criticalHigh(red)
              // if true, mean range condition is match
              let rangeNeeded = true;

              // gender
              if (gender === 1) {
                genderText = 'M: ';
              } else if (gender === 2) {
                genderText = 'F: ';
              } else if (gender !== 3) {
                rangeNeeded = false;
              }

              // age
              if (rangeNeeded === true){
                if (ageOpeVal === 1 && agePatient >= age1 && agePatient <= age2){// -
                  ageText = age1 + ' - ' + age2 + ' yrs ';
                } else if (ageOpeVal === 2 && agePatient > age1){// >
                  ageText = '>' + age1 + ' yrs ';
                } else if (ageOpeVal === 3 && agePatient < age1){// <
                  ageText = '<' + age1 + ' yrs ';
                } else if (ageOpeVal === 4 && agePatient >= age1) {// ≥
                  ageText = '≥' + age1 + ' yrs ';
                } else if (ageOpeVal === 5 && agePatient <= age1) {// ≤
                  ageText = '≤' + age1 + ' yrs ';
                } else if (ageOpeVal !== 0) { rangeNeeded = false; }
              }

              // value range
              if (rangeNeeded === true){
                if (rsMeasure === 2) { // qualitative
                  if (valueText) {
                    rangeText = '(' + valueText + ')';
                  }
                } else if (rsMeasure === 1) { // quantitative
                  if (valueOpeVal === 1){// -
                    rangeText = '(' + value1 + ' - ' + value2 + ')';
                  } else if (valueOpeVal >= 2 && valueOpeVal <= 5) {// >, <, ≥, ≤
                    rangeText = '(' + operator[valueOpeVal] + ' ' + value1 + ')';
                  }
                }
              }

              // displayList = genderText + ageText + rangeText;
              displayList = rangeText; // show refer range only
            }
          }
        }
      }
    }

    return (displayList === '') ? '-' : displayList;
  }

  getCurrentResult(indexSample: number): string {
    const allResult = this.dataReg.sampleResult[indexSample]; // get all result of a single test
    return (allResult.length === 0) ? null : allResult[allResult.length - 1];
  }

  getCurrentResultRemark(indexSample: number): string {
    const allResult = this.dataReg.sampleResultRemark[indexSample]; // get all resultRemark of a single test
    return (allResult.length === 0) ? null : allResult[allResult.length - 1];
  }

  // get customer choosen unit to display
  // @return 1=CU, 2=SI, 3=CU&SI
  getReportUnit(custId: number): number {
    const cust = this.dataCustomer.find(item => item.id === custId);
    return cust ? Number(cust.reportFormatUnit) : 3;
  }

  getReportLanguage(custId: number): boolean {
    const cust = this.dataCustomer.find(item => item.id === custId);
    return cust ? (cust.reportLanguage === true) : false;
  }

  getReportVersion(reg): number {
    let version = 1;
    for (let i = 0; i < reg.sampleResult.length; i++) {
      if (reg.sampleTempId[i] !== reg.sampleTempId[i - 1]){
        // if got >1 item in sampleResult[], then + sampleResult[].length
        if (Number(reg.sampleResult[i].length) > 1){
          version += Number(reg.sampleResult[i].length);
        }
      }
    }
    return version;
  }

  // @param settingType 1=reportNewPage, 2=reportSelfPage
  // @param subItemType package test item use, check Package.itemIsProfile
  // subItem is profile/test in package or test in profile
  getReportPageSetting(
    testItemId: number, testItemType: number, settingType: number,
    isTestItem: boolean = true, subItemId: number = 0, subItemType: number = 0
  ): boolean {
    let testItem: any = {};

    // get test item
    if (testItemType === 1){// package
      testItem = this.dataTestPackage.find(item => item.id === testItemId);
    } else if (testItemType === 2){// profile
      testItem = this.dataTestProfile.find(item => item.id === testItemId);
    } else if (testItemType === 3) {// test
      testItem = this.dataTest.find(item => item.id === testItemId);
      // if testItem is test, subItem is also test, return false
      if (testItemType === subItemType) {
        return false;
      }
    } else {
      // console.log('Wrong testItemType: ' + testItemType);
    }

    if (isTestItem === true){
      if (settingType === 1){// report new page
        return (testItem.reportNewPage) ? testItem.reportNewPage : false;
      } else if (settingType === 2){// report self page
        return (testItem.reportSelfPage) ? testItem.reportSelfPage : false;
      }
    } else {// if is item inside testItem
      if (testItemType === 1){// package
        const isProfile = (subItemType === 2) ? true : false;
        for (let i = 0; i < testItem.itemId.length; i++) {
          if (testItem.itemId[i] === subItemId && testItem.itemIsProfile[i] === isProfile){
            if (settingType === 1){// report new page
              return (testItem.itemReportNewPage && testItem.itemReportNewPage[i]) ? testItem.itemReportNewPage[i] : false;
            } else if (settingType === 2){// report self page
              return (testItem.itemReportSelfPage && testItem.itemReportSelfPage[i]) ? testItem.itemReportSelfPage[i] : false;
            }
          }
        }
      } else if (testItemType === 2){// profile
        for (let i = 0; i < testItem.test.length; i++) {
          if (testItem.test[i] === subItemId){
            if (settingType === 1){// report new page
              return (testItem.testReportNewPage && testItem.testReportNewPage[i]) ? testItem.testReportNewPage[i] : false;
            } else if (settingType === 2){// report self page
              return (testItem.testReportSelfPage && testItem.testReportSelfPage[i]) ? testItem.testReportSelfPage[i] : false;
            }
          }
        }
      } else if (testItemType === 3){// test
        if (settingType === 1){// report new page
          return (testItem.reportNewPage) ? testItem.reportNewPage : false;
        } else if (settingType === 2){// report self page
          return (testItem.reportSelfPage) ? testItem.reportSelfPage : false;
        }
      }
    }

    return false;
  }
}
