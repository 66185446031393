import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TestPackage } from './test-package';

@Injectable({
  providedIn: 'root'
})
export class TestPackageService {

  private url = environment.apiURL + 'tests-packages';

  constructor(private http: HttpClient) { }

  getAllTestPackage(): Observable<TestPackage[]>{
    return this.http.get<TestPackage[]>(this.url);
  }

  getTestPackage(id: number): Observable<TestPackage>{
    return this.http.get<TestPackage>(this.url + '/' + id);
  }

  createTestPackage(testPackage: TestPackage): Observable<object>{
    return this.http.post(this.url + '/create', testPackage);
  }

  updateTestPackage(testPackage: TestPackage): Observable<any>{
    return this.http.patch(this.url + '/update', testPackage);
  }

  deleteTestPackage(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }
}
