<!-- button: download -->
<div [class.d-none]="!isShowPreview" class="px-2 py-1">
	<div class="btn btn-primary text-light a4" (click)="downloadPDF(true)">
		<i class="feather icon-download text-light"></i> DOWNLOAD TEST REPORT
	</div>
</div>

<!-- report -->
<div [class.d-none]="!isShowPreview">
	<div #pdfData id="pdfData" class="a4">
		<ng-container *ngFor="let pageNum of pageArr">
			<div [class]="{ 'page-break' : pageNum != 1 }">
				<div class="header">
					<ng-container *ngTemplateOutlet="header; context: {
						hideTitle: pageNum != 1,
						pageNow: pageNum,
						pageTotal: pageTotal
					}"></ng-container>
				</div>
				<hr>
				<div class="body">
					<ng-container *ngTemplateOutlet="body; context: {
						pageNum: pageNum
					}"></ng-container>
				</div>
				<div class="footer">
					<ng-container *ngTemplateOutlet="footer"></ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</div>


<!-- Template START -->
<ng-template #body let-pageNum="pageNum">
	<ng-container *ngFor="let dataTest of dataReport.resultData, index as iTestItem">

		<!-- TestItem is Package && showAll == false -->
		<ng-container *ngIf="dataTest.testItemType == 1">
			<!-- TestItemName (Package) -->
			<div *ngIf="pageNum == dataTest.page" class="row">
				<div class="col-12">
					<p style="font-size: 8px; margin-bottom: 8px">
						<b class="font-weight-bold">
							{{ dataTest.testItemName }}
							<span class="jspdf_chinese" *ngIf="dataReport.showChinese == true && dataTest.testItemNameChinese">
								({{ dataTest.testItemNameChinese }})
							</span>
						</b>
					</p>
				</div>
			</div>

			<div class="row">
				<div class="col-12 text-left patient-detail-desc">
					<ng-container *ngFor="let innerTest of dataTest.inner, index as iInner">

						<!-- Profile/Test Header (underline) -->
						<ng-container *ngIf="pageNum == innerTest.page && innerTest.show == true">
							<ng-container *ngTemplateOutlet="resultHeaderRow; context: { 
								testName: innerTest.testItemName, testNameChinese: innerTest.testItemNameChinese,
								type: innerTest.resultType, unitDisplay: dataReport.unitDisplay,
								underline:true
							}"></ng-container>
						</ng-container>

						<!-- Result -->
						<ng-container *ngFor="let dataResult of innerTest.result, index as iRs">
							<ng-container *ngIf="pageNum == dataResult.page && dataResult.showCU == true && dataResult.showSI == true">
								<ng-container *ngTemplateOutlet="resultRow; context: {
									showCU: dataResult.showCU, showSI: dataResult.showSI,
									testName: dataResult.testName, testNameChinese: dataResult.testNameChinese,
									type: dataResult.resultType, unitDisplay: dataReport.unitDisplay,
									rsCU: dataResult.resultCU, unitCU: dataResult.unitCU, rangeCU: dataResult.rangeCU,
									rsSI: dataResult.resultSI, unitSI: dataResult.unitSI, rangeSI: dataResult.rangeSI,
									remarkCU: dataResult.remarkRangeCU, remarkSI: dataResult.remarkRangeSI,
									rangeRemarkCU: dataResult.rangeRemarkCU, rangeRemarkSI: dataResult.rangeRemarkSI,
									resultRemark: dataResult.resultRemark,
									description: dataResult.description,
									index: iRs, len: innerTest.result.length
								}"></ng-container>
							</ng-container>
						</ng-container>

						<ng-container *ngIf="pageNum == innerTest.pageRemark">
							<!-- Description -->
							<ng-container *ngTemplateOutlet="descriptionRow; context: {
								description: innerTest.description
							}"></ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>

		<!-- TestItem is Profile/Test -->
		<ng-container *ngIf="dataTest.testItemType > 1">
			<div class="row">
				<div class="col-12 text-left patient-detail-desc">

					<!-- Profile/Test Header -->
					<ng-container *ngIf="pageNum == dataTest.page">
						<ng-container *ngTemplateOutlet="resultHeaderRow; context: { 
							testName: dataTest.testItemName, testNameChinese: dataTest.testItemNameChinese,
							type: dataTest.resultType, unitDisplay: dataReport.unitDisplay,
							underline: false
						}"></ng-container>
					</ng-container>

					<!-- Result -->
					<ng-container *ngFor="let dataResult of dataTest.result, index as iRs">
						<ng-container *ngIf="pageNum == dataResult.page && dataResult.showCU && dataResult.showSI">
							<ng-container *ngTemplateOutlet="resultRow; context: {
								showCU: dataResult.showCU, showSI: dataResult.showSI,
								testName: dataResult.testName, testNameChinese: dataResult.testNameChinese,
								type: dataResult.resultType, unitDisplay: dataReport.unitDisplay,
								rsCU: dataResult.resultCU, unitCU: dataResult.unitCU, rangeCU: dataResult.rangeCU,
								rsSI: dataResult.resultSI, unitSI: dataResult.unitSI, rangeSI: dataResult.rangeSI,
								remarkCU: dataResult.remarkRangeCU, remarkSI: dataResult.remarkRangeSI,
								rangeRemarkCU: dataResult.rangeRemarkCU, rangeRemarkSI: dataResult.rangeRemarkSI,
								resultRemark: dataResult.resultRemark,
								description: dataResult.description,
								index: iRs, len: dataTest.result.length
							}"></ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="pageNum == dataTest.pageRemark && dataTest.testItemType >= 1">
			<!-- Description -->
			<ng-container *ngTemplateOutlet="descriptionRow; context: {
				description: dataTest.description
			}"></ng-container>

			<!-- Reject -->
			<ng-container *ngTemplateOutlet="rejectReasonRow; context: {
				reason: dataTest.rejectReason
			}"></ng-container>

			<!-- Validate & Verify -->
			<ng-container *ngTemplateOutlet="vvRow; context: {
				valBy: dataTest.validateBy, valDT: dataTest.validateDateTime,
				verBy: dataTest.verifyBy, verDT: dataTest.verifyDateTime
			}"></ng-container>
		</ng-container>

		<!-- End Of Report -->
		<ng-container *ngIf="pageNum == dataTest.page && dataTest.testItemType == -1">
			<ng-container *ngTemplateOutlet="EndOfReportCol"></ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<!-- Header -->
<ng-template #header let-hideTitle="hideTitle" let-pageNow="pageNow" let-pageTotal="pageTotal">
	<div *ngIf="hideTitle == false" class="row">
		<div class="col-8">
			<ng-container *ngIf="dataReport.showLogo == true">
				<!-- default: MediLab Logo -->
				<img *ngIf="dataReport.defaultLogo == true" src="{{pathLogo}}" width="200">

				<!-- if show customer logo -->
				<img *ngIf="dataReport.defaultLogo == false" src="{{pathLogo}}" height="50">
			</ng-container>
		</div>	
		<div class="col-4 text-right">
			<figure class="m-0 p-0 text-center float-right">
				<!-- PREV VERSION - deprecated (https://developers.google.com/chart/infographics/docs/qr_codes) -->
				<!-- <p>{{pathQR}}</p>
				<img class="m-0 p-0" src="{{pathQR}}" height="50"/> -->
				<!-- <qrcode [qrdata]="pathQR" [width]="50" [errorCorrectionLevel]="'M'" class="m-0 p-0" [imageHeight]=50 [imageWidth]=50></qrcode> -->
				<!-- Android phone camare need this "[allowEmptyString]="true"" attribute to scan qr code -->
				<qrcode
					[qrdata]="pathQR"
					[allowEmptyString]="true"
					[ariaLabel]="'QR Code image with the following content...'"
					[cssClass]="'right'"
					[elementType]="'canvas'"
					[errorCorrectionLevel]="'H'"
					[scale]="1"
					[title]="'A custom title attribute'"
					[width]="50"
				></qrcode>
				<figcaption class="font-weight-bold" style="font-size: 7px">
					{{ this.reportPassword }}
				</figcaption>
			</figure>
		</div>	
	</div>
	<div *ngIf="hideTitle == false" class="row">
		<div class="col-12 text-center">
			<p style="font-size: 10px; font-weight: 600; margin-bottom: 10px;"><b class="font-weight-bold">TEST REPORT</b></p>
		</div>	
	</div>

	<div class="row">
		<div class="col-12 patient-detail-desc">
			<p style="font-size: 6px" class="font-weight-bold">Patient Details</p>
		</div>	
	</div>
	<div class="row">
		<div class="col-12 text-left patient-detail-desc">
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">Name</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-4 pl-0">
					<p style="font-size: 6px"><b class="font-weight-bold">{{ dataReport.name }}</b></p>
				</div>
				<div class="col-3 text-right pr-1">
					<p style="font-size: 6px">Age/Gender :</p>
				</div>
				<div class="col-2 pl-0 text-left">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.ageGender }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">IC/Passport No</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-4 pl-0">
					<p style="font-size: 6px"><b class="font-weight-bold text-nowrap">{{ dataReport.icPassport }}</b></p>
				</div>
				<div class="col-3 text-right pr-1">
					<p style="font-size: 6px">Date/Time Sample Collected :</p>
				</div>
				<div class="col-2 pl-0 text-left">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.sampleCollectDateTime }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">Date of Birth</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-4 pl-0">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.birth }}</p>
				</div>
				<div class="col-3 text-right pr-1">
					<p style="font-size: 6px">Date/Time Sample Received :</p>
				</div>
				<div class="col-2 pl-0 text-left">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.sampleReceiveDateTime }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">Contact No</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-4 pl-0">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.contact }}</p>
				</div>
				<div class="col-3 text-right pr-1">
					<p style="font-size: 6px">Date/Time of Report :</p>
				</div>
				<div class="col-2 pl-0 text-left">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.reportDateTime }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">Lab ID No</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-4 pl-0">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.lab }}</p>
				</div>
				<div class="col-3 text-right pr-1">
					<p style="font-size: 6px">Report Version :</p>
				</div>
				<div class="col-2 pl-0 text-left">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.reportVersion }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">Referral Name</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-4 pl-0">
					<p style="font-size: 6px" class="text-nowrap">{{ dataReport.custName }}</p>
				</div>
				<div class="col-3 text-right pr-1">
					<p style="font-size: 6px"></p>
				</div>
				<div class="col-2 pl-0 text-left">
					<p style="font-size: 6px" class="text-nowrap"></p>
				</div>
			</div>
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">Referral Dr Name</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-4 pl-0">
					<p style="font-size: 6px" class="text-nowrap">{{dataReport.custPic}}</p>
				</div>
				<div class="col-3 text-right pr-1">
					<p style="font-size: 6px"></p>
				</div>
				<div class="col-2 pl-0 text-left">
					<p style="font-size: 6px" class="text-nowrap">Page {{ pageNow }} of {{ pageTotal }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-2 pr-0">
					<p style="font-size: 6px">Specimen type</p>
				</div>
				<div class="col-1 text-center px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-9 pl-0">
					<p style="font-size: 6px">{{ dataReport.specimenType }}</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- End Of Report -->
<ng-template #EndOfReportCol>
	<div class="col-12 text-center">
		<div class="row">
			<div class="col-12" style="margin-bottom: 0px">
				<p style="font-size: 6px; margin-bottom: 2px" class="font-weight-bold">
					COMPUTER GENERATED REPORT - NO SIGNATURE REQUIRED
				</p>
			</div>
			<div class="col-12" style="margin-bottom: 0px">
				<p style="font-size: 6px; margin-bottom: 2px" class="font-weight-bold">
					END OF TEST REPORT
				</p>
			</div>
		</div>
	</div>
</ng-template>

<!-- Footer -->
<ng-template #footer>
	<div class="row">
		<div class="col-12 text-center">
			<p style="font-size: 7px; margin-bottom: 2px" class="font-weight-bold">
				{{ dataReport.institutionBranch ? dataReport.institutionBranch : 'MediLabz' }}
			</p>
			<p style="font-size: 6px; margin-bottom: 2px">
				{{ dataReport.institutionAddress ? dataReport.institutionAddress : '' }}
			</p>
		</div>
		<div class="col-12 text-center">
			<div class="row">
				<div class="col-12 text-center">
					<p style="font-size: 6px; margin-bottom: 2px">
						Tel : {{ dataReport.institutionPhone ? dataReport.institutionPhone : '-' }}
						<span class="mx-2"></span>
						Email: {{ dataReport.institutionEmail ? dataReport.institutionEmail : '-' }}
					</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- Result Header (col header for refer use, eg: Result, CU/SI unit, Ref. Range) -->
<ng-template #resultHeaderRow
	let-testName="testName" let-testNameChinese="testNameChinese"
	let-type="type" let-unitDisplay="unitDisplay"
	let-underline="underline"
>
	<div *ngIf="type == 1" class="row">
		<!-- english testItem name header -->
		<div class="col-3 pr-0">
			<!-- underline -->
			<p *ngIf="underline == true" style="font-size: 6px" class="font-weight-bold">
				<b class="font-weight-bold">
					<u>{{ testName }}</u>
				</b>
			</p>
			<!-- no underline -->
			<p *ngIf="underline == false" style="font-size: 6px">
				<b class="font-weight-bold">{{ testName }}</b>
			</p>
		</div>
		<!-- chinese testItem name header -->
		<div *ngIf="dataReport.showChinese == true && unitDisplay != 3" class="col-2 pr-0">
			<!-- underline -->
			<p *ngIf="underline == true" style="font-size: 6px" class="font-weight-bold">
				<b class="font-weight-bold">
					<u class="jspdf_chinese font-weight-bold">{{ testNameChinese }}</u>
				</b>
			</p>
			<!-- no underline -->
			<p *ngIf="underline == false" style="font-size: 6px">
				<b class="jspdf_chinese font-weight-bold">
					{{ testNameChinese }}
				</b>
			</p>
		</div>
		<div class="col-1 pr-0">
			<p style="font-size: 6px"></p>
		</div>
		<!-- col header for result -->
		<div class="row p-0" [ngClass]="dataReport.showChinese == true && unitDisplay != 3 ? 'col-6' : 'col-8'">
			<!-- CU & SI -->
			<ng-container *ngIf="unitDisplay == 3">
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px"><i>Result</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>CU unit</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>Ref. Range</i></p>
				</div>
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px"><i>Result</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>SI unit</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>Ref. Range</i></p>
				</div>
			</ng-container>
			<!-- CU -->
			<ng-container *ngIf="unitDisplay == 1">
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px"><i>Result</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>CU unit</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>Ref. Range</i></p>
				</div>
			</ng-container>
			<!-- SI -->
			<ng-container *ngIf="unitDisplay == 2">
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px"><i>Result</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>SI unit</i></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px"><i>Ref. Range</i></p>
				</div>
			</ng-container>
		</div>
	</div>

	<div *ngIf="type == 2" class="row">
		<!-- english testItem name header -->
		<div class="col-3 pr-0">
			<!-- underline -->
			<p *ngIf="underline == true" style="font-size: 6px" class="font-weight-bold">
				<b class="font-weight-bold">
					<u>{{ testName }}</u>
				</b>
			</p>
			<!-- no underline -->
			<p *ngIf="underline == false" style="font-size: 6px">
				<b class="font-weight-bold">{{ testName }}</b>
			</p>
		</div>
		<!-- chinese testItem name header -->
		<div *ngIf="dataReport.showChinese == true && unitDisplay != 3" class="col-3 pr-0">
			<!-- underline -->
			<p *ngIf="underline == true" style="font-size: 6px" class="font-weight-bold">
				<b class="font-weight-bold">
					<u class="jspdf_chinese font-weight-bold">{{ testNameChinese }}</u>
				</b>
			</p>
			<!-- no underline -->
			<p *ngIf="underline == false" style="font-size: 6px">
				<b class="jspdf_chinese font-weight-bold">
					{{ testNameChinese }}
				</b>
			</p>
		</div>
	</div>
</ng-template>

<!-- Result -->
<ng-template #resultRow 
	let-showCU="showCU" let-showSI="showSI"
	let-testName="testName" let-testNameChinese="testNameChinese"
	let-type="type" let-unitDisplay="unitDisplay"
	let-rsCU="rsCU" let-unitCU="unitCU" let-rangeCU="rangeCU"
	let-rsSI="rsSI" let-unitSI="unitSI" let-rangeSI="rangeSI"
	let-remarkCU="remarkCU" let-remarkSI="remarkSI"
	let-rangeRemarkCU="rangeRemarkCU" let-rangeRemarkSI="rangeRemarkSI"
	let-resultRemark="resultRemark"
	let-description="description"
	let-index="index" let-len="len"
>
	<!-- Number Result -->
	<div *ngIf="type == 1" class="row">
		<!-- english test name -->
		<div class="col-3 pr-0">
			<p style="font-size: 6px">
				{{ testName }}
			</p>
		</div>
		<!-- chinese test name -->
		<div *ngIf="dataReport.showChinese == true && unitDisplay != 3" class="col-2 pr-0">
			<p style="font-size: 6px" class="jspdf_chinese">
				{{ testNameChinese }}
			</p>
		</div>
		<div class="col-1 px-0">
			<p style="font-size: 6px">:</p>
		</div>
		<div class="row p-0" [ngClass]="dataReport.showChinese == true && unitDisplay != 3 ? 'col-6' : 'col-8'">
			<!-- result CU & SI -->
			<ng-container *ngIf="unitDisplay == 3 && showCU == true && showSI == true">
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px" [innerHTML]="rsCU"></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px" class="text-wrap" [innerHTML]="unitCU"></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px" [innerHTML]="rangeCU"></p>
				</div>
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px" [innerHTML]="rsSI"></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px" class="text-wrap" [innerHTML]="unitSI"></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px" [innerHTML]="rangeSI"></p>
				</div>
			</ng-container>
			<!-- result CU -->
			<ng-container *ngIf="unitDisplay == 1 && showCU == true">
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px" [innerHTML]="rsCU"></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px" class="text-wrap" [innerHTML]="unitCU"></p>
				</div>
				<div class="col-8 px-0">
					<p style="font-size: 6px" [innerHTML]="rangeCU"></p>
				</div>
			</ng-container>
			<!-- result SI -->
			<ng-container *ngIf="unitDisplay == 2 && showSI == true">
				<div class="col-2 px-0 text-center">
					<p style="font-size: 6px" [innerHTML]="rsSI"></p>
				</div>
				<div class="col-2 px-0">
					<p style="font-size: 6px" class="text-wrap" [innerHTML]="unitSI"></p>
				</div>
				<div class="col-8 px-0">
					<p style="font-size: 6px" [innerHTML]="rangeSI"></p>
				</div>
			</ng-container>
		</div>
	</div>

	<!-- String Result -->
	<div *ngIf="type == 2" class="row">
		<!-- english test name -->
		<div class="col-3 pr-0">
			<p style="font-size: 6px">
				{{ testName }}
			</p>
		</div>
		<!-- chinese test name -->
		<div *ngIf="dataReport.showChinese == true && unitDisplay != 3" class="col-2 pr-0">
			<p style="font-size: 6px" class="jspdf_chinese">
				{{ testNameChinese }}
			</p>
		</div>
		<div class="col-1 px-0">
			<p style="font-size: 6px">:</p>
		</div>
		<div class="row p-0"  [ngClass]="dataReport.showChinese == true && unitDisplay != 3 ? 'col-6' : 'col-8'">
			<div class="col-12 pl-3 pr-1">
				<p style="font-size: 6px" class="text-justify" [innerHTML]="rsSI"></p>
			</div>
			<!--
				Just comment out this, prevent client suddenly change requirement again
				<ng-container *ngIf="rangeSI != '-'; else noQualitativeRange">
				<div class="col-4 pl-3 pr-0">
					<p style="font-size: 6px" [innerHTML]="rsSI"></p>
				</div>
				<div class="col-8 pl-0 text-left">
					<p style="font-size: 6px" class="text-left" [innerHTML]="rangeSI"></p>
				</div>
			</ng-container>
			<ng-template #noQualitativeRange>
				<div class="col-12 pl-3 pr-1">
					<p style="font-size: 6px" class="text-justify" [innerHTML]="rsSI"></p>
				</div>
			</ng-template> -->
		</div>
	</div>

	<ng-container *ngTemplateOutlet="remarkRow; context: {
		remarkCU:remarkCU, remarkSI:remarkSI,
		showCU: showCU, showSI: showSI,
		unitDisplay:unitDisplay
	}"></ng-container>
	<ng-container *ngTemplateOutlet="rangeRemarkRow; context: {
		rangeRemarkCU:rangeRemarkCU, rangeRemarkSI:rangeRemarkSI,
		showCU: showCU, showSI: showSI,
		unitDisplay:unitDisplay
	}"></ng-container>
	<ng-container *ngTemplateOutlet="resultRemarkRow; context: { resultRemark:resultRemark }"></ng-container>
	<ng-container *ngTemplateOutlet="descriptionRow; context: { description:description }"></ng-container>

	<!-- mb-2, for last result row -->
	<div *ngIf="index == len - 1" style="height: 8px"></div>
</ng-template>

<!-- Remark -->
<ng-template #remarkRow
	let-remarkCU="remarkCU" let-remarkSI="remarkSI"
	let-showCU="showCU" let-showSI="showSI"
	let-unitDisplay="unitDisplay"
>
	<div *ngIf="((remarkCU && remarkCU != '') || (remarkSI && remarkSI != '')) && (showCU == true || showSI == true)" class="row">
		<div class="col-3 pr-0">
			<p style="font-size: 6px"></p>
		</div>
		<div *ngIf="dataReport.showChinese == true && unitDisplay != 3" class="col-2 pr-0">
			<p style="font-size: 6px"></p>
		</div>
		<div class="col-1 px-0">
			<p style="font-size: 6px"></p>
		</div>
		<div class="row p-0" [ngClass]="dataReport.showChinese == true && unitDisplay != 3 ? 'col-6' : 'col-8'">
			<!-- remark CU unit -->
			<div *ngIf="unitDisplay != 2 && showCU == true" class="col-6 pl-3 pr-1">
				<p *ngIf="remarkCU && remarkCU != ''" style="font-size: 6px" class="text-justify">
					<b class="font-weight-bold">Remark: </b>{{ remarkCU }}
				</p>
			</div>
			<!-- remark SI unit -->
			<div *ngIf="unitDisplay != 1 && showSI == true" class="col-6 pl-3 pr-1">
				<p *ngIf="remarkSI && remarkSI != ''" style="font-size: 6px" class="text-justify">
					<b class="font-weight-bold">Remark: </b>{{ remarkSI }}
				</p>
			</div>
		</div>
	</div>
</ng-template>

<!-- Range Remark -->
<ng-template #rangeRemarkRow
	let-rangeRemarkCU="rangeRemarkCU" let-rangeRemarkSI="rangeRemarkSI"
	let-showCU="showCU" let-showSI="showSI"
	let-unitDisplay="unitDisplay"
>
	<div *ngIf="((rangeRemarkCU && rangeRemarkCU != '') || (rangeRemarkSI && rangeRemarkSI != '')) && (showCU == true || showSI == true)" class="row">
		<div class="col-3 pr-0">
			<p style="font-size: 6px"></p>
		</div>
		<div *ngIf="dataReport.showChinese == true && unitDisplay != 3" class="col-2 pr-0">
			<p style="font-size: 6px"></p>
		</div>
		<div class="col-1 px-0">
			<p style="font-size: 6px"></p>
		</div>
		<div class="row p-0" [ngClass]="dataReport.showChinese == true && unitDisplay != 3 ? 'col-6' : 'col-8'">
			<!-- range remark CU unit -->
			<div *ngIf="unitDisplay != 2 && showCU == true" class="col-6 pl-3 pr-1">
				<p *ngIf="rangeRemarkCU && rangeRemarkCU != ''" style="font-size: 6px" class="text-justify">
					<b class="font-weight-bold">Remark: </b>{{ rangeRemarkCU }}
				</p>
			</div>
			<!-- range remark SI unit -->
			<div *ngIf="unitDisplay != 1 && showSI == true" class="col-6 pl-3 pr-1">
				<p *ngIf="rangeRemarkSI && rangeRemarkSI != ''" style="font-size: 6px" class="text-justify">
					<b class="font-weight-bold">Remark: </b>{{ rangeRemarkSI }}
				</p>
			</div>
		</div>
	</div>
</ng-template>

<!-- Result Remark -->
<ng-template #resultRemarkRow let-resultRemark="resultRemark" let-unitDisplay="unitDisplay">
	<div *ngIf="resultRemark != '' && resultRemark != null" class="row">
		<div class="col-3 pr-0">
			<p style="font-size: 6px"></p>
		</div>
		<div *ngIf="dataReport.showChinese == true && unitDisplay != 3" class="col-2 pr-0">
			<p style="font-size: 6px"></p>
		</div>
		<div class="col-1 px-0">
			<p style="font-size: 6px"></p>
		</div>
		<div class="row p-0" [ngClass]="dataReport.showChinese == true && unitDisplay != 3 ? 'col-6' : 'col-8'">
			<div class="col-12 pl-3 pr-1">
				<p style="font-size: 6px" class="text-justify">
					<b class="font-weight-bold">Note: </b>{{ resultRemark }}
				</p>
			</div>
		</div>
	</div>
</ng-template>

<!-- Description -->
<ng-template #descriptionRow let-description="description">
	<div *ngIf="description != ''" class="row">
		<div class="col-12 px-4">
			<p style="font-size: 6px" class="text-justify" [innerHTML]="description"></p>
		</div>
	</div>
</ng-template>

<!-- Reject Reason -->
<ng-template #rejectReasonRow let-reason="reason">
	<div *ngIf="reason != null" class="row">
		<div class="col-12 text-left patient-detail-desc mb-1">
			<div class="row">
				<div class="col-3 pr-0 text-right">
					<p style="font-size: 6px">Note</p>
				</div>
				<div class="col-1 px-0">
					<p style="font-size: 6px">:</p>
				</div>
			</div>
			<div class="row">
				<div class="col-9 px-0 ml-auto">
					<p style="font-size: 6px">[**] {{ reason }}</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- Validate & Verify -->
<ng-template #vvRow let-valBy="valBy" let-valDT="valDT" let-verBy="verBy" let-verDT="verDT">
	<div class="row">
		<div class="col-12 text-left patient-detail-desc my-2">
			<!-- Validate -->
			<div class="row">
				<div class="col-3 pr-0">
					<p style="font-size: 6px">Validated by</p>
				</div>
				<div class="col-1 px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-8 px-0">
					<p *ngIf="valBy == null" style="font-size: 6px">-</p>
					<p *ngIf="valBy != null" style="font-size: 6px">{{ valBy }} at {{ valDT }}</p>
				</div>
			</div>
			<!-- Verify -->
			<div class="row">
				<div class="col-3 pr-0">
					<p style="font-size: 6px">Verified by</p>
				</div>
				<div class="col-1 px-0">
					<p style="font-size: 6px">:</p>
				</div>
				<div class="col-8 px-0">
					<p *ngIf="verBy == null" style="font-size: 6px">-</p>
					<p *ngIf="verBy != null" style="font-size: 6px">{{ verBy }} at {{ verDT }}</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!-- Template END -->