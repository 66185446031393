import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Lab } from './lab';

@Injectable({
  providedIn: 'root'
})
export class LabService {

  private url = environment.apiURL + 'lab';

  constructor(private http: HttpClient) { }

  getAllLab(): Observable<Lab[]>{
    return this.http.get<Lab[]>(this.url);
  }

  getLab(id: number): Observable<Lab>{
    return this.http.get<Lab>(this.url + '/' + id);
  }

  createLab(lab: Lab): Observable<object>{
    return this.http.post(this.url + '/create', lab);
  }

  updateLab(lab: Lab): Observable<any>{
    return this.http.patch(this.url + '/update', lab);
  }

  deleteLab(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }

  getNextLab(institutionId: number): Observable<any>{
    return this.http.get(this.url + '/getNextName/' + institutionId, {responseType: 'text'});
  }
}
