import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../../../layout/admin/navigation/navigation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    @Input() type: string;

    public navigation: any;
    breadcrumbList: Array<any> = [];
    public navigationList: Array<any> = [];

    constructor(private route: Router, public nav: NavigationItem, private titleService: Title) {
        this.navigation = this.nav.get();
        this.setBreadcrumb();
    }

    ngOnInit(): void {
        let routerUrl: string;
        routerUrl = this.route.url;
        if (routerUrl && typeof routerUrl === 'string') {
            this.filterNavigation(this.filterUrl(routerUrl));
        }
    }

    setBreadcrumb(): void {
        let routerUrl: string;
        this.route.events.subscribe((router: any) => {
            routerUrl = router.urlAfterRedirects;
            if (routerUrl && typeof routerUrl === 'string') {
                this.breadcrumbList.length = 0;
                const activeLink = router.url;
                this.filterNavigation(this.filterUrl(activeLink));

                // prevent page unscrollable if redirect from modal btn
                document.body.classList.remove('modal-open');
            }
        });
    }

    // if the last ele of url is id (is number),then remove it
    filterUrl(oriUrl): string {
        const lastEle = oriUrl.split('/').slice(-1)[0];
        if ((!isNaN(Number(lastEle)))) {
            oriUrl = oriUrl.slice(0, oriUrl.lastIndexOf('/')); // if lastEle is number (is id), remove it
        }
        return oriUrl;
    }

    filterNavigation(activeLink): void {
        let result = [];
        let title = 'Welcome';
        this.navigation.forEach((a) => {
            if (a.type === 'item' && 'url' in a && a.url === activeLink) {
                result = [
                    {
                        url: 'url' in a ? a.url : false,
                        title: a.title,
                        breadcrumbs: 'breadcrumbs' in a ? a.breadcrumbs : true,
                        type: a.type,
                        dynamicRoute: a.dynamicRoute
                    }
                ];
                title = a.title;
            } else {
                if (a.type === 'group' && 'children' in a) {
                    a.children.forEach((b) => {
                        if (b.type === 'item' && 'url' in b && b.url === activeLink) {
                            result = [
                                {
                                    url: 'url' in b ? b.url : false,
                                    title: b.title,
                                    breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                                    type: b.type,
                                    dynamicRoute: b.dynamicRoute
                                }
                            ];
                            title = b.title;
                        } else {
                            if (b.type === 'collapse' && 'children' in b) {
                                b.children.forEach((c) => {
                                    if (c.type === 'item' && 'url' in c && c.url === activeLink) {
                                        result = [
                                            {
                                                url: 'url' in b ? b.url : false,
                                                title: b.title,
                                                breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                                                type: b.type,
                                                dynamicRoute: b.dynamicRoute
                                            },
                                            {
                                                url: 'url' in c ? c.url : false,
                                                title: c.title,
                                                breadcrumbs: 'breadcrumbs' in c ? c.breadcrumbs : true,
                                                type: c.type,
                                                dynamicRoute: c.dynamicRoute
                                            }
                                        ];
                                        title = c.title;
                                    } else {
                                        if (c.type === 'collapse' && 'children' in c) {
                                            c.children.forEach((d) => {
                                                if (d.type === 'item' && 'url' in d && d.url === activeLink) {
                                                    result = [
                                                        {
                                                            url: 'url' in b ? b.url : false,
                                                            title: b.title,
                                                            breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                                                            type: b.type,
                                                            dynamicRoute: b.dynamicRoute
                                                        },
                                                        {
                                                            url: 'url' in c ? c.url : false,
                                                            title: c.title,
                                                            breadcrumbs: 'breadcrumbs' in c ? c.breadcrumbs : true,
                                                            type: c.type,
                                                            dynamicRoute: c.dynamicRoute
                                                        },
                                                        {
                                                            url: 'url' in d ? d.url : false,
                                                            title: d.title,
                                                            breadcrumbs: 'breadcrumbs' in c ? d.breadcrumbs : true,
                                                            type: d.type,
                                                            dynamicRoute: d.dynamicRoute
                                                        }
                                                    ];
                                                    title = d.title;
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });
        this.navigationList = result;
        this.titleService.setTitle(title + ' | MediLabz');
    }
}
