<div class="d-none">
    <div #pdfData class="a4">
    	<div class="header">
    		<div class="row">
    			<div class="col-12 text-center">
    				<div class="title" style="margin-bottom: 10px">
	    				<h2>Medilabz Sdn. Bhd.</h2>
    				</div>
    			</div>	
    		</div>
    		<div class="row">
    			<div class="col-11 mx-auto mb-2">
    				<div class="header-border">
    					<div class="row m-0">
			    			<div class="col-6 px-0">
			    				<h2 style="margin-top:7px">Customer Consent Form</h2>
			    			</div>	
			    			<div class="col-3 px-0">
			    				<div class="title">
			    					<p style="margin-top: 7px;">Page No 1 of 1 </p>
			    				</div>
			    			</div>	
			    			<div class="col-3 px-0">
			    				<div class="title text-right">
			    					<p>F/CSD/06<br/>Rev. No.00 </p>
			    				</div>
			    			</div>	
			    		</div>
    				</div>
    			</div>	
    		</div>
    	</div>
    	<div class="body">
    		<div class="row">
    			<div class="col-11 mx-auto">
    				<div class="header-border">
    					<div class="row m-0">
			    			<div class="col-12 px-0">
			    				<h3>Personal Information</h3>
			    			</div>	
			    		</div>
    					<div class="row m-0">
			    			<div class="col-6 pl-0">
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Name</p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
										{{ data.name }}
									</div>
			    				</div>
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>IC Number</p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
										{{ data.ic ? data.ic : '-' }}
			    					</div>
			    				</div>
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Date of Birth </p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
										{{ data.birth }}
									</div>
			    				</div>
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Contact Number</p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
										{{ data.contact }}
									</div>
			    				</div>
							</div>	
			    			<div class="col-6 pl-0">
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Gender</p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
										<ng-container *ngIf="data.gender == 1">Male</ng-container>
										<ng-container *ngIf="data.gender == 2">Female</ng-container>
			    					</div>
			    				</div>
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Passport number</p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
										{{ data.passport ? data.passport : '-' }}
									</div>
			    				</div>
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Age</p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
										{{ data.age }}
			    					</div>
			    				</div>
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Email address </p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0 space-fix">
										{{ data.email ? data.email : '-' }}
			    					</div>
			    				</div>
			    			</div>
							<div class="col-12 pl-0">
								<div class="row m-0">
			    					<div class="col-2 px-0">
			    						<p class="float-left">Address</p><span class="float-right">:</span>
			    					</div>
			    					<div class="col-10 px-0 pl-2">
										{{ data.address }}
			    					</div>
			    				</div>
			    			</div>	
			    		</div>
    				</div>
    			</div>	
    		</div>
    		<div class="row">
    			<div class="col-11 mx-auto mb-2">
    				<div class="header-border">
    					<div class="row m-0">
			    			<div class="col-12 px-0">
			    				<h3>Detail of Test</h3>
			    			</div>	
			    		</div>
    					<div class="row m-0">
			    			<div class="col-12 pl-0">
			    				<div class="row m-0">
			    					<div class="col-3 px-0">
										<p class="float-left">Name of Test / Package</p><span class="float-right">:</span>
			    					</div>
			    					<div class="col-9 px-0 pl-2 space-fix">
										{{ data.testItemNameList }}
			    					</div>
			    				</div>
			    			</div>	
			    			<div class="col-6 pl-0">
			    				<div class="row m-0">
			    					<div class="col-6 px-0">
			    						<p>Date and Time of Testing</p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-5 px-0">
										{{ data.sampleCollectDateTime }}
			    					</div>
			    				</div>
			    			</div>		
			    			<div class="col-6 pl-0">
			    				<div class="row m-0">
			    					<div class="col-4 px-0">
			    						<p>Testing Site </p>
			    					</div>
			    					<div class="col-1 px-0">:</div>
			    					<div class="col-6 px-0">
			    					</div>
			    				</div>
			    			</div>		
			    		</div>
    				</div>
    			</div>	
    		</div>
    		<div class="row">
    			<div class="col-11 mx-auto mb-2">
    				<div class="header-border">
    					<div class="row m-0">
			    			<div class="col-12 px-0">
			    				<h3>Consent </h3>
			    			</div>	
			    		</div>
    					<div class="row m-0">
			    			<div class="col-12 pl-0">
			    				<div>
			    					<div class="box-border"></div>
			    					<label for="yes" class="space-fix" style="position: relative; top: -5px">
										<b>Yes</b>, I provide consent to the above testing. (Please read and sign form below).
									</label>
			    				</div>
			    				<div>
			    					<div class="box-border"></div>
			    					<label for="no" class="space-fix" style="position: relative; top: -5px">
										<b>No</b>, I do not provide consent to the above testing. (No further action needed).
									</label>
			    				</div>
			    			</div>
			    		</div>
    					<div class="row m-0">
			    			<div class="col-12 pl-0">
			    				<p class="space-fix">By signing and submitting this form, I attest that:</p>
			    				<ol>
			    					<li><span class="space-fix">I am the appropriate individual or guardian to provide consent.</span></li>
			    					<li><span class="space-fix">I have signed this form freely and voluntarily.</span></li>
			    					<li><span class="space-fix">I acknowledge that I have been explained regarding the test, including its uses, benefits, limitations, and the meaning of test results.</span></li>
			    					<li><span class="space-fix">I understand that test results may be shared with the ordering physician, country, and other local, state, and federal public health authorities, as well as other testing partners as permitted by law.</span></li>
			    					<li><span class="space-fix">I have received a copy of this Customer Consent Form for record purposes.</span></li>
			    				</ol>
			    				<p class="text-justify space-fix" style="margin-top: 10px">I further undertake to indemnify and hold Medilabz Sdn. Bhd. and its employees, harmless from and against all actions, proceedings, losses, shortfalls, damages, compensation, costs (including legal costs), charges and expenses resulting from my actions, negligence, or dishonesty.</p>
			    			</div>
			    		</div>
    				</div>
    			</div>	
    		</div>
    		<div class="row">
    			<div class="col-11 mx-auto">
    				<div class="header-border">
    					<div class="row m-0">
			    			<div class="col-6 pl-0">
			    				<div class="row m-0">
			    					<div class="col-12 px-0">
			    						<p>Customer SIGNATURE :</p>
			    					</div>
			    					<div class="col-12 px-0">
			    						<div class="signature"></div>
			    					</div>
			    					<div class="col-12 px-0">
					    				<div class="row m-0">
					    					<div class="col-4 px-0">
					    						<p>Name</p>
					    					</div>
					    					<div class="col-1 px-0">:</div>
					    					<div class="col-6 px-0"></div>
					    				</div>
			    					</div>
			    					<div class="col-12 px-0">
					    				<div class="row m-0">
					    					<div class="col-4 px-0">
					    						<p>IC/ Passport No.</p>
					    					</div>
					    					<div class="col-1 px-0">:</div>
					    					<div class="col-6 px-0"></div>
					    				</div>
			    					</div>
			    					<div class="col-12 px-0">
					    				<div class="row m-0">
					    					<div class="col-4 px-0">
					    						<p>Date</p>
					    					</div>
					    					<div class="col-1 px-0">:</div>
					    					<div class="col-6 px-0"></div>
					    				</div>
			    					</div>
			    				</div>
			    			</div>	
			    			<div class="col-6 pl-0">
			    				<div class="row m-0">
			    					<div class="col-12 px-0">
			    						<p>Individual Conducting Consent discussion SIGNATURE :</p>
			    					</div>
			    					<div class="col-12 px-0">
			    						<div class="signature-individual"></div>
			    					</div>
			    					<div class="col-12 px-0">
					    				<div class="row m-0">
					    					<div class="col-4 px-0">
					    						<p>Name</p>
					    					</div>
					    					<div class="col-1 px-0">:</div>
					    					<div class="col-6 px-0"></div>
					    				</div>
			    					</div>
			    					<div class="col-12 px-0">
					    				<div class="row m-0">
					    					<div class="col-4 px-0">
					    						<p>IC/ Passport No.</p>
					    					</div>
					    					<div class="col-1 px-0">:</div>
					    					<div class="col-6 px-0"></div>
					    				</div>
			    					</div>
			    					<div class="col-12 px-0">
					    				<div class="row m-0">
					    					<div class="col-4 px-0">
					    						<p>Date</p>
					    					</div>
					    					<div class="col-1 px-0">:</div>
					    					<div class="col-6 px-0"></div>
					    				</div>
			    					</div>
			    				</div>
			    			</div>		
			    		</div>
    				</div>
    			</div>	
    		</div>
    	</div>
	</div>
</div>