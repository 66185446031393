<div class="d-none">
    <div #pdfData class="a4">
		<ng-container *ngFor="let pageCurrent of pageArray">
			<div class="header">
				<div class="row">
					<div class="col-8 text-center">
						<div class="title">
							<h2>Medilabz Sdn. Bhd.</h2>
							<h3>{{ dept.deptName | uppercase }} WORKSHEET</h3>
						</div>
					</div>	
					<div class="col-4 text-right">
						<div class="header-right">
							<p>F/ML/20</p>
							<p>Rev. No. 00</p>
							<p class="my-1">Date: {{ data[0]?.datetime | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
							<p class="mb-3">Batch: {{ data[0]?.batch }}</p>
						</div>
					</div>	
				</div>
			</div>
			<div class="body">
				<table class="pdf-table">
					<ng-container *ngFor="let item of data, index as i">
						<ng-container *ngIf="item.dataPage.page == pageCurrent">
							<tr *ngIf="i % 2 == 0">
								<ng-container *ngTemplateOutlet="worksheetBox; context:{ index: i }"></ng-container>
								<ng-container *ngTemplateOutlet="worksheetBox; context:{ index: i+1 }"></ng-container>
							</tr>
						</ng-container>
					</ng-container>
				</table>
			</div>
	
			<div class="footer mt-2" [ngStyle]="{'height': pageCurrent == pageTotal ? '50px' : '76px' }">
				<div class="row">
					<div class="col-7">
						<div class="test-detail">
							<p>Test Performed By:  </p>
							<p>Date:  </p>
						</div>
					</div>
					<div class="col-5">
						<div class="test-detail">
							<p>Test Checked By:  </p>
							<p>Date:  </p>
						</div>
					</div>
				</div>
				<div class="col-12 text-right" style="margin-top: 20px">
					<p style="font-size: 7.5px">
						Page {{ pageCurrent }} of {{ pageTotal }}
					</p>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<!-- Template -->
<ng-template #worksheetBox let-i="index">
	<ng-container *ngFor="let item of data, index as idx">
		<td #box *ngIf="idx == i" class="text-left" style="width: 148.5mm">
			<p style="font-size: 8.2px;" class="mb-0 font-weight-bold">
				{{ item?.name }}
			</p>
			<p style="font-size: 8px;" class="mr-1 mb-1">
				<span class="font-weight-bold mr-1">{{ item?.lab }} ({{ item?.qty }})</span>
				<span class="font-weight-normal">{{ item?.testItemName }}</span>
			</p>
			<div class="text-secondary row">
				<ng-container *ngFor="let sampleName of item.sampleName">
					<div [ngClass]="sampleName.length >= 35 ? 'col-12' : 'col-6'">
						{{ sampleName }}
					</div>
				</ng-container>
			</div>
		</td>
		<td *ngIf="idx == i && data.length == 1" style="width: 148.5mm"></td>
	</ng-container>
</ng-template>