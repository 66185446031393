import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserDetails } from './user-details';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

    private url = environment.apiURL + 'user-details';

  constructor(private http: HttpClient) { }

  // getAllUserDetails(): Observable<UserDetails[]>{
  //   return this.http.get<UserDetails[]>(this.url);
  // }

  getUserDetails(id: number): Observable<UserDetails>{
    return this.http.get<UserDetails>(this.url + '/' + id);
  }

  createUserDetails(userDetails: UserDetails): Observable<object>{
    return this.http.post(this.url + '/create', userDetails);
  }

  updateUserDetails(userDetails: UserDetails): Observable<any>{
    return this.http.patch(this.url + '/update', userDetails);
  }

  deleteUserDetails(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }

  // Additional API
  getUserDetailsByEmail(email: string): Observable<UserDetails>{
    return this.http.get<UserDetails>(this.url + '/email/' + email);
  }
}
