import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Sample } from './sample';

@Injectable({
  providedIn: 'root'
})
export class SampleService {

  private url = environment.apiURL + 'sample';

  constructor(private http: HttpClient) { }

  getAllSample(): Observable<Sample[]>{
    return this.http.get<Sample[]>(this.url);
  }

  getSample(id: number): Observable<Sample>{
    return this.http.get<Sample>(this.url + '/' + id);
  }

  createSample(sample: Sample): Observable<object>{
    return this.http.post(this.url + '/create', sample);
  }

  updateSample(sample: Sample): Observable<any>{
    return this.http.patch(this.url + '/update', sample);
  }

  deleteSample(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }

  // Additional API
  getSampleByReportPwd(pwd: string): Observable<Sample>{
    return this.http.get<Sample>(this.url + '/reportpassword/' + pwd);
  }

  getNextReceipt(sample: Sample): Observable<object>{
    return this.http.post(this.url + '/getNextReceipt', sample);
  }

  getSampleByCreated(startDate: Date, endDate: Date): Observable<Sample[]>{
    return this.http.get<Sample[]>(this.url + '/fetch/' + startDate + '/' + endDate);
  }

  updateSampleResultByExcel(excel: FormData): Observable<any>{
    return this.http.patch(this.url + '/updateSampleResultByExcel', excel);
  }
}
