import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Nationality } from './nationality';

@Injectable({
  providedIn: 'root'
})
export class NationalityService {

  private url = environment.apiURL + 'nationality';

  constructor(private http: HttpClient) { }

  getAllNationality(): Observable<Nationality[]>{
    return this.http.get<Nationality[]>(this.url);
  }

  getNationality(id: number): Observable<Nationality>{
    return this.http.get<Nationality>(this.url + '/' + id);
  }

  createNationality(nationality: Nationality): Observable<object>{
    return this.http.post(this.url + '/create', nationality);
  }

  updateNationality(nationality: Nationality): Observable<any>{
    return this.http.patch(this.url + '/update', nationality);
  }

  deleteNationality(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }
}
