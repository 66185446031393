import { Injectable } from '@angular/core';

export interface NavigationItem {
    id: string;
    title: string;
    type: 'item' | 'collapse' | 'group';
    translate?: string;
    icon?: string;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
    dynamicRoute?: boolean;
    customClass?: string;
}

export interface Navigation extends NavigationItem {
    children?: NavigationItem[];
}

const NavigationItems = [
    {
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: 'feather icon-pie-chart',
                url: '/dashboard'
            },
            {
                id: 'appointment',
                title: 'Appointment',
                type: 'item',
                url: '/appointment',
                icon: 'feather icon-calendar',
                hidden: true,
            },
            {
                id: 'appointment',
                title: 'Appointment',
                type: 'collapse',
                url: '/appointment',
                hidden: true,
                children: [
                    {
                        id: 'appointment-create',
                        title: 'Create Appointment',
                        type: 'item',
                        url: '/appointment/create',
                        hidden: true
                    },
                    {
                        id: 'appointment-view',
                        title: 'View Appointment',
                        type: 'item',
                        url: '/appointment/view',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            // sample nav START
            {
                id: 'sample-registration',
                title: 'Sample Registration',
                type: 'item',
                url: '/sample/registration',
                icon: 'feather icon-file-plus',
                hidden: true,
            },
            {
                id: 'sample-registration',
                title: 'Sample Registration',
                type: 'collapse',
                url: '/sample/registration',
                hidden: true,
                children: [
                    {
                        id: 'sample-registration-create',
                        title: 'Create New Sample',
                        type: 'item',
                        url: '/sample/registration/create',
                        hidden: true
                    },
                    {
                        id: 'sample-registration-view',
                        title: 'View Sample',
                        type: 'item',
                        url: '/sample/registration/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'sample-registration-update',
                        title: 'Update Sample',
                        type: 'item',
                        url: '/sample/registration/edit',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            {
                id: 'sample-transfer',
                title: 'Sample Transfer',
                type: 'item',
                icon: 'feather icon-file',
                url: '/sample/transfer',
                hidden: true
            },
            {
                id: 'sample-receive',
                title: 'Sample Receive',
                type: 'item',
                icon: 'feather icon-inbox',
                url: '/sample/receive',
                hidden: true
            },
            {
                id: 'worksheet',
                title: 'Worksheet',
                type: 'item',
                icon: 'feather icon-file-text',
                url: '/sample/worksheet',
                hidden: true
            },
            {
                id: 'rejected-sample',
                title: 'Rejected Sample',
                type: 'item',
                icon: 'feather icon-file-minus',
                url: '/sample/rejected',
                hidden: true
            },
            {
                id: 'test-order',
                title: 'Test Order',
                type: 'item',
                url: '/sample/test-order',
                icon: 'feather icon-clipboard',
                hidden: true,
            },
            {
                id: 'test-order',
                title: 'Test Order',
                type: 'collapse',
                url: '/sample/test-order',
                hidden: true,
                children: [
                    {
                        id: 'test-order-view',
                        title: 'View Test Order',
                        type: 'item',
                        url: '/sample/test-order/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'test-order-update',
                        title: 'Edit Test Order',
                        type: 'item',
                        url: '/sample/test-order/edit',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            {
                id: 'archive',
                title: 'Archive',
                type: 'item',
                url: '/sample/archive',
                icon: 'feather icon-book',
                hidden: true
            },
            {
                id: 'archive',
                title: 'Archive',
                type: 'collapse',
                url: '/sample/archive',
                hidden: true,
                children: [
                    {
                        id: 'archive-view',
                        title: 'View Archive',
                        type: 'item',
                        url: '/sample/archive/view',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            // sample nav END
            // management nav START
            {
                id: 'management',
                title: 'Management',
                type: 'collapse',
                icon: 'feather icon-grid',
                hidden: true,
                children: [
                    {
                        id: 'inventory',
                        title: 'Inventory',
                        type: 'item',
                        icon: 'feather icon-package',
                        url: '/management/inventory',
                        hidden: true
                    },
                    {
                        id: 'customer-management',
                        title: 'Customer',
                        type: 'item',
                        icon: 'feather icon-briefcase',
                        url: '/management/customer',
                        hidden: true
                    },
                    {
                        id: 'user-management',
                        title: 'User',
                        type: 'item',
                        icon: 'feather icon-users',
                        url: '/management/user',
                        hidden: true
                    },
                    {
                        id: 'billing-&-account',
                        title: 'Billing and Account',
                        type: 'item',
                        icon: 'feather icon-feather',
                        url: '/management/billing-account',
                        hidden: true
                    },
                    {
                        id: 'department',
                        title: 'Department',
                        type: 'item',
                        icon: 'feather icon-server',
                        url: '/management/department',
                        hidden: true
                    },
                    {
                        id: 'ptest',
                        title: 'Test',
                        type: 'item',
                        icon: 'feather icon-thermometer',
                        url: '/management/p-test',
                        hidden: true
                    }
                ]
            },
            {
                id: 'inventory',
                title: 'Inventory Management',
                type: 'item',
                url: '/management/inventory',
                hidden: true,
            },
            {
                id: 'inventory',
                title: 'Inventory Management',
                type: 'collapse',
                url: '/management/inventory',
                hidden: true,
                children: [
                    {
                        id: 'inventory-create',
                        title: 'Create Inventory',
                        type: 'item',
                        url: '/management/inventory/create',
                        hidden: true
                    },
                    {
                        id: 'inventory-view',
                        title: 'View Inventory',
                        type: 'item',
                        url: '/management/inventory/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'inventory-history',
                        title: 'View Inventory History',
                        type: 'item',
                        url: '/management/inventory/history',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'inventory-update',
                        title: 'Update Inventory',
                        type: 'item',
                        url: '/management/inventory/edit',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'inventory-sale',
                        title: 'Sales',
                        type: 'item',
                        url: '/management/inventory/sale',
                        hidden: true
                    },
                    {
                        id: 'inventory-sale',
                        title: 'Sales',
                        type: 'collapse',
                        url: '/management/inventory/sale',
                        hidden: true,
                        children: [
                            {
                                id: 'inventory-sale-create',
                                title: 'Create New Sale',
                                type: 'item',
                                url: '/management/inventory/sale/create',
                                hidden: true
                            }
                        ]
                    }
                ]
            },
            {
                id: 'customer-management',
                title: 'Customer Management',
                type: 'item',
                url: '/management/customer',
                hidden: true,
            },
            {
                id: 'customer-management',
                title: 'Customer Management',
                type: 'collapse',
                url: '/management/customer',
                hidden: true,
                children: [
                    {
                        id: 'customer-management-create',
                        title: 'Create New Customer',
                        type: 'item',
                        url: '/management/customer/create',
                        hidden: true
                    },
                    {
                        id: 'customer-management-view',
                        title: 'View Customer',
                        type: 'item',
                        url: '/management/customer/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'customer-management-update',
                        title: 'Edit Customer',
                        type: 'item',
                        url: '/management/customer/edit',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            {
                id: 'user-management',
                title: 'User Management',
                type: 'item',
                url: '/management/user',
                hidden: true,
            },
            {
                id: 'user-management',
                title: 'User Management',
                type: 'collapse',
                url: '/management/user',
                hidden: true,
                children: [
                    {
                        id: 'user-management-create',
                        title: 'Create New User',
                        type: 'item',
                        url: '/management/user/create',
                        hidden: true
                    },
                    {
                        id: 'user-management-view',
                        title: 'View User',
                        type: 'item',
                        url: '/management/user/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'user-management-update',
                        title: 'Edit User',
                        type: 'item',
                        url: '/management/user/edit',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            {
                id: 'department',
                title: 'Department Management',
                type: 'item',
                url: '/management/department',
                hidden: true,
            },
            {
                id: 'department',
                title: 'Department Management',
                type: 'collapse',
                url: '/management/department',
                hidden: true,
                children: [
                    {
                        id: 'department-create',
                        title: 'Create Department',
                        type: 'item',
                        url: '/management/department/create',
                        hidden: true
                    },
                    {
                        id: 'department-view',
                        title: 'View Department',
                        type: 'item',
                        url: '/management/department/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'department-update',
                        title: 'Edit Department',
                        type: 'item',
                        url: '/management/department/edit',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            {
                id: 'test',
                title: 'Test Management',
                type: 'item',
                url: '/management/test',
                hidden: true,
            },
            {
                id: 'test',
                title: 'Test Management',
                type: 'collapse',
                url: '/management/test',
                hidden: true,
                children: [
                    {
                        id: 'test-create',
                        title: 'Create Single Test',
                        type: 'item',
                        url: '/management/test/create',
                        hidden: true
                    },
                    {
                        id: 'test-view',
                        title: 'View Single Test',
                        type: 'item',
                        url: '/management/test/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'test-update',
                        title: 'Edit Single Test',
                        type: 'item',
                        url: '/management/test/edit',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            {
                id: 'ptest',
                title: 'Test Management',
                type: 'item',
                url: '/management/p-test',
                hidden: true,
            },
            {
                id: 'ptest',
                title: 'Test Management',
                type: 'collapse',
                url: '/management/p-test',
                hidden: true,
                children: [
                    {
                        id: 'test-package',
                        title: 'Test Package',
                        type: 'item',
                        url: '/management/p-test/package',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'test-profile',
                        title: 'Test Profile',
                        type: 'item',
                        url: '/management/p-test/profile',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'test-p-create',
                        title: 'Create Test Package/Profile',
                        type: 'item',
                        url: '/management/p-test/create',
                        hidden: true
                    },
                    {
                        id: 'test-package-view',
                        title: 'View Test Package',
                        type: 'item',
                        url: '/management/p-test/package/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'test-profile-view',
                        title: 'View Test Profile',
                        type: 'item',
                        url: '/management/p-test/profile/view',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'test-package-edit',
                        title: 'Edit Test Package',
                        type: 'item',
                        url: '/management/p-test/package/edit',
                        hidden: true,
                        dynamicRoute: true
                    },
                    {
                        id: 'test-profile-edit',
                        title: 'Edit Test Profile',
                        type: 'item',
                        url: '/management/p-test/profile/edit',
                        hidden: true,
                        dynamicRoute: true
                    }
                ]
            },
            // management nav END
            {
                id: 'profile',
                title: 'User Profile',
                type: 'item',
                url: '/profile',
                hidden: true
            },
            {
                id: 'profile',
                title: 'User Profile',
                type: 'collapse',
                url: '/profile',
                hidden: true,
                children: [
                    {
                        id: 'profile-edit',
                        title: 'Edit Profile',
                        type: 'item',
                        url: '/profile/edit',
                        hidden: true
                    },
                    {
                        id: 'password-change',
                        title: 'Change Password',
                        type: 'item',
                        url: '/profile/changePassword',
                        hidden: true
                    }
                ]
            },
            {
                id: 'setting',
                title: 'Setting',
                type: 'item',
                url: '/setting',
                hidden: true
            },
            {
                id: 'setting',
                title: 'Setting',
                type: 'collapse',
                url: '/setting',
                hidden: true,
                children: [
                    {
                        id: 'result-measure',
                        title: 'Result Measurement',
                        type: 'item',
                        url: '/setting/result-measure',
                        hidden: true
                    },
                    {
                        id: 'customer-type',
                        title: 'Customer Type',
                        type: 'item',
                        url: '/setting/customer-type',
                        hidden: true
                    },
                    {
                        id: 'payment-mode',
                        title: 'Payment Mode',
                        type: 'item',
                        url: '/setting/payment-mode',
                        hidden: true
                    },
                    {
                        id: 'nationality',
                        title: 'Nationality',
                        type: 'item',
                        url: '/setting/nationality',
                        hidden: true
                    },
                    {
                        id: 'institution',
                        title: 'Institution',
                        type: 'item',
                        url: '/setting/institution',
                        hidden: true
                    },
                    {
                        id: 'salutation',
                        title: 'Salutation',
                        type: 'item',
                        url: '/setting/salutation',
                        hidden: true
                    },
                    {
                        id: 'unit-measure',
                        title: 'Unit Measurement',
                        type: 'item',
                        url: '/setting/unit-measure',
                        hidden: true
                    },
                    {
                        id: 'machine',
                        title: 'Machine',
                        type: 'item',
                        url: '/setting/machine',
                        hidden: true
                    },
                    {
                        id: 'account-type',
                        title: 'AccountType',
                        type: 'item',
                        url: '/setting/account-type',
                        hidden: true
                    }
                ]
            }
        ]
    },
    {
        id: 'server-version',
        title: 'v 0.0.17',
        type: 'group',
        customClass: 'text-center text-lowercase'
    }
];

@Injectable()
export class NavigationItem {
    public get(): any {
        return NavigationItems;
    }
}
