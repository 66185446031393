import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TestProfile } from './test-profile';

@Injectable({
  providedIn: 'root'
})
export class TestProfileService {

  private url = environment.apiURL + 'tests-profiles';

  constructor(private http: HttpClient) { }

  getAllTestProfile(): Observable<TestProfile[]>{
    return this.http.get<TestProfile[]>(this.url);
  }

  getTestProfile(id: number): Observable<TestProfile>{
    return this.http.get<TestProfile>(this.url + '/' + id);
  }

  createTestProfile(testProfile: TestProfile): Observable<object>{
    return this.http.post(this.url + '/create', testProfile);
  }

  updateTestProfile(testProfile: TestProfile): Observable<any>{
    return this.http.patch(this.url + '/update', testProfile);
  }

  deleteTestProfile(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }
}
