<perfect-scrollbar
    *ngIf="this.nextConfig['layout'] === 'vertical'"
    (clickOutside)="navMob()"
    [excludeBeforeClick]="true"
    [exclude]="'#mobile-collapse'"
    id="nav-ps-next"
    ngClass="able-pro-scroll"
    [style.max-width]="'300px'"
    [style.max-height]="'calc(100vh - 60px)'"
    [usePSClass]="'next'"
    [disabled]="null"
>
    <div class="navbar-content" (mouseleave)="isNavProfile = false">
        <div class="">
            <div class="main-menu-header mb-1">
                <!-- icon: MediLabz -->
                <img class="img-radius mt-3 img-fluid" src="assets/img/medilabz-icon.png" alt="Logo-Image" />
                <div class="user-details" (click)="isNavProfile = !isNavProfile">
                    <div id="more-details">
                        {{ dataUserDetails.name }}
                        <i class="fa fa-caret-down" [ngClass]="{ 'fa-caret-up': isNavProfile }"></i>
                    </div>
                </div>
            </div>
            <div *ngIf="isNavProfile" class="collapse mt-3 border-top border-bottom" id="nav-user-link" [style.display]="'block'">
                <ul class="list-unstyled">
                    <li class="list-group-item" routerLink="/profile">
                        <i class="feather icon-user m-r-5"></i>View Profile
                    </li>
                    <li *ngIf="showSettingNav == true" class="list-group-item" routerLink="/setting">
                        <i class="feather icon-settings m-r-5"></i>Settings
                    </li>
                    <li class="list-group-item" (click)="logout()">
                        <i class="feather icon-log-out m-r-5"></i>Logout
                    </li>
                </ul>
            </div>
        </div>
        <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type == 'group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type == 'collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
        <!-- <app-card [hidHeader]="true" cardClass="text-center">
            <i class="feather icon-sunset f-40"></i>
            <h6 class="mt-3">Help?</h6>
            <p>Please contact us on our email for need any support</p>
            <a href="javascript:" target="_blank" class="btn btn-primary btn-sm text-white m-0">Support</a>
        </app-card> -->
    </div>
</perfect-scrollbar>

<div *ngIf="this.nextConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal" id="layout-sidenav">
    <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
    <div class="sidenav-horizontal-wrapper">
        <!-- add 14-4 viral -->
        <ul
            #navbarContent
            id="side-nav-horizontal"
            class="nav pcoded-inner-navbar sidenav-inner"
            (clickOutside)="fireLeave()"
            (mouseleave)="fireLeave()"
        >
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type == 'group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type == 'collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
    <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>
