import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { PipeService } from '../pipe/pipe.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  // static data
  readonly dataTube = [
    { id: 1, name: 'Plain Tube', abbr: 'P' },
    { id: 2, name: 'Urine Container', abbr: 'U' },
    { id: 3, name: 'Edta Tube', abbr: 'E' },
    { id: 4, name: 'Viral Transport Media', abbr: 'VTM' },
    { id: 5, name: 'Fluoride Tube', abbr: 'F' },
    { id: 6, name: 'Histopathology Examination', abbr: 'HPE' }
  ];

  constructor(
    private pipeService: PipeService,
  ) { }

  /**
   * check whether data is existed, only can 1 key & value (JSON)
   * @param JSON the complete data get from database
   * @param input value to check
   * @param titleText title text for Swal
   * @param trimLower trim() & lowercase() before check
   * @param valueId id don't want to checking, use during update data
   * @param valueKey JSON's key to check
   * @return boolean value, is duplicate or not
   */
  checkRepeat(JSON: any, input: any, titleText: string, trimLower: boolean = true, valueId: number = -1, valueKey: any = 'name'): boolean {
    JSON = JSON.filter(item => (item[valueKey] != null && item[valueKey] !== undefined)); // filter null data
    const dataInput = (trimLower) ? input.toLowerCase().trim() : input;
    for (let i = 0; i < JSON.length; i++) {
      const dataJSON = (trimLower) ? (JSON[i][valueKey])?.toLowerCase().trim() : JSON[i][valueKey];
      if (dataJSON == dataInput && JSON[i].id != valueId) { // if exist
        Swal.fire({
          icon: 'error',
          title: titleText + ' Existed'
        });
        return true;
      }
    }
    return false;
  }


  /* Sorting Function START */
  /**
   * sort JSON data by id asc
   * @param JSON data wanna to sort
   * @return sorted JSON data
   */
  sortIdAsc(JSON: any): any {
    JSON = JSON.filter(item => (item.id != null && item.id !== undefined)); // filter null data
    return JSON.sort((a, b) => a.id - b.id);
  }

  /**
   * sort JSON data by JSON date
   * @param JSON data wanna to sort
   * @param valueKey JSON key used to sort, default: created
   */
  sortDateDesc(JSON: any, valueKey: any = 'created'): any {
    JSON = JSON.filter(item => (item[valueKey] != null && item[valueKey] !== undefined)); // filter null data
    return JSON.sort((a: any, b: any) =>
      (new Date(this.pipeService.transformSystemDateTimeFormat(b[valueKey])).getTime())
      - (new Date(this.pipeService.transformSystemDateTimeFormat(a[valueKey])).getTime())
    );
  }

  /**
   * sort JSON data by JSON key
   * @param JSON data wanna to sort
   * @param valueKey JSON key used to sort, default: name
   * @return sorted JSON data
   */
  sortAsc(JSON: any, valueKey: any = 'name'): any {
    JSON = JSON.filter(item => (item[valueKey] != null && item[valueKey] !== undefined)); // filter null data
    return JSON.sort((a, b) => (a[valueKey].toLowerCase() > b[valueKey].toLowerCase()) ? 1 : -1);
  }
  /* Sorting Function END */


  // get data without direct/linking relationship
  getPureData(json: any): any {
    if (typeof json !== 'undefined') {
      return JSON.parse(JSON.stringify(json));
    }
  }

  /**
   * generate a text with random value
   * @param length length of text
   * @return generated random text
   */
  getRandomText(length: number = 8): string {
    const templateText = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let text = '';
    for (let i = 0; i < length; i++) {
      text += templateText[Math.floor(Math.random() * templateText.length)];
    }
    return text;
  }

  // filter by date in some index page, default is 2 week duration
  getIndexFilterDate(type: string): any {
    const today = new Date();
    if (type === 'min') {
      const min = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2); // last 3 days
      const displayDate = this.pipeService.transformDateFormat(min) + ' 00:00';
      return this.pipeService.transformSystemDateTimeFormat(displayDate);
    } else if (type === 'max') {
      const max = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1); // tmr
      const displayDate = this.pipeService.transformDateFormat(max) + ' 00:00';
      return this.pipeService.transformSystemDateTimeFormat(displayDate);
    } else {
      throw new Error('Invalid Type Param, type: ' + type);
    }
  }

  /**
   * show JSON data by loggedUser's institution, data filter by 'updateBy'(default) user's institution
   * @param JSON data wanna to filter
   * @param dataUser all dataUser
   * @param loggedUserInstitutionId loggedUser.institutionId
   * @param key Object key (JSON key) to filter, default is updatedBy
   * @return JSON filtered by institution
   */
  filterDataByUserInstitution(JSON: any, dataUser: any, loggedUserInstitutionId: any, key: string = 'updatedBy'): any {
    JSON = JSON.filter(item => (item[key] !== null && item[key] !== undefined)); // filter null data
    const array: any = [];
    JSON.forEach(item => {
      const user = dataUser.find(aUser => aUser.id == item[key]);
      if (user && user.institutionId == loggedUserInstitutionId) {
        array.push(item);
      }
    });
    return array;
  }

  /**
   * filter JSON by logged-in user.institutionId
   * @param JSON data wanna to filter
   * @param loggedUserInstitutionId loggedUser.institutionId
   * @return JSON filtered by institution
   */
  filterDataByInstitution(JSON: any, institutionId: number, institutionViewList: any = [], key: string = 'institution'): any {
    JSON = JSON.filter(item => (item[key] != null && item[key] !== undefined)); // filter null data
    const array: any = [];

    // filter by institutionId
    JSON.forEach(item => {
      if (item[key] == 0 || item[key] == institutionId) {
        array.push(item);
      }
    });

    // filter by institutionViewList, if != institutionId
    if (institutionViewList) {
      for (let i = 0; i < institutionViewList.length; i++) {
        const branchId = institutionViewList[i];
        if (branchId != institutionId) {
          JSON.forEach(item => {
            if (item[key] !== 0 && item[key] == branchId) {
              array.push(item);
            }
          });
        }
      }
    }

    return array;
  }

  // check institution access setting
  canAccess(accessArray: any, labId: number, dataLab, loggedUser): boolean {
    // for old data, cuz value is null
    if (!accessArray) {
      // check user institution is same as sample institution?
      const lab = dataLab.find(item => item.id == labId);
      if (
        lab && (
          lab.institutionId == loggedUser.institutionId ||
          loggedUser.institutionViewList.indexOf(lab.institutionId) !== -1
        )
      ) {
        return true;
      }
      return false;
    } else {
      // loggedUser's institution_Id
      if (accessArray.indexOf(loggedUser.institutionId) !== -1) {
        return true;
      }

      // loggedUser's institution_View_List
      for (let i = 0; i < loggedUser.institutionViewList.length; i++) {
        if (accessArray.indexOf(loggedUser.institutionViewList[i]) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  // normally for batch.name & lab.name use
  // @return current date in MMYY format
  getCurrentMMYY(): string {
    const today = new Date();
    const dateMMYY = ((today.getMonth() < 10) ? '0' : '') + (today.getMonth() + 1).toString() + (today.getFullYear() % 100).toString();
    // console.log(dateMMYY); //#
    return dateMMYY;
  }

  // @params array return false if: [0, 0, 0, 0, 0, 0]
  gotTubeUsed(array: any): boolean {
    return array.reduce((sum, num) => sum + num, 0) > 0;
  }

  /* get display data function START */
  // get sample index name col data
  getSampleDisplayName(patientName: string, dob, collectDT, gender: number): string {
    return patientName + ' (' + this.getSampleAge(dob, collectDT) + (gender == 1 ? 'M' : 'F') + ')';
  }

  // get actual age when sample is collected
  getSampleAge(dob, sampleCollectDateTime): number {
    const yearBirth = (new Date(this.pipeService.transformSystemDateFormat(dob))).getFullYear();
    const yearSample = (new Date(this.pipeService.transformSystemDateTimeFormat(sampleCollectDateTime))).getFullYear();
    return yearSample - yearBirth;
  }

  getIcPassportDisplay(ic: string, passport: string): string {
    return (ic ? ic : '') + (ic && passport ? '/' : '') + (passport ? passport : '');
  }

  getLabName(labId: number, dataLab): string {
    const lab = dataLab.find(item => item.id == labId);
    return lab ? lab.name : '-';
  }

  getSourceName(custTypeId: number, custId: number, dataCustomerType, dataCustomer): string {
    const custType = dataCustomerType.find(item => item.id == custTypeId);
    const cust = dataCustomer.find(item => item.id == custId);
    return custType?.customerTypeName + (cust ? ' - ' + cust.name : '');
  }

  getTubeAbbr(index: number): string {
    const result = this.dataTube[index].abbr;
    return result ? result : null;
  }

  getTubeDisplay(tubeArray): string {
    // if testItem got nothing inside
    if (!tubeArray) {
      return '0';
    }

    let list = '';
    for (let t = 0; t < 6; t++) {
      if (tubeArray[t] == 0) {
        continue;
      } else {
        if (list !== '') {
          list += ', ';
        }
        list += tubeArray[t] + this.getTubeAbbr(t);
      }
    }
    return (list === '') ? '1' : list;
  }

  getUserNameAndAccountType(userId: number, dataUser, dataAccountType): string {
    const user = dataUser.find(item => item.id == userId);
    if (user){
      const accountType = dataAccountType.find(item => item.id == user.accountTypeId);
      return user.name + ' (' + accountType.name + ')';
    }
    return '-';
  }

  getTestItemTypeName(type: number): string {
    if (type === 1) {
      return 'Package';
    } else if (type === 2) {
      return 'Profile';
    } else if (type === 3) {
      return 'Test';
    } else {
      throw new Error('Invalid Test Item Type: ' + type);
    }
  }

  getStatusName(status: number): string {
    switch (status) {
      case 0: return 'Collected';
      case 1: return 'Transferred';
      case 2: return 'Received';
      case 3: return 'Worksheet Generated';
      case 20: return 'Rejected';
      case 21: return 'Rejected & Informed';
      case 22: return 'Rejected & Fail to Inform';
      case 30: return 'Reactivated';
      case 31: return 'Unvoided';
      case 32: return 'Voided';
      case 33: return 'Validated';
      case 34: return 'Verified';
      case 40: return 'Printed';
      case 41: return 'Emailed';
      case 42: return 'Whatsapped';
      case 43: return 'Printed & Emailed';
      case 44: return 'Printed & Whatsapped';
      case 45: return 'Emailed & Whatsapped';
      case 46: return 'Printed & Emailed & Whatsapped';
      case 47: return 'Notified';
      case 48: return 'Notified & Printed';
      case 49: return 'Notified & Emailed';
      case 50: return 'Notified & Whatsapped';
      case 51: return 'Notified & Printed & Emailed';
      case 52: return 'Notified & Printed & Whatsapped';
      case 53: return 'Notified & Emailed & Whatsapped';
      case 54: return 'Notified & Printed & Emailed & Whatsapp';
      default: return '-';
    }
  }
  /* get display data function END */
}
