import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsersRecord } from './users-record';

@Injectable({
  providedIn: 'root'
})
export class UsersRecordService {

  private url = environment.apiURL + 'users-record';

  constructor(private http: HttpClient) { }

  getAllUsersRecord(): Observable<UsersRecord[]>{
    return this.http.get<UsersRecord[]>(this.url);
  }

  // Additional API
  toggleUserStatus(id: number): Observable<object>{
    return this.http.patch(this.url + '/toggle-status/' + id, null);
  }

  toggleUserStatusByStatus(id: number, status: number): Observable<object>{
    return this.http.patch(this.url + '/toggle-status/' + id + '/' + status, null);
  }
}
