import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-worksheet',
  templateUrl: './worksheet.component.html',
  styleUrls: ['./worksheet.component.scss']
})
export class WorksheetComponent implements AfterViewInit, OnInit {

  @ViewChild('pdfData') htmlData;
  data: any;
  dept: any;
  pageTotal = 1;
  pageArray: any = [];

  constructor() { }
  readonly hBody: number = 462;
  readonly hBoxNormal: number = 77; // w/o padding(5+5)
  // line 1 - patient name, age, gender
  readonly hLine1: number = 12.3;
  readonly maxWeightForLine1: number = 48;
  // line 2 - lab, tube used, test item name
  readonly hLine2: number = 10;
  readonly hLine2Margin: number = 4;
  readonly maxWeightForLine2: number = 53;
  // line 3 - item in testItem
  readonly hLine3: number = 9;
  // line unknown (height w/o line1-3 in box)
  readonly hLineUnknown: number = 13;

  ngAfterViewInit(): void {
    this.downloadPDF();
  }

  ngOnInit(): void{
    this.data = JSON.parse(sessionStorage.getItem('worksheet_temp'));
    this.dept = JSON.parse(sessionStorage.getItem('worksheet_dept'));
    this.generatePageData();
    // console.log('data:', this.data);
    // console.log('dept', this.dept);

    // set dept empty if reprint
    if (!this.dept) {
      this.dept = { deptId: 0, deptName: '' };
    }
  }

  generatePageData(): void {
    this.data.forEach((data, idx) => {
      data.dataPage = {};

      // check line 1 got text-wrap
      if (data.name){ // prevent null
        data.dataPage.textWrapLine1Total = Math.floor(data.name.length / this.maxWeightForLine1);
      }

      // check line 2 got text-wrap
      let line2Text = '';
      if (data.lab) {
        line2Text += data.lab + ' ';
      }
      if (data.qty) {
        line2Text += data.qty + ' ';
      }
      if (data.testItemName) { line2Text += data.testItemName; }
      data.dataPage.textWrapLine2Total = Math.floor(line2Text.length / this.maxWeightForLine2);

      // sum total line for item in testItem
      data.dataPage.sampleLineTotal = 0;
      data.sampleName.forEach(sampleName => {
        if (sampleName) { // prevent null
          if (sampleName.length > 35) {
            data.dataPage.sampleLineTotal++;
            // check if last row only got one item (full is 2), if yes add extra
            if (data.dataPage.sampleLineTotal % 1 !== 0) {
              data.dataPage.sampleLineTotal += 0.5;
            }
          } else {
            data.dataPage.sampleLineTotal += 0.5;
          }
        }
      });

      // sum total height used
      data.dataPage.heightTotal = 0;
      data.dataPage.heightTotal += this.hLine1 * (data.dataPage.textWrapLine1Total + 1); // line 1
      // console.log(idx, this.hLine1 * (data.dataPage.textWrapLine1Total + 1)); //#
      data.dataPage.heightTotal += this.hLine2 * (data.dataPage.textWrapLine2Total + 1) + this.hLine2Margin; // line 2
      // console.log(idx, this.hLine2 * (data.dataPage.textWrapLine2Total + 1) + this.hLine2Margin); //#
      data.dataPage.heightTotal += this.hLine3 * Math.ceil(data.dataPage.sampleLineTotal); // line 3
      // console.log(idx, this.hLine3 * Math.ceil(data.dataPage.sampleLineTotal)); //#
      data.dataPage.heightTotal += this.hLineUnknown; // line unknown
      // console.log(idx, data.dataPage.heightTotal); //#
    });

    let hColLeft = 0;
    let hColRight = 0;
    for (let i = 0, hTotal = 0; i < this.data.length; i++) {
      if (i % 2 === 0) { // col left
        hColLeft = this.data[i].dataPage.heightTotal;
        if (this.hBoxNormal > hColLeft) {
          hColLeft = this.hBoxNormal;
        }
      } else { // col right
        hColRight = this.data[i].dataPage.heightTotal;
        if (this.hBoxNormal > hColRight) {
          hColRight = this.hBoxNormal;
        }
      }

      if (
        this.data.length === 1 ||
        i % 2 !== 0 ||
        (i === this.data.length - 1 && i % 2 === 0)
      ){
        // get height for current box row (get the highest one)
        const hRowMax = Math.max(hColLeft, hColRight);

        // check whether got enough height
        if (hTotal + hRowMax >= this.hBody) {
          this.pageTotal++;
          hTotal = hRowMax;
        } else {
          hTotal += hRowMax;
        }

        // set page
        this.data[i].dataPage.page = this.pageTotal;
        if (this.data.length !== 1) {
          this.data[i - 1].dataPage.page = this.pageTotal;
        }

        hColLeft = 0;
        hColRight = 0;
      }
    }

    // prepare this.pageArray
    for (let i = 0; i < this.pageTotal; i++) {
      this.pageArray.push(i + 1);
    }
  }

  downloadPDF(): void {
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('p', 'px', 'a4');

    doc.html(DATA, {
      margin: 10,
      // tslint:disable-next-line:no-shadowed-variable
      callback: (doc) => {
        doc.save('worksheet.pdf');
        Swal.fire( 'Print Successfully', 'Worksheet Printed', 'success' ).then(() => {
          window.close();
        });
      }
    });
  }
}
