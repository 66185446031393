<ul class="navbar-nav ml-auto">
    <li>
        <div class="dropdown drp-user" ngbDropdown placement="auto">
            <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
                <i class="icon feather icon-user"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
                <div class="pro-head">
                    <img src="assets/img/medilabz-icon.png" class="img-radius bg-white" alt="Logo-Image" />
                    <span>{{ username ? username : 'user' }}</span>
                </div>
                <ul class="pro-body">
                    <li>
                        <a href="/profile" class="dropdown-item"><i class="feather icon-user"></i> Profile</a>
                    </li>
                    <li>
                        <a href="/setting" class="dropdown-item"><i class="feather icon-settings"></i> Setting</a>
                    </li>
                    <li>
                        <a href="javascript:" (click)="logout()" class="dropdown-item"><i class="feather icon-log-out"></i> Log Out</a>
                    </li>
                </ul>
            </div>
        </div>
    </li>
</ul>
