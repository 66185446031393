import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AccessLevelGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess(route);
  }

  canAccess(route): boolean {
    const loggedUser = JSON.parse(localStorage.getItem('userDetails'));
    const accessLevel = route.data.accessLevel;
    if (Array.isArray(accessLevel) === true) { // array in routing guard
      for (let i = 0; i < accessLevel.length; i++) {
        if (this.gotPermission(loggedUser[accessLevel[i]]) === true) {
          return true; // if has permission to access
        }
      }
      return this.noPermission(); // cannot access
    } else { // non array in routing guard
      return (this.gotPermission(loggedUser[accessLevel]) !== true) ? this.noPermission() : true;
    }
  }

  gotPermission(accessData): boolean {
    // if accessData is array, then check is any true value, else check value is true
    return (Array.isArray(accessData)) ? accessData?.includes(true) : (accessData === true);
  }

  noPermission(): boolean {
    Swal.fire(
      '403 Forbidden Error',
      'You don\'t has permission to access this page',
      'error'
    ).then(() => {
      window.location.href = '/dashboard';
    });
    return false; // cannot access
  }
}
