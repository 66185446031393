import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlTree } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.userObs.pipe(
      map(res => {
        if (res) {
          return (res.password) ? this.router.createUrlTree(['update']) : true;
        } else {
          sessionStorage.setItem('route', state.url);
          return this.router.createUrlTree(['login']);
        }
      })
    );
  }
}
