import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PipeService {

  constructor(private datePipe: DatePipe) { }

  // dd-MM-yyyy HH:mm:ss
  transformDateTimeFormat(inputDate: any): any {
    if (inputDate === 'now') {
      inputDate = new Date();
    }
    return this.datePipe.transform(inputDate, 'dd-MM-yyyy HH:mm:ss');
  }

  // yyyy-MM-dd HH:mm:ss (from: dd-MM-yyyy HH:mm:ss)
  transformSystemDateTimeFormat(inputDate: any): any {
    if (inputDate === 'now') {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    } else {
      return inputDate?.substr(6, 4) + '-' + inputDate?.substr(3, 2) + '-' + inputDate?.substr(0, 2) + 'T' + inputDate?.substr(11, 8);
    }
  }

  // dd-MM-yyyy
  transformDateFormat(inputDate: any): any {
    if (inputDate === 'now') {
      inputDate = new Date();
    }
    return this.datePipe.transform(inputDate, 'dd-MM-yyyy');
  }

  // yyyy-MM-dd (from: dd-MM-yyyy)
  transformSystemDateFormat(inputDate: any): any {
    if (inputDate === 'now') {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    } else {
      return inputDate.substr(6, 4) + '-' + inputDate.substr(3, 2) + '-' + inputDate.substr(0, 2);
    }
  }

  // yyyy-MM-dd (from: datatable)
  transformMinMaxDateFormat(inputDate: Date): any {
    return this.datePipe.transform(inputDate, 'yyyy-MM-dd');
  }

  // yyyy-MM-dd HH:mm:ss (from: datatable)
  transformMinMaxDateTimeFormat(inputDate: Date): any {
    return this.datePipe.transform(inputDate, 'yyyy-MM-dd HH:mm:ss');
  }
}
