import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportExtraInfoService {

  dataReport: any = {}; // report data
  pageArr: any = [];
  pageTotal = 0;
  pageEmpty = true; // check whether page is empty (exclude header & footer)
  hBodyLeft = 0;

  constructor() { }

  // for test description use
  readonly maxStringLenInLine: number = 140; // max num of character can put in single line
  readonly maxStringLenInLineRemark: number = 40; // max num of character can put in rangeRemark/resultRemark
  readonly maxStringLenInResultValue: number = 13; // max num of character can put in result value, if number type result
  readonly hRowDescription: number = 9;

  /* height of every row type */
  readonly hTestBigHeaderRow: number = 20; // package name, 12 + 8(pb: 8px)
  readonly hNormalRow: number = 10.5;
  readonly hReject: number = 25; // reject reason
  readonly hVerify: number = 37; // validate & verify
  // Page
  readonly hPage: number = 611; // single page total height
  readonly hPageHeader: number = 97.5; // patient details
  readonly hPageHeaderTitle: number = 85.5; // logo & qr & "Test Report" text
  readonly hPageBody: number = 369;
  readonly hPageFooter: number = 38;
  // Special
  readonly h1Rem: number = 16; // 1rem == 16px
  readonly hEndOfReport: number = 22;
  // After part
  readonly hAfterResultGroup: number = 8; // css:mb-2, 0.5rem

  /* Main Function START */
  updatePageHeigthData(reg): any {
    this.dataReport = reg;
    this.filterShowItemCondition();
    this.setHeightData();
    this.setPageData();
    this.dataReport = this.filterSymbol(this.dataReport);
    // this.dataReport = this.removeStarSymbolForResult(this.dataReport);
    // console.log("data w/ height & page", this.dataReport.resultData); //#
    return {
      dataReport: this.dataReport,
      pageTotal: this.pageTotal,
      pageArr: this.pageArr
    };
  }
  /* Main Function END */

  filterShowItemCondition(): void {
    const reg = this.dataReport;
    reg.resultData.forEach(testItem => {
      if (Number(testItem.testItemType) === 1) { // if is Package
        testItem.inner.forEach(subTestItem => {
          if (subTestItem.result.filter(item => item.showItem === true).length > 0) {
            subTestItem.showItem = true;
          }
        });
        if (testItem.inner.filter(item => item.showItem === true).length > 0) {
          testItem.showItem = true;
        }
      } else if (testItem.testItemType > 1) { // if is Profile/Test
        if (testItem.result.filter(item => item.showItem === true).length > 0) {
          testItem.showItem = true;
        }
      }
    });
    // console.log(reg); //# debug use
  }

  setHeightData(): void {
    const reg = this.dataReport;
    reg.resultData.forEach(testItem => {
      if (Number(testItem.testItemType) === 1) { // if is Package
        testItem.inner.forEach(subTestItem => {
          this.setHeightForProfileAndTest(subTestItem, true);
        });
      } else if (testItem.testItemType > 1) { // if is Profile/Test
        this.setHeightForProfileAndTest(testItem);
      }
    });

    /* update Package hSelf */
    reg.resultData.forEach(testItem => {
      if (Number(testItem.testItemType) === 1) {
        if (testItem.showItem === true) {
          testItem.hBigHeader = this.hTestBigHeaderRow; // set hBigHeader
          testItem.hDescription = this.getDescriptionHeight(testItem); // set hDescription
          testItem.hAction = this.hVerify + 16 + (
            (testItem.rejectReason == null || testItem.rejectReason.trim() === '') ? 0 : this.hReject
          ); // hAction (validate, verify, reject, my-2)
          testItem.hSelf = testItem.hBigHeader + testItem.hDescription + testItem.hAction; // set hSelf w/o result total height
          testItem.hResultTotal = 0; // declare
          for (let i = 0; i < testItem.inner.length; i++) {
            testItem.hResultTotal += testItem.inner[i].hTotal;
          }
          testItem.hTotal = testItem.hSelf + testItem.hResultTotal;
        } else {
          testItem.hBigHeader = 0; // set hBigHeader
          testItem.hDescription = 0; // set hDescription
          testItem.hAction = 0; // hAction (validate, verify, reject, my-2)
          testItem.hSelf = 0; // set hSelf w/o result total height
          testItem.hResultTotal = 0; // declare
          testItem.hTotal = 0;
        }
      }
    });

    // "EndOfReport", type:-1
    this.dataReport.resultData.push({
      testItemType: -1,
      hTotal: this.hEndOfReport
    });
  }

  setPageData(): void {
    this.newPage();
    for (let iTestItem = 0; iTestItem < this.dataReport.resultData.length; iTestItem++) {
      const testItem = this.dataReport.resultData[iTestItem];
      const isLastTestItem = (iTestItem === this.dataReport.resultData.length - 2);

      if (testItem.showItem === true) {
        // check page setting for testItem, show item at new page
        if (testItem.reportNewPage === true) {
          // don't add new page if is 1st testItem && page is empty
          if (iTestItem >= 1 && this.pageEmpty === false) {
            this.newPage();
          }
        }

        if (Number(testItem.testItemType) === 1) { // if is Package testItem
          if (testItem.hTotal < this.hBodyLeft) {
            testItem.page = this.setPage();

            testItem.inner.forEach((subTestItem, idx) => {
              this.setPageForProfileAndTest(subTestItem, (idx === testItem.inner.length - 1));
            });

            testItem.pageRemark = this.setPage();
            this.minusBodyHeight(testItem.hTotal - testItem.hResultTotal);
          } else {
            // new page, if package's header & 1st subTestItem's result in package not enough height to put in
            if (!(testItem.hBigHeader + testItem.inner[0].hHeader + testItem.inner[0].result[0].hSelf < this.hBodyLeft)) {
              if (!this.pageEmpty) {
                this.newPage();
              }
            }

            testItem.page = this.setPage();

            testItem.inner.forEach((subTestItem, idx) => {
              this.setPageForProfileAndTest(subTestItem, (idx === testItem.inner.length - 1));
            });

            // new page, if description & testItem action (validate, verify, reject, etc) not enough height to put in
            if (!(testItem.hDescription + testItem.hAction < this.hBodyLeft)) {
              this.newPage();
            }

            testItem.pageRemark = this.setPage();
            this.minusBodyHeight(testItem.hDescription + testItem.hAction);
          }
        } else if (testItem.testItemType > 1) { // if is Profile/Test testItem
          this.setPageForProfileAndTest(testItem, true);
        } else if (Number(testItem.testItemType) === -1) { // "EndOfReport", type:-1
          // new page, if "EndOfReport" not enough height to put in
          if (!(testItem.hTotal < this.hBodyLeft)) {
            this.newPage();
          }
          testItem.page = this.setPage();
        }

        // check page setting for testItem, next item show at new page
        if (testItem.reportSelfPage === true) {
          // don't add new page at the end if is last testItem && page is empty
          if (isLastTestItem === false && this.pageEmpty === false) {
            this.newPage();
          }
        }
      }
    }
  }

  // these function exist just for easier see by color when used
  minusBodyHeight(value: number): void {
    this.hBodyLeft -= value;
  }
  setPage(pageNum: number = this.pageTotal): number {
    this.pageEmpty = false;
    return pageNum;
  }
  newPage(): void {
    this.pageTotal++;
    this.pageArr.push(this.pageTotal);
    this.pageEmpty = true;
    // console.log(this.pageTotal-1, this.hBodyLeft); //#
    this.hBodyLeft = this.hPageBody - 21; // reset body height, -21 is <hr> after header
    // add title height if not page 1
    if (this.pageTotal > 1) {
      this.hBodyLeft += this.hPageHeaderTitle;
    }
  }

  setHeightForProfileAndTest(testItem, isPackage: boolean = false): void {
    if (testItem.show === true && testItem.showItem === true) {
      // set hHeader
      testItem.hHeader = this.hNormalRow;
      // set hDescription
      testItem.hDescription = this.getDescriptionHeight(testItem);
      // set hAction (validate, verify, reject)
      testItem.hAction = (isPackage === true) ? 0 : (this.hVerify + (testItem.rejectReason == null ? 0 : this.hReject));
      // result height total
      testItem.hResultTotal = 0;

      // single test result
      testItem.result.forEach((rs, idx) => {
        if (rs.showItem === false) {
          rs.hSelf = 0;
        } else if (rs.showCU === true || rs.showSI === true) {
          // set hSelf (single test result row height)
          rs.hSelf = (
            this.getResultHeight(rs) +
            this.getResultRemarkHeight(rs) +
            this.getRangeRemarkHeight(rs) +
            this.getRemarkHeight(rs) +
            this.getDescriptionHeight(rs)
          );

          // add mb height if is last item
          if (idx === testItem.result.length - 1) {
            rs.hSelf += this.hAfterResultGroup;
          }

          testItem.hResultTotal += rs.hSelf;
        } else {
          rs.hSelf = 0;
        }
      });

      // set hSelf w/o result height
      testItem.hSelf = testItem.hHeader + testItem.hDescription + testItem.hAction;
      // set all height total
      testItem.hTotal = testItem.hSelf + testItem.hResultTotal;
    } else {
      testItem.hHeader = 0;
      testItem.hDescription = 0;
      testItem.hAction = 0;
      testItem.hResultTotal = 0;
      testItem.hSelf = 0;
      testItem.hTotal = 0;
    }
  }

  setPageForProfileAndTest(testItem, isLastSubItem: boolean): void {
    if (testItem.showItem === true) {
      // check page setting for testItem, show item at new page
      if (testItem.reportNewPage === true) {
        // don't add new page if current page is empty
        if (this.pageEmpty === false) {
          this.newPage();
        }
      }

      /* if full testItem can put in current page */
      if (testItem.hTotal < this.hBodyLeft) {
        testItem.page = this.setPage();
        for (let iRs = 0; iRs < testItem.result.length; iRs++) {// loop result item
          const rs = testItem.result[iRs];

          if (rs.showItem === true) {
            // new page, if setting current item in new page
            if (rs.reportNewPage === true && this.pageEmpty === false) {
              this.newPage();
            }

            rs.page = this.setPage();

            // new page, if setting next item in new page
            if (rs.reportSelfPage === true && this.pageEmpty === false) {
              this.newPage();
            }
          }
        }
        this.minusBodyHeight(testItem.hTotal);
      } else {
        if (testItem.result.length <= 1) { // if testItem is test || profile only got 1 test inside
          if (!(testItem.hTotal < this.hBodyLeft)) {
            this.newPage();
          }
        } else if (!(testItem.hHeader + testItem.result[0].hSelf < this.hBodyLeft)) {
          this.newPage(); // new page, if package/test header & 1st result row not enough height to put in
        }

        testItem.page = this.setPage();

        for (let iRs = 0; iRs < testItem.result.length; iRs++) {// loop result item
          const rs = testItem.result[iRs];

          if (rs.showItem === true) {
            // new page, if setting current item in new page
            if (rs.reportNewPage === true && this.pageEmpty === false) {
              this.newPage();
            }

            // new page, if result row not enough height to put in
            if (!(rs.hSelf < this.hBodyLeft)) {
              this.newPage();
            }

            rs.page = this.setPage();
            this.minusBodyHeight(rs.hSelf);

            // new page, if setting next item in new page
            if (rs.reportSelfPage === true && this.pageEmpty === false) {
              this.newPage();
            }
          }
        }

        // new page, if description & testItem action (validate, verify, reject, etc) not enough height to put in
        if (!(testItem.hDescription + testItem.hAction < this.hBodyLeft)) {
          this.newPage();
        }

        testItem.pageRemark = this.setPage();
        this.minusBodyHeight(testItem.hDescription + testItem.hAction);
      }

      testItem.pageRemark = this.setPage();

      // check page setting for testItem, next item show at new page
      if (testItem.reportSelfPage === true) {
        // don't add new page if is last item in package/profile && current page is empty
        if (isLastSubItem === false && this.pageEmpty === false) {
          this.newPage();
        }
      }
    }
  }

  getRangeRemarkHeight(item, len: number = 0): number {
    const hCU = (!item.rangeRemarkCU ? 0 : item.rangeRemarkCU.length / this.maxStringLenInLineRemark);
    const hSI = (!item.rangeRemarkSI ? 0 : item.rangeRemarkSI.length / this.maxStringLenInLineRemark);
    len += (hCU >= hSI) ? Math.ceil(hCU) : Math.ceil(hSI); // get the largest value
    return len * this.hNormalRow;
  }

  // ref. range remark
  getRemarkHeight(item, len: number = 0): number {
    // height for CU remark
    const hCU = (
      (item.remarkRangeCU && item.remarkRangeCU.toString().trim() !== '') ?
      item.remarkRangeCU.length / this.maxStringLenInLineRemark : 0
    );
    // heigth for SI remark
    const hSI = (
      (item.remarkRangeSI && item.remarkRangeSI.toString().trim() !== '') ?
      item.remarkRangeSI.length / this.maxStringLenInLineRemark : 0
    );
    len += (hCU >= hSI) ? Math.ceil(hCU) : Math.ceil(hSI); // get the largest value
    return len * this.hNormalRow;
  }

  getResultHeight(item): number {
    if (item != null && item.resultType == 1) {
      const tempRsCU = item.resultCU == null ? '' : item.resultCU.toString();
      const matchCU = tempRsCU.match(/>(.*?)</);
      const rsCU = matchCU ? matchCU[1] : tempRsCU;
      const lineTotalCU = Math.ceil(rsCU.length / this.maxStringLenInResultValue);

      const tempRsSI = item.resultSI == null ? '' : item.resultSI.toString();
      const matchSI = tempRsSI.match(/>(.*?)</);
      const rsSI = matchSI ? matchSI[1] : tempRsSI;
      const lineTotalSI = Math.ceil(rsSI.length / this.maxStringLenInResultValue);
      
      return (lineTotalCU > lineTotalSI ? lineTotalCU : lineTotalSI) * this.hNormalRow;
    }
    return this.hNormalRow;
  }

  // remark input by user in test-order update page
  getResultRemarkHeight(item, len: number = 0): number {
    len += (
      (item.resultRemark && item.resultRemark.toString().trim() !== '') ?
      Math.ceil(item.resultRemark.length / (this.maxStringLenInLineRemark * 2)) : 0
    );
    return len * this.hNormalRow;
  }

  getDescriptionHeight(item, len: number = 0): number {
    if (item.description) {// if got text
      const tempDescription: any = this.filterDescriptionData(item.description).split('<div>');
      len += tempDescription.length; // got next line

      // -1 cuz 1st array element must be "" (mean empty);
      if (len > 0) {
        len--;
      }

      let textWarpHeightTotal = 0;
      tempDescription.forEach(ele => {
        // add space for <br>, if needed
        if (ele.split('<br>').length > 2) {
          len++;
        }

        /* filter description for text length calculation later */
        ele = ele.replace(/<[^>]*>/g, ''); // remove html element tag
        ele = ele.replace(/&nbsp;/g, ' '); // remove "&" symbol (&nbsp;)

        // check text warp
        const textWarpHeight: number = Math.floor(ele.toString().length / this.maxStringLenInLine);
        textWarpHeightTotal += textWarpHeight;
        len += textWarpHeight;
      });
      if (textWarpHeightTotal > 1) {
        len--;
      }

      // +1.5 for padding
      if (len > 0) {
        len += 1.5;
      }
    }
    return len * this.hRowDescription;
  }

  filterDescriptionData(description): any {
    // empty
    if (
      description == null ||
      description == undefined ||
      description.trim() === '<br>' ||
      description.trim() === '<div><br></div>' ||
      description === '<p class="MsoNormal" style="margin-bottom: 0cm; text-align: justify; line-height: 19.5px;"><br></p>'
    ) {
      description = '';
    }

    // modify
    description = description.replace(/<span style="font-weight: 600;">/g, '<span class="font-weight-bold">'); // bold
    description = description.replace(/<p[^>]*>/g, '<div>'); // <p> -> <div>
    description = description.replace(/<\/p[^>]*>/g, '</div>'); // </p> -> </div>

    // add css
    description = description.replace(/<b>/g, '<b class="font-weight-bold">'); // <b>
    description = description.replace(/<i>/g, '<i style="font-style:italic">'); // <i>
    description = description.replace(
      /<u>/g, '<u style="text-decoration: none; border-bottom: 1px solid #000; padding-bottom: 2px;">'
    ); // <u>
    // console.log(description); //#
    return description;
  }

  filterSymbol(data): any {
    data = JSON.stringify(data);
    data = data.replace(/≥/g, '<span class=\'jspdf_symbol\'>≥</span>');
    data = data.replace(/≤/g, '<span class=\'jspdf_symbol\'>≤</span>');

    // replace chinese symbol to english symbol
    data = data.replace(/：/g, ':'); // :
    data = data.replace(/；/g, ';'); // ;
    data = data.replace(/，/g, ','); // ,
    data = data.replace(/！/g, '!'); // !
    data = data.replace(/？/g, '?'); // ？

    return JSON.parse(data);
  }

  // removeStarSymbolForResult(dataReport): any {
  //   for (let iRsData = 0; iRsData < dataReport.resultData.length; iRsData++) {
  //     const rsData = dataReport.resultData[iRsData];
  //     if (rsData.testItemType != -1) {
  //       for (let iInner = 0; iInner < rsData.inner.length; iInner++) {
  //         const inner = rsData.inner[iInner];
  //         for (let iRs = 0; iRs < inner.result.length; iRs++) {
  //           const rs = inner.result[iRs];
  //           try {
  //             if (rs.resultCU) {
  //               if (rs.resultCU.toString().includes('text-primary') || rs.resultCU.toString().includes('text-danger')) {
  //                 rs.resultCU = rs.resultCU.toString().replace(/\*/g, '');
  //               }
  //             }
  //             if (rs.resultSI) {
  //               if (rs.resultSI.toString().includes('text-primary') || rs.resultSI.toString().includes('text-danger')) {
  //                 rs.resultSI = rs.resultSI.toString().replace(/\*/g, '');
  //               }
  //             }
  //           } catch (error) {
  //             console.log('*1');
  //           }
  //         }
  //       }

  //       for (let iRs = 0; iRs < rsData.result.length; iRs++) {
  //         const rs = rsData.result[iRs];
  //         try {
  //           if (rs.resultCU) {
  //             if (rs.resultCU.toString().includes('text-primary') || rs.resultCU.toString().includes('text-danger')) {
  //               rs.resultCU = rs.resultCU.toString().replace(/\*/g, '');
  //             }
  //           }
  //           if (rs.resultSI) {
  //             if (rs.resultSI.toString().includes('text-primary') || rs.resultSI.toString().includes('text-danger')) {
  //               rs.resultSI = rs.resultSI.toString().replace(/\*/g, '');
  //             }
  //           }
  //         } catch (error) {
  //           console.log('*2');
  //         }
  //       }
  //     }
  //   }
  //   return dataReport;
  // }

}
