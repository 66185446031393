import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { AuthComponent } from './theme/layout/auth/auth.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

/* Menu Items */
import { NgbButtonsModule, NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationItem } from './theme/layout/admin/navigation/navigation';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

/* Printing */
import { NgxElectronModule } from 'ngx-electron';
import { SatransferComponent } from 'src/app/template/satransfer/satransfer.component';
import { ConsentComponent } from './template/consent/consent.component';
import { InvoiceComponent } from './template/invoice/invoice.component';
import { ReceiptComponent } from './template/receipt/receipt.component';
import { ReportComponent } from './template/report/report.component';
import { SoaComponent } from './template/soa/soa.component';
import { WorksheetComponent } from './template/worksheet/worksheet.component';

/* Guard */
import { AccessLevelGuard } from './guard/access-level.guard';
import { AuthGuard } from './guard/auth-guard.guard';
import { GuestGuard } from './guard/guest-guard.guard';

// QR Code
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        AuthComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        ConfigurationComponent,
        ToggleFullScreenDirective,
        SatransferComponent,
        WorksheetComponent,
        ReportComponent,
        ConsentComponent,
        InvoiceComponent,
        ReceiptComponent,
        SoaComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxElectronModule,
        SharedModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbButtonsModule,
        NgbNavModule,
        HttpClientModule,
        QRCodeModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),
    ],
    providers: [
        NavigationItem,
        AccessLevelGuard, AuthGuard, GuestGuard,
        DatePipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
