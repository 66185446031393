<div class="mt-5">   <!--  d-none -->
    <div #pdfData class="a4 mx-auto">
		<div *ngFor="let i of receiptSecondaryData, index as idx" style="height: 302px;" #pdfData class="a4 mx-auto bg-white">   <!-- OPT: height: 380px; / 265px, 285 -->
			<div class="header mt-1">
				<div class="row" *ngIf="idx === 0">
					<div class="col-12 text-center">
						<div class="title" style="margin-bottom: 3px; letter-spacing: 0.2px;">
							<h3 style="display: inline-block; margin-right: 5px">{{ dataPrintReceipt.institutionName }} - {{ dataPrintReceipt.institutionBranch }}</h3><span>(202101002796 (1403094-A))</span>   <!-- OPT: h2 -->
							<div>
								<p [innerHTML]="dataPrintReceipt.institutionAddress"></p>
								<p>Tel: {{ dataPrintReceipt.institutionPhone }}</p>
							</div>
							<p>{{ dataPrintReceipt.institutionEmail }}</p>
						</div>
					</div>	
				</div>
				<div class="row mt-1">
					<div class="col-11 mx-auto">
						<div class="header">
							<div class="row m-0">
								<div class="col-7 mt-1">
									<p class="font-weight-bold">Customer Details : </p>
									<div class="address">
										<p>{{ dataPrintReceipt?.name }}</p>
										<p>{{ dataPrintReceipt?.address }}</p>
										<p>TEL: {{ dataPrintReceipt?.contact ? dataPrintReceipt?.contact : 'N/A' }}</p>
									</div>
								</div>
								<div class="col-5 mt-1 ms-0">
									<div class="row m-0">
										<div class="col-6"></div>
										<div class="col-6">
											<h3>RECEIPT</h3>
										</div>
									</div>
									<div class="row m-0 font-weight-bold">
										<div class="col-6 px-0 text-right">
											<p>No.</p>
										</div>
										<div class="col-1 px-0 text-center">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintReceipt?.no }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-6 px-0 text-right">
											<p>Date</p>
										</div>
										<div class="col-1 px-0 text-center">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintReceipt?.collectDate ? dataPrintReceipt?.collectDate : '-' }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-6 px-0 text-right">
											<p>Page</p>
										</div>
										<div class="col-1 px-0 text-center">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{idx+1}} of {{receiptSecondaryData.length}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="body">
				<div class="row">
					<div class="col-11 mx-auto">
						<table class="pdf-table">
							<tr *ngIf="idx === 0">
								<ng-container *ngIf="this.dataPrintReceipt.description.length > 0">   <!-- PATH: receipt -->
									<th>Item</th>
									<th width="40%">Description</th>
									<th>Qty</th>
									<th>UOM</th>
									<th class="text-right">U/Price <br>RM</th>
									<th>Disc. <br>RM</th>
									<th class="text-right pr-2">Total <br>RM</th>
								</ng-container>
								<ng-container *ngIf="this.dataPrintReceipt.docNo.length > 0">   <!-- PATH: invoice -> receipt -->
									<th>Doc Date</th>
									<th width="40%">Doc No.</th>
									<th>Org. Amt <br>RM</th>
									<th>Outstanding</th>
									<th class="text-right">Paid Amount <br>RM</th>
								</ng-container>
							</tr>
							
							<ng-container *ngIf="this.dataPrintReceipt.description.length > 0">   <!-- PATH: receipt -->
								<tr *ngFor="let description of dataPrintReceipt.description[idx], index as j">
									<td class="text-center">
										<ng-container *ngIf="dataPrintReceipt.qty[idx][j]">
											{{ getNo(dataPrintReceipt.description, idx, j) + "." }}
										</ng-container>
									</td>
									<td width="40%" style="letter-spacing: 0.50px;" class="text-left">{{ dataPrintReceipt.description[idx][j] }}</td>
									<td class="text-center">{{ dataPrintReceipt.qty[idx][j] }}</td>
									<td class="text-center">{{ dataPrintReceipt.uom[idx][j] }}</td>
									<td class="text-right">{{ dataPrintReceipt.price[idx][j]?.toFixed(2) }}</td>
									<td class="text-center">
										<ng-container *ngIf="dataPrintReceipt.qty[idx][j]">
											{{ dataPrintReceipt.singleMergedDiscInRM[idx][j] ? dataPrintReceipt.singleMergedDiscInRM[idx][j].toFixed(2) : "-" }}
										</ng-container>
									</td>
									<td class="text-right pr-2">{{ dataPrintReceipt.subtotalPrice[idx][j]?.toFixed(2) }}</td>
								</tr>
							</ng-container>
							<ng-container *ngIf="this.dataPrintReceipt.docNo.length > 0">   <!-- PATH: invoice -> receipt -->
								<tr *ngFor="let docNo of dataPrintReceipt.docNo[idx], index as j">
									<td class="text-center">
										<ng-container *ngIf="dataPrintReceipt.orgAmt[idx][j]?.toFixed(2)">
											{{ dataPrintReceipt.docDate[idx][j] }}
										</ng-container>
									</td>
									<td width="40%" style="letter-spacing: 0.50px;" class="text-left">{{ dataPrintReceipt.docNo[idx][j] }}</td>
									<td class="text-center">{{ dataPrintReceipt.orgAmt[idx][j]?.toFixed(2) }}</td>
									<td class="text-center">{{ dataPrintReceipt.outstanding[idx][j] }}</td>
									<td class="text-right">{{ dataPrintReceipt.paidAmount[idx][j]?.toFixed(2) }}</td>
								</tr>
							</ng-container>

							<tr *ngIf="((receiptSecondaryData[receiptSecondaryData.length-1] !== null 
											&& idx === receiptSecondaryData.length-1) 
										|| (receiptSecondaryData[receiptSecondaryData.length-1] === null 
											&& idx === receiptSecondaryData.length-1-1))">
								<td></td>
								<td> Paid via {{ dataPrintReceipt.paymentModeForBilling }}. </td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<div class="footer" *ngIf="idx === receiptSecondaryData.length-1">
				<div class="row">
					<div class="col-11 mx-auto">
						<div class="total-amount">
							<div class="row">
								<div class="col-7">
									<p style="margin-bottom: 2px; text-transform: uppercase; letter-spacing: 0.1px">{{ dataPrintReceipt?.totalPriceWording }}</p>
									<div class="row notes">
										<div class="col-10">
											<p>Goods sold are neither returnable or refundable. Otherwise a cancellation fee of 20% on purchase price will be imposed.</p>
										</div>
									</div>
								</div>
								<div class="col-5">
									<table class="w-100">
										<!-- TODO: chk if no need or where to put for not-first page -->
										<!-- <tr>
											<td class="col-7 text-right">B/F Pages Total</td>
											<td class="col-5 text-right pr-1">0.00</td>
										</tr>
										<tr>
											<td class="col-7 text-right">Page Total</td>
											<td class="col-5 text-right pr-1">{{ dataPrintReceipt.totalPrice }}</td>
										</tr> -->   <!-- OPT: totalPrice?.toFixed(2) -->
										<tr class="font-weight-bold">
											<td class="col-7 text-right">Total</td>
											<td class="col-5 text-right border border-dark pr-1">{{ dataPrintReceipt.totalPrice?.toFixed(2) }}</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-11 mx-auto">
						<div class="row">
							<div class="col-5">
								<div class="signature">
								</div>
								<div class="text-center font-weight-bold">Authorised Signature</div>
							</div>
							<div class="col-3"></div>
							<div class="col-4 text-center" style="align-self: center;">
								<h3>E.& O.E.</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>