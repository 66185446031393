import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/auth.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-nav-right',
    templateUrl: './nav-right.component.html',
    styleUrls: ['./nav-right.component.scss'],
    providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
    username: any;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        // fix console err: ERROR TypeError: Cannot read properties of null (reading 'name')
        this.username = JSON.parse(localStorage.getItem('userDetails'))?.name;
    }

    logout(): void {
        Swal.fire({
            icon: 'warning',
            title: 'Log Out?',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.authService.logout().then(() => {
                    localStorage.clear();
                    console.log('logout');
                    window.location.href = '/login';
                });
            }
        });
    }
}
