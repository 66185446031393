<div class="d-none">
    <div #pdfData class="a4">
        <ng-container *ngFor="let pageCurrent of pageArray">
            <!-- Header -->
            <div class="header">
                <div class="row">
                    <div class="col-9 text-center">
                        <div class="title">
                            <h2>Medilabz Sdn. Bhd.</h2>
                            <h3>Sample Transfer Form</h3>
                        </div>
                    </div>  
                    <div class="col-3 text-right">
                        <div class="header-right">
                            <p>F/ML/20</p>
                            <p>Rev. No. 00</p>
                            <p class="my-1">Date: {{ data[0]?.datetime | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
                            <p class="mb-3">Batch: {{ data[0]?.batch }}</p>
                        </div>
                    </div>  
                </div>
            </div>
            <!-- Body -->
            <div class="body" [ngStyle]="{'height': pageCurrent == pageTotal ? '239px' : '240px' }">
                <table class="pdf-table">
                    <tr>
                        <th>No</th>
                        <th>Date Collected</th>
                        <th>Name</th>
                        <th>IC/Passport</th>
                        <th>Lab Number</th>
                        <th>Tests</th>
                        <th>Source</th>
                        <th>Collected</th>
                        <th>Received</th>
                    </tr>
                    <ng-container *ngFor="let item of data, index as i">
                        <tr #dataRow *ngIf="item?.page === pageCurrent">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item?.sampleCollectDateTime }}</td>
                            <td>{{ item?.name | uppercase }}</td>
                            <td>{{ item?.icPassport }}</td>
                            <td>{{ item?.lab }}</td>
                            <td>{{ item?.testItemName }}</td>
                            <td style="max-width: 121.92px">{{ item?.source }}</td>
                            <td>{{ item?.qty }}</td>
                            <td></td>
                        </tr>
                    </ng-container>
                </table>
                <div class="table-total">
                    <div class="row">
                        <div class="col-12 text-right">
                            <div class="total">
                                <p>Total Sent <span class="ml-1">{{ tubeTotalText }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer -->
            <div class="footer">
                <div class="row">
                    <div class="col-8">
                        <div class="sample ml-4">
                            <p>Sample Prepared By: </p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="sample">
                            <p>Sample Received By: </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <div class="test-detail ml-4">
                            <p>Staff Name: </p>
                            <p>Institution: </p>
                            <p>Date: </p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="test-detail">
                            <p>Staff Name: </p>
                            <p>Institution: </p>
                            <p>Date: </p>
                        </div>
                    </div>
                    <div class="col-12 text-right" style="margin-top: 15px">
                        <p style="font-size: 7.5px; margin-bottom: 0;">
                            Page {{ pageCurrent }} of {{ pageTotal }}
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>