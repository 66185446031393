<div class="mt-5">   <!--  d-none -->
	<div #pdfData class="a4 mx-auto">
		<div *ngFor="let i of dataPrintSoa.date, index as idx" style="height: 609px" class=" bg-white">
			<div class="header">
				<div class="row" *ngIf="idx === 0">
					<div class="col-12 text-center">
						<div class="title" style="margin-bottom: 3px; letter-spacing: 0.2px;">
							<h2 style="display: inline-block; margin-right: 5px">{{ dataPrintSoa.institutionName }} - {{ dataPrintSoa.institutionBranch }}</h2><span>(202101002796 (1403094-A))</span>
							<div>
								<p [innerHTML]="dataPrintSoa.institutionAddress"></p>
								<p>Tel: {{ dataPrintSoa.institutionPhone }}</p>
							</div>
							<p>{{ dataPrintSoa.institutionEmail }}</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-11 mx-auto">
						<div>
							<div class="row m-0">
								<div class="col-12 px-0 text-center">
									<h2 style="margin-top: 5px; margin-bottom: 5px; letter-spacing: 0.55px">DEBTOR STATEMENT</h2>
								</div>
							</div>
							<div class="row m-0">
								<div class="col-8 pl-0">
									<div class="row m-0 mt-2">
										<div class="col-2 px-0 text-right">
											<p>A/C No.</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintSoa.accNo ? dataPrintSoa.accNo : '-' }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-2 px-0 text-right">
											<p>Debtor</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintSoa.debtor ? dataPrintSoa.debtor : '-' }}</p>
										</div>
									</div>
									<div class="row m-0">
										<div class="col-2 px-0 text-right">
											<p>Address</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p [innerHTML]="dataPrintSoa.address ? dataPrintSoa.address : '-'"></p>
										</div>
									</div>
								</div>
								<div class="col-4 pr-0">
									<div class="row m-0 mt-2">
										<div class="col-6 px-0 text-right">
											<p>Statement Date</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintSoa.statementDate ? dataPrintSoa.statementDate : '-' }}</p>
										</div>
									</div>
									<div class="row m-0 mt-2">
										<div class="col-6 px-0 text-right">
											<p>Terms</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{ dataPrintSoa.terms ? dataPrintSoa.terms : '-' }}</p>
										</div>
									</div>
									<div class="row m-0 mt-2">
										<div class="col-6 px-0 text-right">
											<p>Page</p>
										</div>
										<div class="col-1 px-0">
											:
										</div>
										<div class="col-5 px-0">
											<p>{{idx+1}} of {{dataPrintSoa.date.length}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="body">
				<div class="row">
					<div class="col-11 mx-auto">
						<table class="pdf-table">
							<!-- default: class: text-center // text aligment: center -->
							<tr *ngIf="idx === 0">
								<th class="text-left pl-1">DATE</th>
								<th>REF.</th>
								<th width="40%" class="text-left pl-2">DESCRIPTION</th>
								<th>DR</th>
								<th>CR</th>
								<th class="text-right pr-1">BALANCE</th>
							</tr>
							<!-- LATER: space between up & down -->
							<!-- OPT: py-0 -->
							<!-- OPT: mt-0 mb-0 pt-0 pb-0 -->
							<!-- default: class: text-left // text aligment: left -->
							<!-- {{ dataPrintSoa.date[idx] }} -->
							<tr *ngFor="let item of dataPrintSoa.date[idx], index as j" style="letter-spacing: 0.45px;">
								<td class="pl-0">{{ dataPrintSoa.date[idx][j] }}</td>
								<!-- <td>IN &nbsp;&nbsp;&nbsp; {{ dataPrintSoa.ref[idx][j] }}</td> -->
								<td [innerHtml]="dataPrintSoa.ref[idx][j]"></td>
								<td class="pl-2" width="40%" style="line-height: 7px !important;">{{ dataPrintSoa.description[idx][j] }}</td>
								<td class="text-center">{{ dataPrintSoa.dr[idx][j] }}</td>
								<td class="text-center">{{ dataPrintSoa.cr[idx][j] ? dataPrintSoa.cr[idx][j] : "" }}</td>
								<td class="text-right">{{ dataPrintSoa.balance[idx][j].toFixed(2) }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<div *ngIf="idx === dataPrintSoa.date.length-1" class="footer">
				<div class="row">
					<div class="col-11 mx-auto">
						<div class="total-amount">
							<div class="div1" style="float: left;">
								<p style="text-transform: uppercase; letter-spacing: 0.1px">{{ dataPrintSoa.totalPriceWording }}</p>
							</div>
							<div class="div3" style="float: right">
								<p>RM &nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ dataPrintSoa.totalPrice?.toFixed(2) }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-11 mx-auto">
						<table class="pdf-table text-right" id="full-border">   <!-- due table -->
							<tr>
								<th>CURRENT</th>
								<th>1 MONTH</th>
								<th>2 MONTHS</th>
								<th>3 MONTHS</th>
								<th>4 MONTHS</th>
								<th>5 MONTHS</th>
							</tr>
							<tr>
								<ng-container *ngFor="let i of [0,1,2,3,4,5]">   <!-- new Array(6) -->
									<td>{{ dataPrintSoa.dueAmount[i] ? dataPrintSoa.dueAmount[i].toFixed(2) : "0.00"}}</td>
								</ng-container>
							</tr>
							<tr>
								<th>6 MONTHS</th>
								<th>7 MONTHS</th>
								<th>8 MONTHS</th>
								<th>9 MONTHS</th>
								<th>10 MONTHS</th>
								<th>11 & OVER</th>
							</tr>
							<tr>
								<ng-container *ngFor="let i of [6,7,8,9,10,11]">
									<td>{{ dataPrintSoa.dueAmount[i] ? dataPrintSoa.dueAmount[i].toFixed(2) : (0.00).toFixed(2)}}</td>
								</ng-container>
							</tr>
						</table>
			
						<br>
						<div class="notes">
							<p>We shall be grateful if you will let us have payment as soon as posible. Any discrepancy in this statement must be reported to us in writing within 10 days.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>