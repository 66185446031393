import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements AfterViewInit, OnInit {

  @ViewChild('pdfData') htmlData;
  data: any;

  constructor() {}

  ngOnInit(): void{
    this.data = JSON.parse(sessionStorage.getItem('consent_temp'));
    // preprare testItemList
    this.data.testItemNameList = '';
    this.data.testItemName.forEach(item => {
      if (this.data.testItemNameList !== '') {
        this.data.testItemNameList += ', ';
      }
      this.data.testItemNameList += item;
    });
    // console.log('data: ', this.data);
  }

  ngAfterViewInit(): void {
    this.downloadPDF();
  }

  downloadPDF(): void {
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('p', 'px', 'a4');

    doc.html(DATA, {
      margin: 10,
      // tslint:disable-next-line:no-shadowed-variable
      callback: (doc) => {
        doc.save('consent-form.pdf');
        Swal.fire('Print Successfully', 'Consent Form Printed', 'success').then(() => {
          window.close();
        });
      }
    });
  }
}
