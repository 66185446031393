import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerType } from './customer-type';

@Injectable({
  providedIn: 'root'
})
export class CustomerTypeService {

  private url = environment.apiURL + 'customeresTypesController';

  constructor(private http: HttpClient) { }

  getAllCustomerType(): Observable<CustomerType[]>{
    return this.http.get<CustomerType[]>(this.url);
  }

  getCustomerType(id: number): Observable<CustomerType>{
    return this.http.get<CustomerType>(this.url + '/' + id);
  }

  createCustomerType(customerType: CustomerType): Observable<object>{
    return this.http.post(this.url + '/create', customerType);
  }

  updateCustomerType(customerType: CustomerType): Observable<any>{
    return this.http.patch(this.url + '/update', customerType);
  }

  deleteCustomerType(id: number): Observable<object>{
    return this.http.delete(this.url + '/delete/' + id);
  }
}
