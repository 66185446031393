import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportCustomPageBreakService } from 'src/app/service/filter/report-custom-page-break.service';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  @ViewChild('pdfData') htmlData;
  dataPrintReceipt: any;
  receiptSecondaryData: any;

  // DEBUGGING PURPOSE
  debugging = false;   // OPT: true

  constructor(private reportCustomPageBreakService: ReportCustomPageBreakService) {}

  ngAfterViewInit(): void {
    this.downloadPDF();
  }

  ngOnInit(): void {
    this.dataPrintReceipt = JSON.parse(sessionStorage.getItem('receipt_temp'));

    // DEBUGGING PURPOSE
    if (this.debugging) {
      // dummy data
      this.dataPrintReceipt = {
        id: null,
        institutionName: 'MLKB',
        institutionBranch: 'MediLabz (Kota Bharu)',
        institutionAddress: 'PT2507, Ground Floor, Taman Kenangan, Jalan Hospital, 15200, Kota Bharu, Kelantan',
        institutionPhone: '09-7470683',
        institutionEmail: 'medilabzkb@medilabz.com.my',
        no: 'MLKB-R-A000049',
        name: 'Customer Name',
        contact: 'Contact Number',
        collectDate: '06/01/2023',
        description: [
          'Billing 4 ([package] BASIC HEALTH SCREENING PACKAGE)',
          'Billing 4 ([package] BASIC HEALTH SCREENING PACKAGE)'
        ],
        qty: [
          2,
          2
        ],
        uom: [
          'Set',
          'Set'
        ],
        price: [
          81,
          81
        ],
        discSingleInPercent: [
          4,
          4
        ],
        discSingleInRM: [
          1,
          2
        ],
        singleMergedDiscInRM: [
          4.24,
          5.24
        ],
        discEarlyPayment: null,
        discAllTests: 1,
        discNonBilling: null,
        subtotalPrice: [
          153.52,
          151.52
        ],
        docDate: [],
        docNo: [],
        orgAmt: [],
        outstanding: [],
        paidAmount: [],
        totalPrice: 301.99,
        totalPriceWording: 'RINGGIT MALAYSIA THREE HUNDRED ONE AND CENTS NINETY-NINE ONLY',
        paymentModeForBilling: 'Cash'
      };

      // format receipt's custom page break
        // 1 line can have 40 characters
        // put the primary (/important) col (eg. description / docNo) in font of the rest parameter
      this.dataPrintReceipt = (this.dataPrintReceipt.description.length > 0) ?
        this.reportCustomPageBreakService.formatData4Pages(this.dataPrintReceipt,
          20, 30, 7, 12, 40, null,   // OPT: 20+1, 17+1; -2; -2; -2, 15-4; 55-2, 52; "rec" //42, 51-2, 26-2, 32-2, 60,; 42, 50, 26, 35-2, 50 / 22-2, 36-2, 13-2, 27-2,  //minus 2 lines (1 payment mode + 1 discEarlyPayment)
          'description', 'qty', 'uom', 'price', 'singleMergedDiscInRM', 'subtotalPrice'
        ) :
        this.reportCustomPageBreakService.formatData4Pages(this.dataPrintReceipt,
          20, 30, 7, 12, 40, null,
          'docNo', 'docDate', 'orgAmt', 'outstanding', 'paidAmount'
        );
    }
    console.log('selectedData: ', this.dataPrintReceipt);

    // get receipt secondary col data
    this.receiptSecondaryData = this.dataPrintReceipt.qty.length > 0 ? this.dataPrintReceipt.qty : this.dataPrintReceipt.docDate;
    console.log('this.receiptSecondaryData = ', this.receiptSecondaryData);

    /* this.totalPrice = 0;   // TOOD: delete it if useless
    this.dataPrintReceipt.price.forEach(request => this.totalPrice += Number(request));
    this.totalPriceWording = converter.toWords(this.totalPrice); */
  }

  downloadPDF(): void {
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('l', 'px', 'a5');

    doc.html(DATA, {
      margin: 3,   // OPT: 10
      // tslint:disable-next-line:no-shadowed-variable
      callback: (doc) => {
        doc.save('official-receipt (' + this.dataPrintReceipt.name + ').pdf');
        Swal.fire('Print Successfully', 'Receipt Printed', 'success').then(() => {
          window.close();
        });
      }
    });
  }

  // get no for invoice table
  getNo(arrayCol, currIdx, currNo): any {
    let prevAccumulatedNo = 0;

    // if currId is more than 1 (next page & so on), then we need to count the prev arr (page) item as well
    for (let i = 0; i < currIdx; i++) {
      prevAccumulatedNo += arrayCol[i].length;
    }

    return prevAccumulatedNo + currNo + 1;   // start from 1 instead of 0
  }
}
