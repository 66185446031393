import { Injectable } from '@angular/core';
import {
  Auth, authState,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword, signOut,
  updatePassword
} from '@angular/fire/auth';
import { Firestore, doc, getDoc, onSnapshot, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import Swal from 'sweetalert2';
import { UserDetailsService } from '../service/api/UserDetails/user-details.service';
import { UsersRecordService } from '../service/api/UsersRecord/users-record.service';
import { LocalService } from '../service/local/local.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  fieldName = 'users';
  collectionName = 'users';
  user: any;
  dbUser: any;
  userObs: ReplaySubject<any>;

  constructor(
    private afAuth: Auth,
    private afs: Firestore,
    private router: Router,
    private userDetailsService: UserDetailsService,
    private usersRecordService: UsersRecordService,
    private localService: LocalService
  ) {
    this.userObs = new ReplaySubject(1);

    authState(this.afAuth).subscribe(user => {
      this.user = user;
      if (user) {// User is exist
        // console.log(user)
        if (user.email) {
          onSnapshot(this.getMyDocRef(user.uid), (docRef: any) => {
            const dbUser = docRef.data();
            if (dbUser) {
              this.dbUser = dbUser;
              this.userObs.next(dbUser);
              localStorage.setItem('user', JSON.stringify(user));
            } else {
              this.dbUser = null;
              this.userObs.next(null);
              // observer.error('You are not ' + this.collectionName + '.');
            }
          });
        } else {
          this.dbUser = null;
          this.userObs.next(null);
        }
      } else { // User too long no activity, reset token
        this.dbUser = null;
        this.userObs.next(null);
        // observer.error('Your token is expired, please relog.');
      }
    });

    this.userObs.subscribe();
  }

  getMyDocRef(key: any) {
    return doc(this.afs, this.collectionName + '/' + key);
  }

  get isLoggedIn(): boolean {
    return this.dbUser !== null;
  }

  login(email: any, password: any) {
    return signInWithEmailAndPassword(this.afAuth, email, password).then((res) => {
      if (res.user) {
        return getDoc(doc(this.afs, 'users/' + res.user.uid)).then((docRef) => {
          // fix for "userDetails" local data not stored after login
          // get & set user details data
          this.userDetailsService.getUserDetailsByEmail(email).subscribe({
            next: httpData => {
              if (httpData){
                const userDetail: any = httpData;
                if (!userDetail.institutionViewList) {
                  userDetail.institutionViewList = []; // prevent null
                }
                if (userDetail.institutionViewList.length === 0) {
                  userDetail.institutionViewList.push(userDetail.institutionId); // temp filter for old data
                }
                this.localService.saveData('userDetails', JSON.stringify(userDetail));

                /// === PostgreSQL ===
                // update user status to online START
                this.usersRecordService.toggleUserStatusByStatus(userDetail.id, 2).subscribe({   // status 2: online
                  next: httpData => {
                    console.log('online login');
                    // console.log(httpData);
                  },
                  error: err => {
                    console.log('Failed to set user status to "online"', err);
                    Swal.fire('Update Data Failed', 'Data: user status', 'error');
                  }
                });
                // update user status to online END
                /// === PostgreSQL ===
              }
            }, error: err => {
              console.log(err);
              Swal.fire('Get Data Failed', 'Data: Login User', 'error');
            }
          });

          const dbUser = docRef.data();
          localStorage.setItem('name', dbUser?.name);
          this.userObs.next(dbUser);
        });
      }
    });
  }

  logout() {
    /// === PostgreSQL ===
    // update user status to offline START
    const dataUserDetails = JSON.parse(this.localService.getData('userDetails'));
    if (dataUserDetails?.id !== undefined && dataUserDetails?.id > 0) {
      this.usersRecordService.toggleUserStatusByStatus(dataUserDetails.id, 1).subscribe({ // status 1: offline
        next: httpData => {
          console.log(httpData);
        },
        error: err => {
          console.log('Failed to set user status to "offline"', err);
          Swal.fire('Update Data Failed', 'Data: user status', 'error');
        }
      });
    } else {
      console.log('Warning: dataUserDetails?.id is ' + dataUserDetails?.id);
    }
    // update user status to offline END
    /// === PostgreSQL ===

    localStorage.clear();
    sessionStorage.clear();
    return signOut(this.afAuth);
  }

  signUp(dbUser: any) {
    const promise = new Promise((resolve, reject) => {
      return createUserWithEmailAndPassword(this.afAuth, dbUser.email, dbUser.pwd).then((res) => {
        if (res.user) {
          // delete dbUser.pwd;
          // delete dbUser.confirmPwd;
          delete dbUser.departmentsId;
          return setDoc(doc(this.afs, 'users/' + res.user.uid), dbUser)
            .then(() => {
              this.userObs.next(dbUser);
              resolve(dbUser);
            }, err => {
              reject(this.parse(err.code));
            });
        } else {
          reject('User missing');
        }
      }, err => {
        reject(this.parse(err.code));
      });
    });

    return promise;
  }

  changePassword(password) {
    return updatePassword(this.user, password);
  }

  // forgot password
  resetPassword(email: string) {
    const promise = new Promise((resolve, reject) => {
      return sendPasswordResetEmail(this.afAuth, email).then(() => {
        this.localService.saveData('email', email); // to be used in confirm password
        this.router.navigate(['/verify-email']);
      }, err => {
        reject(this.parse(err.code));
      });
    });

    return promise;
  }

  // email varification
  sendEmailForVerification(user) { // OPT: verifyEmail
    const promise = new Promise((resolve, reject) => {
      return user.sendEmailForVerification().then(res => {
        this.router.navigate(['/verify-email']);
      }, err => {
        // alert('Something went wrong. Not able to send mail to your email.');
        // console.log('this.parse(err.code) = ', this.parse(err.code));
        reject(this.parse(err.code));
      });
    });

    return promise;
  }

  confirmPassword(oobCode, newPassword) {
    const promise = new Promise((resolve, reject) => {
      return confirmPasswordReset(this.afAuth, oobCode, newPassword).then(() => {
        this.router.navigate(['/login']);
      }, err => {
        reject(this.parse(err.code));
      });
    });

    return promise;
  }

  parse(errorCode: string): string {
    let message: string;
    switch (errorCode) {
      case 'auth/invalid-email':
      case 'auth/wrong-password':
        message = 'Invalid login credentials.';
        break;
      case 'auth/network-request-failed':
        message = 'Please check your internet connection';
        break;
      case 'auth/too-many-requests':
        message = 'We have detected too many requests from your device. Take a break please!';
        break;
      case 'auth/user-disabled':
        message = 'Your account has been disabled or deleted. Please contact the system administrator.';
        break;
      case 'auth/requires-recent-login':
        message = 'Please login again and try again!';
        break;
      case 'auth/email-already-exists':
        message = 'This email address is registered by other, if it is you, try to login instead with register.';
        break;
      case 'auth/email-already-in-use':
        message = 'This email address is registered by other, if it is you, try to login instead with register.';
        break;
      case 'auth/user-not-found':
        message = 'We could not find user account associated with the email address.'; // OPT:  or phone number
        break;
      case 'auth/phone-number-already-exists':
        message = 'The phone number is already in use by an existing user.';
        break;
      case 'auth/invalid-phone-number':
        message = 'The phone number is not a valid phone number!';
        break;
      case 'auth/invalid-email':
        message = 'The email address is not a valid email address!';
        break;
      case 'auth/cannot-delete-own-user-account':
        message = 'You cannot delete your own user account.';
        break;
      case 'auth/code-expired':
        message = 'The OTP is expired!';
        break;
      case 'auth/invalid-verification-code':
        message = 'The OTP is invalid!';
        break;
      case 'auth/too-many-requests':
        message = 'Too many attempts, try again later or change another phone number!';
        break;
      default:
        message = 'Oops! Something went wrong. Try again later.';
        break;
    }

    return message;
  }
}
